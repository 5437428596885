import { call, put } from 'redux-saga/effects'
import { ACTIONS, API_URL, STORAGE, UpdateWithVariables } from '../../_config'
import { CallApi, responseHandler } from '../api/callApi'
import { ApiActionType } from '../../_common/interfaces/ActionExtended'







export function* login(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.LOGIN, data)
    let respChecker = responseHandler(resp)
    if (respChecker.status) {
      localStorage.setItem(STORAGE, JSON.stringify(resp.data.data))
      yield put({
        type: ACTIONS.USER.LOGIN,
        payload: {
          user: resp.data.data,
          token: resp.data.data.accessToken,
          refreshtoken: resp.data.data.refreshToken,
        },
      })
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* logout(action: ApiActionType): any {
  try {
    const data = action.payload
    //const resp = yield call(CallApi.GET, API_URL.USER.LOGOUT, data, true)
    // if (resp.status >= 200 && resp.status < 300 && resp.data) {
    localStorage.clear()
    yield put({
      type: ACTIONS.USER.LOGOUT,
    })
    action && action.callbackSuccess && action.callbackSuccess({})
    // } else {
    //   action && action.callbackError && action.callbackError(resp.data)
    // }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* getMemberDetails(action: ApiActionType):any {
  try {
    const data = action.payload
    const resp:any = yield call(CallApi.GET, API_URL.USER.GET_MEMBER_DETAILS, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.USER.ME,
        payload: {
          user: resp.data.data,
          
        },
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e:any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}


export function* forgotPassword(action: ApiActionType):any {
    try {
        const data = action.payload
        const resp:any = yield call(CallApi.POST, API_URL.USER.FORGOT_PASSWORD, data, true)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data)
        } else {
            action && action.callbackError && action.callbackError(resp.data)
        }
    } catch (e: any) {
        action &&
            action.callbackError &&
            action.callbackError(e && e.data ? e.data : e)
    }
}

export function* resetPassword(action: ApiActionType):any {
    try {
        const data = action.payload
        const resp: any = yield call(CallApi.POST, API_URL.USER.RESET_PASSWORD, data, true)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data)
        } else {
            action && action.callbackError && action.callbackError(resp.data)
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
    }
}




export function* employeeList(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.GET, API_URL.USER.EMPLOYEE_LIST, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* getFormConfig(action: ApiActionType): any {
  const data = action.payload
  let API = UpdateWithVariables(API_URL.USER.GET_FORM_CONFIG, data.urlParams)
  if (data.urlParams) {
    delete data.urlParams
  }
  try {
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}
export function* submitDynamicFrom(action: ApiActionType): any {
  const data = action.payload
  if (data.api) {
    let API = API_URL.USER.SUBMIT_DYNAMIC_FORM + data.api
    delete data.api
    try {
      const resp: any = yield call(CallApi.POST, API, data, true)
      let respChecker =  responseHandler(resp)
      if (respChecker.status) {
        action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
      } else {
        action && action.callbackError && action.callbackError(respChecker.data)
      }
     /*  if (resp.status >= 200 && resp.status < 300 && resp.data) {
        action && action.callbackSuccess && action.callbackSuccess(resp.data)
      } else {
        action && action.callbackError && action.callbackError(resp.data)
      } */
    } catch (e: any) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
    }
  } else {
    console.error('api is not found on dynamic from submit data')
  }
}

export function* memberActivation(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.POST, API_URL.USER.EMPLOYEE_ACTIVE_INACTIVE, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}

export function* addEmployee(action: ApiActionType):any {
  try {
      const data = action.payload
      const resp: any = yield call(CallApi.POST, API_URL.USER.ADD_EMPLOYEE, data, true)
      let respChecker =  responseHandler(resp)
      if (respChecker.status) {
        action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
      } else {
        action && action.callbackError && action.callbackError(respChecker.data)
      }
  } catch (e: any) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}
export function* userDetails(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.USER_DETAILS, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* userUpdate(action: ApiActionType):any {
  try {
      const data = action.payload
      const resp: any = yield call(CallApi.PUT, API_URL.USER.USER_UPDATE, data, true)
      let respChecker =  responseHandler(resp)
      if (respChecker.status) {
        action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
      } else {
        action && action.callbackError && action.callbackError(respChecker.data)
      }
  } catch (e: any) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}

export function* userChangeStatus(action: ApiActionType):any {
  try {
      const data = action.payload
      const resp: any = yield call(CallApi.PUT, API_URL.USER.USER_CHANGE_STATUS, data, true)
      let respChecker =  responseHandler(resp)
      if (respChecker.status) {
        action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
      } else {
        action && action.callbackError && action.callbackError(respChecker.data)
      }
  } catch (e: any) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}



export function* addRole(action: ApiActionType): any {  
    
    try {
      const data = action.payload
       
      const resp: any = yield call(CallApi.POST, API_URL.USER.ROLE_ADD, data, true)
      let respChecker = responseHandler(resp)
      if (respChecker.status) { 
        action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
      } else {
        action && action.callbackError && action.callbackError(respChecker.data)
      } 
    } catch (e: any) {
      action &&
        action.callbackError &&
        action.callbackError(e && e.data ? e.data : e)
    }
}

export function* editRole(action: ApiActionType):any {  

  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.PUT, API_URL.USER.ROLE_EDIT, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) { 
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    } 
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* listRole(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.GET, API_URL.USER.ROLE_LIST, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* detailsRole(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.GET, API_URL.USER.ROLE_DETAILS, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* deleteRole(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.DELETE, API_URL.USER.ROLE_DELETE, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
 

export function* userRoleMap(action: ApiActionType):any {
  try {
      const data = action.payload
      const resp: any = yield call(CallApi.POST, API_URL.USER.USER_ROLE_MAP, data, true)
      let respChecker =  responseHandler(resp)
      if (respChecker.status) {
        action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
      } else {
        action && action.callbackError && action.callbackError(respChecker.data)
      }
  } catch (e: any) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}

export function* useCaseList(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.GET, API_URL.USER.USE_CASE_LIST, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* useCaseDetails(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.USE_CASE_DETAILS, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* airportList(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.GET, API_URL.USER.AIRPORT_LIST, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* airportAdd(action: ApiActionType):any {
  try {
      const data = action.payload
      const resp: any = yield call(CallApi.POST, API_URL.USER.AIRPORT_ADD, data, true)
      let respChecker =  responseHandler(resp)
      if (respChecker.status) {
        action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
      } else {
        action && action.callbackError && action.callbackError(respChecker.data)
      }
  } catch (e: any) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}

export function* airportEdit(action: ApiActionType):any {
  try {
      const data = action.payload
      const resp: any = yield call(CallApi.PUT, API_URL.USER.AIRPORT_EDIT, data, true)
      let respChecker =  responseHandler(resp)
      if (respChecker.status) {
        action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
      } else {
        action && action.callbackError && action.callbackError(respChecker.data)
      }
  } catch (e: any) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}

export function* airportDetails(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.GET, API_URL.USER.AIRPORT_DETAILS, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* airportDelete(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.DELETE, API_URL.USER.AIRPORT_DELETE, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* getUsersByRoleName(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.GET, API_URL.USER.BY_ROLE_NAME, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* getConfigByKey(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.CONFIG_BY_ROLE_NAME, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)    
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* companyList(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.COMPANY_LIST, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* companyAdd(action: ApiActionType):any {
  try {
      const data = action.payload
      const resp: any = yield call(CallApi.POST, API_URL.USER.COMPANY_ADD, data, true)
      let respChecker =  responseHandler(resp)
      if (respChecker.status) {
        action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
      } else {
        action && action.callbackError && action.callbackError(respChecker.data)
      }
  } catch (e: any) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}

export function* companyEdit(action: ApiActionType):any {
  try {
      const data = action.payload
      const resp: any = yield call(CallApi.PUT, API_URL.USER.COMPANY_EDIT, data, true)
      let respChecker =  responseHandler(resp)
      if (respChecker.status) {
        action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
      } else {
        action && action.callbackError && action.callbackError(respChecker.data)
      }
  } catch (e: any) {
      action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}

export function* companyDetails(action: ApiActionType): any {
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.COMPANY_DETAILS, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* companyDelete(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.DELETE, API_URL.USER.COMPANY_DELETE, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* companyStatusUpdate(action: ApiActionType): any {
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.PUT, API_URL.USER.COMPANY_UPDATE_STATUS, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
  }
}

//================= START:: Tenant Section =================//

export function* getTenantsAll(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.TENANTS_LIST, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* getTenants(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.TENANTS_GET, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* tenantSave(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.POST, API_URL.USER.TENANTS_SAVE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) { 
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    } 
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* tenantUpdate(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.PUT, API_URL.USER.TENANTS_UPDATE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) { 
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    } 
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* tenantDelete(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.DELETE, API_URL.USER.TENANTS_DELETE, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* tenantChangeStatus(action: ApiActionType): any { 
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.PUT, API_URL.USER.TENANTS_CHANGE_STATUS, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}


//===================== START:: System Configure section ================//


export function* getSystemConfigAll(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.SYSTEM_CONFIG_LIST, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* getSystemConfig(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.SYSTEM_CONFIG_GET, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* systemConfigSave(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.POST, API_URL.USER.SYSTEM_CONFIG_SAVE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) { 
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    } 
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* systemConfigUpdate(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.PUT, API_URL.USER.SYSTEM_CONFIG_UPDATE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) { 
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    } 
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* systemConfigDelete(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.DELETE, API_URL.USER.SYSTEM_CONFIG_DELETE, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* systemConfigChangeStatus(action: ApiActionType): any { 
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.PUT, API_URL.USER.SYSTEM_CONFIG_CHANGE_STATUS, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* getCountryList(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.COUNTRY_LIST, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* getStateList(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.STATE_LIST, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* getCurrencyList(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.CURRENCY_LIST, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}


export function* companyContactsDetails(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.COMPANY_CONTACT_DETAILS, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* companyContactsList(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.COMPANY_CONTACT_LIST, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* companyContactsAllList(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.COMPANY_CONTACT_ALL_LIST, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* companyContactsAdd(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.POST, API_URL.USER.COMPANY_CONTACT_ADD, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) { 
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    } 
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* companyContactsEdit(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.PUT, API_URL.USER.COMPANY_CONTACT_EDIT, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) { 
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    } 
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* companyContactsDelete(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.DELETE, API_URL.USER.COMPANY_CONTACT_DELETE, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* companyContactsStatus(action: ApiActionType): any { 
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.PUT, API_URL.USER.COMPANY_CONTACT_STATUS, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}


//===================== START:: System Enums section ================//


export function* getSystemEnumsAll(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.SYSTEM_ENUMS_LIST, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* getSystemEnums(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.SYSTEM_ENUMS_GET, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* systemEnumsSave(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.POST, API_URL.USER.SYSTEM_ENUMS_SAVE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) { 
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    } 
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* systemEnumsUpdate(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.PUT, API_URL.USER.SYSTEM_ENUMS_UPDATE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) { 
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    } 
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* systemEnumsDelete(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.DELETE, API_URL.USER.SYSTEM_ENUMS_DELETE, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* systemEnumsChangeStatus(action: ApiActionType): any { 
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.PUT, API_URL.USER.SYSTEM_ENUMS_CHANGE_STATUS, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}





 // CompanyURLS --------------------------

export function* companyUrlsDetails(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.COMPANY_URLS_DETAILS, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* companyUrlsList(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.COMPANY_URLS_LIST, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* companyUrlsAllList(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.COMPANY_URLS_ALL_LIST, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* companyUrlsAdd(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.POST, API_URL.USER.COMPANY_URLS_ADD, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) { 
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    } 
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* companyUrlsEdit(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.PUT, API_URL.USER.COMPANY_URLS_EDIT, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) { 
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    } 
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* companyUrlsDelete(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.DELETE, API_URL.USER.COMPANY_URLS_DELETE, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}

export function* companyUrlsStatus(action: ApiActionType): any { 
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.PUT, API_URL.USER.COMPANY_URLS_STATUS, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}


export function* getDiscovery(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.DISCOVERY_GET_SINGLE, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* getAllDiscovery(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.USER.DISCOVERY_GET_ALL, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}



 

export function* runwayList(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.RUNWAY.LIST, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* runwayDetails(action: ApiActionType): any { 
  try {
    const data = action.payload
    let API = UpdateWithVariables(API_URL.RUNWAY.DETAILS, data.urlParams)
    if (data.urlParams) {
      delete data.urlParams
    }
    const resp: any = yield call(CallApi.GET, API, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* runwaySave(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.POST, API_URL.RUNWAY.SAVE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) { 
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    } 
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* runwayUpdate(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.PUT, API_URL.RUNWAY.UPDATE, data, true)
    let respChecker = responseHandler(resp)
    if (respChecker.status) { 
      action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
    } else {
      action && action.callbackError && action.callbackError(respChecker.data)
    } 
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* runwayDelete(action: ApiActionType): any { 
  try {
    const data = action.payload
     
    const resp: any = yield call(CallApi.DELETE, API_URL.RUNWAY.DELETE, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
export function* runwayChangeStatus(action: ApiActionType): any { 
  try {
    const data = action.payload
    const resp: any = yield call(CallApi.PUT, API_URL.RUNWAY.CHANGE_STATUS, data, true)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data)
    } else {
      action && action.callbackError && action.callbackError(resp.data)
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e)
  }
}
