import React, { useEffect, useState } from "react";
import Error from "../elements/formFieldError";
import { Controller } from "react-hook-form";
import Select from "react-select";
import Form from 'react-bootstrap/Form';
import { useFormApi } from "../../_common/hooks/api/FormApiHook";
import { SAGA_ACTIONS } from '../../_config/index'
import OptionGenerators from "./OptionGenerators";
interface props {
    index: number
    controlType:any
    control: any
    register: any
    getValues: any
    setValue: any
    errors: any
    prop:any
    layout:any
    setColSpan:any
    watch:any
}
const ControlAttributeProp = ({ index,controlType, control, register, getValues, setValue,  errors, prop, layout ,setColSpan, watch}: props) => {


    const [colSpanOptions, setColSpanOptions] = useState<any[]>([]);
    const [submitOptions, setCSubmitOptions] = useState<any[]>([    {
        "value": "api/v1/DynamicForm/Submit",
        "label": "Submit",
    },{
        "value": "api/v1/DynamicForm/DraftForm",
        "label": "Draft",
    }]);

    const componentList:any = [
        {value:'',label:'no component'},
        {value:'productList',label:'Product list with add button'}
    ]

    const colSpanWatch = watch(`field.${index}.prop.${prop.propertyName}`)

    useEffect(()=>{
        if(layout){
            let op:any = []
            for (let i = 1; i <= 12/layout?.col; i++) {
                op.push({value:i,label:i})
                
            }
            setColSpanOptions(op)

        }
    },[layout])

    useEffect(()=>{
        
        if (colSpanWatch && prop.propertyName == 'ColSpan') {
            console.log('colSpanWatch ==>',colSpanWatch)
            setColSpan(parseInt(colSpanWatch.value))
        }
    }, [colSpanWatch])

    const getProp = ()=>{

        
        switch (prop.propertyName) {
            case 'ID' :
            case 'Name' :
            case 'InputType' :
                return null
            case 'Required':
                switch (controlType.controlName) {
                    case '':
                        return null;
                    default:
                        return (
                            <Form.Check // prettier-ignore
                                className="mt-3"
                                type="switch"
                                id="custom-switch"
                                label={prop.propertyName}
                                defaultValue={false}
                                {...register(`field.${index}.prop.${prop.propertyName}`, { required: false })}
                            />
                        )
                }
            case 'Multiple':
            case 'MultiSelect':
                switch (controlType.controlName) {
                    case 'TextBox':
                    case 'CheckBox':
                    case 'Radio':
                        return null;
                    default:
                        return (
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                label={prop.propertyName}
                                defaultValue={false}
                                {...register(`field.${index}.prop.${prop.propertyName}`, { required: false })}
                            />
                        )
                }
                

            case 'ColSpan':
                switch (controlType.controlName) {
                    case 'Button':
                    case 'Submit':
                        return null;
                    default:
                        return (
                            <div className="mt-3">
                                <label htmlFor="tenant" className="form-label">{prop.propertyName}</label>
                                <Controller
                                    defaultValue={{ value: '1', label: '1' }}
                                    control={control}
                                    name={`field.${index}.prop.${prop.propertyName}`}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value, ref, name } }) => (
                                        <div>
                                            <Select
                                                name={name}
                                                onChange={onChange}
                                                value={value}
                                                options={colSpanOptions}
                                                isMulti={false}
                                            />
                                            <Error error={errors.workflow} />
                                        </div>
                                    )}
                                />
                            </div>
                        )
                }
                
            case 'Values':
                switch (controlType.controlName) {
                    case 'TextBox':
                    case 'TextArea':
                    case 'Button':
                    case 'Submit':
                    case 'FileField':
                    case 'Label':
                    case 'HeadLine':
                    case 'CustomControl':
                        return null;
                    default:
                        return (
                            <div>
                                <label htmlFor="tenant" className="form-label">{prop.propertyName}</label>
                                <OptionGenerators
                                    index={index}
                                    control={control}
                                    register={register}
                                    getValues={getValues}
                                    setValue={setValue}
                                    errors={errors}
                                    prop={prop} />
                            </div>

                        )
                }

            case 'Text':
                switch (controlType.controlName) {
                    case 'TextBox':
                    case 'TextArea':
                    case 'Label':
                    case 'HeadLine':
                        return null;
                    case 'CustomControl':
                        return (
                            <div className="mt-3">
                                <label htmlFor="tenant" className="form-label">Component</label>
                                <Controller
                                    control={control}
                                    name={`field.${index}.prop.${prop.propertyName}`}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value, ref, name } }) => (
                                        <div>
                                            <Select
                                                name={name}
                                                onChange={onChange}
                                                value={value}
                                                options={componentList}
                                                isMulti={false}
                                                placeholder='Choose Component'
                                            />
                                            <Error error={errors.workflow} />
                                        </div>
                                    )}
                                />
                            </div>
                        );
                    default:
                        return (
                            <div className="mt-3">
                                <label htmlFor="tenant" className="form-label">{prop.propertyName}</label>
                                <input className="form-control" {...register(`field.${index}.prop.${prop.propertyName}`)} />
                            </div>
                        )
                }
            case 'PlaceHolder':
                switch (controlType.controlName) {
                    case 'Label':
                    case 'Radio':
                    case 'FileField':
                        return null;
                    default:
                        return (
                            <div className="mt-3">
                                <label htmlFor="tenant" className="form-label">{prop.propertyName}</label>
                                <input className="form-control" {...register(`field.${index}.prop.${prop.propertyName}`)} />
                            </div>
                        )
                }

            case 'ActionUrl' :

            return (
                <div className="mt-3">
                    <label htmlFor="tenant" className="form-label">{prop.propertyName}</label>
                    <Controller
                        defaultValue={submitOptions[0]}
                        control={control}
                        name={`field.${index}.prop.${prop.propertyName}`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value, ref, name } }) => (
                            <div>
                                <Select
                                    name={name}
                                    onChange={onChange}
                                    value={value}
                                    options={submitOptions}
                                    isMulti={false}
                                />
                                <Error error={errors.workflow} />
                            </div>
                        )}
                    />
                </div>
            )


            default:
                return (
                    <div className="mt-3">
                        <label htmlFor="tenant" className="form-label">{prop.propertyName}</label>
                        <input className="form-control" {...register(`field.${index}.prop.${prop.propertyName}`)} />
                    </div>
                )
             
        }

        
    }

    return (
        <>{getProp()}</>
    );
}

export default ControlAttributeProp;