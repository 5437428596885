import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import DynamicForm from '../../components/dynamicForm/dynamicForm'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import { useNavigate } from 'react-router-dom'

export const IdeaSubmit = () => {

    const userApi = useUserApi();
    const navigate = useNavigate()

    const [config,setConfig] = useState<any>(null)

    const __config = {
        layout:{col:2},
        fields:[
            {inputType:'file', name:'field1', multiple:'false', colSpan:2, required:true},
            {inputType:'text', name:'field2', label:'name',placeholder:'enter name',required:true},
            {inputType:'text', name:'field3', label:'Use Case Idea',placeholder:'your idea',required:true},
            {inputType:'textarea', name:'field4', colSpan:2,label:'Please describe the big picture idea and potential learning objectives driving interest in the use case. What is the product or technology this opportunity is based on?',placeholder:'',required:true},
            {inputType:'checkbox', name:'field5', label:'What question(s) would we want to answer in a', options:['Demonstration','Prototype','Pilot'],required:true},
            {inputType:'text', name:'field6', label:'How do we meet this need today?',placeholder:'',required:true},
            {inputType:'text', name:'field7', label:'If this solution proves viable, how would it change our approach?',placeholder:'I.e. what is the opportunity?',required:true},
            {inputType:'text', name:'field8', label:'What risks do we see in the testing of this use case?',placeholder:'I.e. what would make this hard to complete?',required:true},
            {inputType:'radio', name:'field9', label:'Do you have a product / vendor in mind?', options:['In the list','Not Listed'],required:true},
            {inputType:'radio', name:'field10', label:'Is the product / service commercially available?', options:['Yes','No','Prototype/Pre-Commercial'],required:true},
            {inputType:'textarea', name:'field11', colSpan:2,label:'Anything else we should know?',placeholder:'',required:true}
        ],
        actions:[
            {button:'submit',label:'save as Draft',theme:'primary'},
            {button:'submit',label:'submit',theme:'info'},
        ]
    }


  const _config = {
    "success": true,
    "message": "Successful",
    "totalRecords": 1,
    "data": {
      "fromID": 1,
      "title": "Usecase intake form",
      "description": "Use case intake from for user data submission",
      "layout": "Col:2",
      "controls": [
        {
          "multipleUpload": false,
          "files": [],
          "id": "file_1_1_1",
          "name": "file_1_1_1",
          "caption": "Browse files",
          "placeHolder": "",
          "colSpan": 2,
          "inputType": "FileField",
          "fkControlTypeID": 6,
          "value": "",
          "displayOrder": 1,
          "fkFormControlID": 1,
          "required": true
        },
        {
          "maxLength": 0,
          "text": null,
          "id": "txt_1_2_2",
          "name": "txt_1_2_2",
          "caption": "Name",
          "placeHolder": "Enter your name",
          "colSpan": 1,
          "inputType": "TextBox",
          "fkControlTypeID": 0,
          "value": "",
          "displayOrder": 2,
          "fkFormControlID": 2,
          "required": true
        },
        {
          "maxLength": 0,
          "text": null,
          "id": "txt_1_3_3",
          "name": "txt_1_3_3",
          "caption": "Use Case Idea",
          "placeHolder": "Enter your Use Case Idea",
          "colSpan": 1,
          "inputType": "TextBox",
          "fkControlTypeID": 0,
          "value": "",
          "displayOrder": 3,
          "fkFormControlID": 3,
          "required": true
        },
        {
          "maxLength": 0,
          "text": null,
          "row": 5,
          "column": 10,
          "id": "txta_1_4_4",
          "name": "txta_1_4_4",
          "caption": "Please describe the big picture idea and potential learning objectives driving interest in the use case. What is the product or technology this opportunity is based on?",
          "placeHolder": "",
          "colSpan": 2,
          "inputType": "TextArea",
          "fkControlTypeID": 0,
          "value": "",
          "displayOrder": 4,
          "fkFormControlID": 4,
          "required": true
        },
        {
          "multiSelect": true,
          "options": {
            "1": "Demonstration",
            "2": "Prototype",
            "3": "Pilot"
          },
          "id": "chk_1_5_5",
          "name": "chk_1_5_5",
          "caption": "What question(s) would we want to answer in a",
          "placeHolder": "",
          "colSpan": 1,
          "inputType": "CheckBox",
          "fkControlTypeID": 4,
          "value": "",
          "displayOrder": 5,
          "fkFormControlID": 5,
          "required": true
        },
        {
          "maxLength": 0,
          "text": null,
          "id": "txt_1_6_6",
          "name": "txt_1_6_6",
          "caption": "How do we meet this need today?",
          "placeHolder": "How do we meet this need today?",
          "colSpan": 1,
          "inputType": "TextBox",
          "fkControlTypeID": 0,
          "value": "",
          "displayOrder": 6,
          "fkFormControlID": 6,
          "required": true
        },
        {
          "maxLength": 0,
          "text": null,
          "id": "txt_1_7_7",
          "name": "txt_1_7_7",
          "caption": "If this solution proves viable, how would it change our approach?",
          "placeHolder": "I.e. what is the opportunity?",
          "colSpan": 1,
          "inputType": "TextBox",
          "fkControlTypeID": 0,
          "value": "",
          "displayOrder": 7,
          "fkFormControlID": 7,
          "required": true
        },
        {
          "maxLength": 0,
          "text": null,
          "id": "txt_1_8_8",
          "name": "txt_1_8_8",
          "caption": "What risks do we see in the testing of this use case?",
          "placeHolder": "I.e. what would make this hard to complete?",
          "colSpan": 1,
          "inputType": "TextBox",
          "fkControlTypeID": 0,
          "value": "",
          "displayOrder": 8,
          "fkFormControlID": 8,
          "required": true
        },
        {
          "multiSelect": false,
          "options": {
            "1": "In the list",
            "2": "Not Listed"
          },
          "groupName": "",
          "id": "rbtn_1_9_9",
          "name": "rbtn_1_9_9",
          "caption": "Do you have a product / vendor in mind?",
          "placeHolder": "",
          "colSpan": 1,
          "inputType": "Radio",
          "fkControlTypeID": 5,
          "value": "",
          "displayOrder": 9,
          "fkFormControlID": 9,
          "required": true
        },
        {
          "multiSelect": false,
          "options": {
            "1": "Yes",
            "2": "No",
            "3": "Prototype/Pre-Commercial"
          },
          "groupName": "",
          "id": "rbtn_1_10_10",
          "name": "rbtn_1_10_10",
          "caption": "Is the product / service commercially available?",
          "placeHolder": "",
          "colSpan": 1,
          "inputType": "Radio",
          "fkControlTypeID": 5,
          "value": "",
          "displayOrder": 10,
          "fkFormControlID": 10,
          "required": true
        },
        {
          "maxLength": 0,
          "text": null,
          "row": 5,
          "column": 10,
          "id": "txta_1_11_11",
          "name": "txta_1_11_11",
          "caption": "Anything else we should know?",
          "placeHolder": "",
          "colSpan": 2,
          "inputType": "TextArea",
          "fkControlTypeID": 0,
          "value": "",
          "displayOrder": 11,
          "fkFormControlID": 11,
          "required": true
        }
      ],
      "buttons": [
        {
          "actionURL": "api/v1/DynamicForm/Draft",
          "id": "btn_1_12_12",
          "name": "btn_1_12_12",
          "caption": "Save as Draft",
          "placeHolder": "",
          "colSpan": 1,
          "inputType": "Submit",
          "fkControlTypeID": 8,
          "value": "",
          "displayOrder": 12,
          "fkFormControlID": 12,
          "required": false
        },
        {
          "actionURL": "api/v1/DynamicForm/Submit",
          "id": "btn_1_13_13",
          "name": "btn_1_13_13",
          "caption": "Submit",
          "placeHolder": "",
          "colSpan": 1,
          "inputType": "Submit",
          "fkControlTypeID": 8,
          "value": "",
          "displayOrder": 13,
          "fkFormControlID": 13,
          "required": false
        }
      ],
      "exceptions": []
    }
  }

  const getFormConfig = () => {
    userApi.getFormConfig({ urlParams: { id: 1 } }, (message: any, resp: any) => {
      //console.log('form config',resp)
      setConfig(resp.data)
    }, (message: any) => {

    })
  }
  
    useEffect(()=>{getFormConfig()},[])


    return (

        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><a href="#">Dashboard</a></li>
                                    <li>Idea</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">Submit your Idea</h1>
                            </div>

                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="white-box">
                            {config?<DynamicForm config={config} onSubmitSuccess={()=>{navigate('/use-case-library-list')}}/>:null}
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="pt-5">
                                <img src="../images/idea-pic.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <Footer></Footer>
        </React.Fragment>

    )
}
