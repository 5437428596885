import { call, put } from 'redux-saga/effects'
import { ACTIONS, API_URL, STORAGE, UpdateWithVariables } from '../../_config'
import { CallApi, responseHandler } from '../api/callApi'
import { ApiActionType } from '../../_common/interfaces/ActionExtended'


export function* getWorkflows(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.GET_WORKFLOWS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* getModules(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.GET_MODULES, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* getForms(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.GET_FORMS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* getControlTypes(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.GET_CONTROL_TYPES, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}

export function* getControlProps(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.GET_CONTROL_PROPS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* save(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.FORM.SAVE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.POST, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}




export function* getAllScoreQuestions(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.SCORE_QUESTION.LIST, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* scoreQuestionSave(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.SCORE_QUESTION.SAVE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.POST, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* getScoreQuestions(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.SCORE_QUESTION.DETAILS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.GET, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
} 
export function* scoreQuestionUpdate(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.SCORE_QUESTION.UPDATE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.PUT, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* scoreQuestionStatusChange(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.SCORE_QUESTION.CHANGE_STATUS, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.PUT, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}
export function* scoreQuestionDelete(action: ApiActionType): any {
	try {
		const data = action.payload
		let API = UpdateWithVariables(API_URL.SCORE_QUESTION.DELETE, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
		const resp: any = yield call(CallApi.DELETE, API, data, true)
		let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
	} catch (e: any) {
		action && action.callbackError && action.callbackError(e && e.data ? e.data : e)
	}
}