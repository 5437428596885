import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import Select from 'react-select'
import Error from '../../components/elements/formFieldError'
import { useUser } from '../../_common/hooks/authHook'
import { resolve } from 'path'
import { URLS } from '../../_config'

export const CompanyAddEdit = () => {
    const userInfo: any = useUser();
    const navigate = useNavigate();
    const userApi = useUserApi();
    const { id } = useParams();
    const paramId: any = id;
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [allCountryList, setAllCountryList] = useState<Array<any>>([]);
    const [allStateList, setAllStateList] = useState<Array<any>>([]);
    const [allCurrencyList, setAllCurrencyList] = useState<Array<any>>([]);
    const [allTenantList, setAllTenantList] = useState<Array<any>>([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [companyData, setCompanyData] = useState<any>([]); 



    type Inputs = {
        companyName: string
        address: string
        phone: string
        email: string
        bio: string
        city: string
        fkStateID: any
        fkCountryID: any
        fkCurrencyID: any
        tenantReq: true
        fkTenantID: any
        domainPrefix: string
        domainURL: string
        contactPersonTitle: string
        contactPersonFirstName: string
        contactPersonLastName: string
        contactPersonEmail: string
        contactPersonPhone: string
        logo: any
    }

    const nameTitle: any = [
        { value: 'Dr', label: 'Dr' },
        { value: 'Mr', label: 'Mr' },
        { value: 'Ms', label: 'Ms' },
        { value: 'Mrs', label: 'Mrs' }       
      ]

 

    const {
        register,
        control,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
    } = useForm<Inputs>()

    const countryWatcher = watch('fkCountryID');

    const tenantList = () => {
        let param = {
            urlParams: {
                PageIndex: 1,
                PageSize: 10
            }
        }

        userApi.getTenantsAll(param, (message: any, resp: any) => {

            setAllTenantList(resp.data.tenants.map((tenant: any) => {
                tenant.value = tenant.tenantId;
                tenant.label = `${tenant.tenantName}`;
                return tenant;
            }));
        }, (message: any, resp: any) => {
            // Error...
        })
    }

    const countryList = () => {
        let param = {
            urlParams: {
                PageIndex: 1,
                PageSize: 10
            }
        }

        userApi.getCountryList(param, (message: any, resp: any) => {

            setAllCountryList(resp.data.countries.map((country: any) => {
                country.value = country.countryID;
                country.label = `${country.name}`;
                return country;
            }));
        }, (message: any, resp: any) => {
            // Error...
        })
    }

    const handleCountryChange = (selectedOption: any) => {
        setSelectedCountry(selectedOption);        
        stateList(selectedOption.value);
        currencyList(selectedOption.value);
    };

    const stateList = (countryID: any) => {
        let param = {
            FKCountryID: countryID,

        }

        userApi.getStateList(param, (message: any, resp: any) => {

            setAllStateList(resp.data.states.map((state: any) => {
                state.value = state.stateID;
                state.label = `${state.state}`;
                return state;
            }));
        }, (message: any, resp: any) => {
            // Error...
        })
    }

    const currencyList = (countryID: any) => {
        let param = {
            FKCountryID: countryID,

        }

        userApi.getCurrencyList(param, (message: any, resp: any) => {

            setAllCurrencyList(resp.data.currencies.map((currency: any) => {
                currency.value = currency.fkCountryID;
                currency.label = `${currency.currency}`;
                return currency;
            }));
        }, (message: any, resp: any) => {
            // Error...
        })
    }

    const getSeletedValue = (value: any, node: string, ownerOptions: any[]) => {    
        console.log('ownerOptions', ownerOptions, value);
            
        let result = null;
        ownerOptions.map((option: any) => {
            if (option[node] == value) {
                result = option;
            }
        })
        return result;
    }

    const getCompanyByID = (id: any) => {

        userApi.companyDetails({ urlParams: { id: id } }, (message: any, resp: any) => {
            setCompanyData(resp.data)
            reset({
                companyName: resp.data.companyName,                
                address: resp.data.address,
                bio: resp.data.bio,
                city: resp.data.city,
                email: resp.data.email,
                phone: resp.data.phone,

                fkCountryID: getSeletedValue(resp.data.fkCountryID, "value", allCountryList),
                fkTenantID: getSeletedValue(resp.data.fkTenantID, "value", allTenantList),
               // contactPersonTitle: getSeletedValue(resp.data.contactPersonTitle, "value", allCountryList),
            })
            if (resp.data.logo != null) {
                setImageUrl(resp.data.logo);
            }
            console.log('resp', resp);

        }, (message: any, resp: any) => {
            // Error...
        })
    }

    useEffect(() => {

        if (allCountryList.length && allTenantList.length && id) {
            getCompanyByID(id)
        }
       

    }, [id, allCountryList, allTenantList]);

    useEffect(() => {
        if(countryWatcher) {
            handleCountryChange(countryWatcher);
        }       

    }, [countryWatcher]);

    useEffect(() => {
        if(allStateList.length && id) {            
            setValue('fkStateID',getSeletedValue(companyData?.fkStateID, "value", allStateList))
        }       

    }, [id, allStateList]);

    useEffect(() => {
        if(allCurrencyList.length && id) {            
            setValue('fkCurrencyID',getSeletedValue(companyData?.fkCurrencyID, "value", allCurrencyList))
        }       

    }, [id, allCurrencyList]);

    useEffect(() => {

        tenantList()
        countryList()

    }, []);

    const convertBase64 = (file: any) => {
        return new Promise<string>((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                const base64String = fileReader.result?.toString();
                resolve(base64String || '');
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const submitCompany = (data: any) => {
        console.log('data',data);
        return new Promise((resolve: any, reject: any) => {
            if (id) {
                data.companyID = id;
                data.updatedBy = userInfo?.userID;
                userApi.companyEdit(data, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message);
                })

            } else {

                data.companyID = 0;
                data.addedBy = userInfo?.userID;
                userApi.companyAdd(data, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message);
                })
            }
        })
    }

    const submitData = (data: any) => {
        

        return new Promise((resolve, reject) => {
            data.isActive = true;
            data.isDeleted = false;
            data.fkCountryID = data.fkCountryID.value;
            data.fkCurrencyID = data.fkCurrencyID.value;
            data.fkStateID = data.fkStateID.value;
            data.fkTenantID = data.fkTenantID.value;
            //data.fkTanentID = userInfo?.tenantID;

            if (data.logo) {
                const logoFile = (document.getElementById('companyLogo') as any).files[0];
                convertBase64(logoFile)
                    .then((base64String) => {
                        data.logo = base64String;
                        submitCompany(data).then(resolve).catch(reject)
                    })
                    .catch((error) => {
                        console.error('Error converting logo to base64:', error);
                    });

            } else {
                submitCompany(data).then(resolve).catch(reject)
            }

        })

    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {

        toast.promise(
            submitData(data), {
            loading: 'Loading...',
            success: (msg: any) => { navigate('/company/list'); return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        }
        );
    }

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setImageUrl(URL.createObjectURL(file));
        }
    };


    return (
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><Link to={URLS.DASHBOARD}>Dashboard</Link></li>
                                    <li><Link to={URLS.COMPANIES}>Companies</Link></li>
                                    <li>{(paramId > 0) ? 'Edit' : 'Add'} Company</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">{(paramId > 0) ? 'Edit' : 'Create'} Company</h1>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="white-box h-100">
                                <div className="form-area">
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="col-md-6">
                                            <label htmlFor="companyname" className="form-label">Company Name</label>
                                            <input type="text" {...register("companyName", { required: "Company name is required" })} className="form-control" placeholder="Put the name" id="companyname" />
                                            <Error error={errors.companyName} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Address</label>
                                            <input type="text" {...register("address", { required: "Address is required" })} className="form-control" placeholder="Put the address" />
                                            <Error error={errors.address} />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">City Name</label>
                                            <input type="text" {...register("city", { required: "City name is required" })} className="form-control" placeholder="Put the city" />
                                            <Error error={errors.city} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Email</label>
                                            <input type="text" {...register("email", { required: "Email is required" })} className="form-control" placeholder="Put the email" />
                                            <Error error={errors.email} />
                                        </div>

                                        <div className="col-md-12">
                                            <label className="form-label">Phone</label>
                                            <input type="text" {...register("phone", { required: "Phone is required" })} className="form-control" placeholder="Put the email" />
                                            <Error error={errors.email} />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Country</label>

                                            <Controller
                                                name="fkCountryID"
                                                control={control}
                                                rules={{ required: 'Country is required' }}
                                                render={({ field }) => (
                                                    <div>
                                                        <Select
                                                            {...field}
                                                            options={allCountryList}
                                                            isMulti={false}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption); // Call the original onChange function
                                                                 // Call your custom function
                                                            }}
                                                        //onChange={handleCountryChange}
                                                        />
                                                        <Error error={errors.fkCountryID} />
                                                    </div>
                                                )}
                                            />

                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">State</label>
                                            <Controller
                                                name="fkStateID"
                                                control={control}
                                                rules={{ required: 'State is required' }}
                                                render={({ field }) => (
                                                    <div>
                                                        <Select
                                                            {...field}
                                                            options={allStateList}
                                                            isMulti={false}
                                                        />
                                                        <Error error={errors.fkStateID} />
                                                    </div>
                                                )}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Currency</label>
                                            <Controller
                                                name="fkCurrencyID"
                                                control={control}
                                                rules={{ required: 'State is required' }}
                                                render={({ field }) => (
                                                    <div>
                                                        <Select
                                                            {...field}
                                                            options={allCurrencyList}
                                                            isMulti={false}
                                                        />
                                                        <Error error={errors.fkCurrencyID} />
                                                    </div>
                                                )}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Tenant</label>
                                            <Controller
                                                name="fkTenantID"
                                                control={control}
                                                rules={{ required: 'State is required' }}
                                                render={({ field }) => (
                                                    <div>
                                                        <Select
                                                            {...field}
                                                            options={allTenantList}
                                                            isMulti={false}
                                                        />
                                                        <Error error={errors.fkTenantID} />
                                                    </div>
                                                )}
                                            />
                                        </div>

                                        <div className="col-12 mt-3">
                                            <label htmlFor="description" className="form-label">Company Bio</label>
                                            <textarea id="description" {...register("bio")} className="form-control" rows={5} placeholder="Put the company bio"></textarea>

                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Domain Prefix</label>
                                            <input type="text" {...register("domainPrefix")} className="form-control" placeholder="Put the address" />

                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Domain URL</label>
                                            <input type="text" {...register("domainURL")} className="form-control" placeholder="Put the domain url" />
                                            <Error error={errors.domainURL} />
                                        </div>


                                        

                                        <div className="col-md-6">
                                            <label className="form-label">Title</label>
                                            <Controller
                                                name="contactPersonTitle"
                                                control={control}
                                                rules={{ required: 'Title is required' }}
                                                render={({ field }) => (
                                                    <div>
                                                        <Select
                                                            {...field}
                                                            options={nameTitle}
                                                            isMulti={false}
                                                        />
                                                        <Error error={errors.contactPersonTitle} />
                                                    </div>
                                                )}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Contact Person First Name</label>
                                            <input type="text" {...register("contactPersonFirstName")} className="form-control" placeholder="Put the  first name" />
                                            <Error error={errors.contactPersonFirstName} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Contact Person Last Name</label>
                                            <input type="text" {...register("contactPersonLastName")} className="form-control" placeholder="Put the  last name" />
                                            <Error error={errors.contactPersonLastName} />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Email</label>
                                            <input type="text" {...register("contactPersonEmail")} className="form-control" placeholder="Put the email" />

                                        </div>

                                        <div className="col-md-12">
                                            <label className="form-label">Phone</label>
                                            <input type="text" {...register("contactPersonPhone")} className="form-control" placeholder="Put the phone number" />
                                            <Error error={errors.contactPersonPhone} />
                                        </div>


                                        <div className="col-12 mt-3">
                                            <div className="custom-file-uploader mb-4">
                                                {/* <Controller
                                                    defaultValue=""
                                                    control={control}
                                                    name="logo"

                                                    render={({ field: { onChange, value, ref, name } }) => (
                                                        <input type="file" id="companyLogo" name={name} ref={ref} onChange={onChange} value={value} />
                                                    )}
                                                />
                                                <i className="file-upload-icon"></i>
                                                <p className="mb-0">Click to upload.</p> */}

                                                <Controller
                                                    defaultValue=""
                                                    control={control}
                                                    name="logo"
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="file"
                                                                id="companyLogo"
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    onFileChange(e);
                                                                }}
                                                            />
                                                            <i className="file-upload-icon"></i>
                                                            <p className="mb-0">Click to upload.</p>
                                                        </>
                                                    )}
                                                />

                                            </div>
                                            {imageUrl && <img src={imageUrl} alt="Uploaded" />}
                                        </div>



                                        <div className="col-12 d-flex justify-content-end gap-3">
                                            <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Submit <i className="arrow-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="pt-5">
                                <img src="./../images/idea-pic.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}
