import React from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <div className="mob-menu-wrapper ">

                <Nav variant="pills" activeKey="1" className="mob-menu d-flex">                   
                    <div className="nav-item-mob dashboard-mobile-footer active">
                        <i className="home-icon"></i>
                        <NavDropdown title="Dashboard" id="nav-dropdown">
                            <NavDropdown.Item as={Link} to="#" eventKey="4.1">Executive Summary</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="#" eventKey="4.2">KPI and Trending</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="#" eventKey="4.3">Period Comparison Chart</NavDropdown.Item>                            
                            <NavDropdown.Item as={Link} to="#" eventKey="4.4">Trending by Phase Chart</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="#" eventKey="4.5">Dynamic Drilldown</NavDropdown.Item>
                        </NavDropdown>
                    </div>

                    <Nav.Item className="nav-item-mob">
                        <Nav.Link as={Link} to="#" className="d-flex align-items-center gap-2">
                            <i className="airport-icon"></i> Airport</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item-mob">
                        <Nav.Link as={Link} to="/use-case-library-list" className="d-flex align-items-center gap-2">
                            <i className="usecase-icon"></i> Use Case</Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="nav-item-mob">
                        <Nav.Link as={Link} to="#" className="d-flex align-items-center gap-2">
                            <i className="report-icon"></i> Reports</Nav.Link>
                    </Nav.Item>

                    <div className="nav-item-mob dashboard-mobile-footer">
                        <i className="more-icon"></i>
                        <NavDropdown title="More" id="nav-dropdown">
                            <NavDropdown.Item as={Link} to="#" eventKey="5.1">Admin Employee</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="#" eventKey="5.2">Solution Provider</NavDropdown.Item>                            
                            {/* <NavDropdown.Divider /> */}                            
                        </NavDropdown>
                    </div>                    
                </Nav>               
            </div>


            <div className="mob-menu-wrapper d-none">
                <ul className="mob-menu d-flex">
                    <li className="nav-item-mob active">
                        <a className="active d-flex align-items-center gap-2 dashboard-menu-btn" aria-current="page" href="#"><i className="home-icon"></i> Dashboard</a>

                    </li>
                    <li className="nav-item-mob">
                        <a className="d-flex align-items-center gap-2" href="airport.html"><i className="airport-icon"></i> Airport</a>
                    </li>
                    <li className="nav-item-mob">
                        <a className="d-flex align-items-center gap-2" href="idea-list.html"><i className="usecase-icon"></i> Use Case</a>
                    </li>
                    <li className="nav-item-mob">
                        <a className="d-flex align-items-center gap-2" href="#"><i className="report-icon"></i> Reports</a>
                    </li>
                    <li className="nav-item-mob">
                        <a className="d-flex align-items-center gap-2 more-menu-btn" href="#"><i className="more-icon"></i> More</a>
                    </li>
                </ul>
                <div className="more-menu-panel">
                    <ul>
                        <li><a href="list-filter.html">Admin Employee</a></li>
                        <li><a href="solution-provider.html">Solution Provider</a></li>
                    </ul>
                </div>
                <div className="dashboard-menu-panel">
                    <ul>
                        <li><a href="dashboard.html">Executive Summary</a></li>
                        <li><a href="kpi_trendingdashboard.html">KPI and Trending</a></li>
                        <li><a href="preiodcomprisonchat_dashboard.html">Period Comparison Chart</a></li>
                        <li><a href="trendingphase_chartDashboard.html">Trending by Phase Chart</a></li>
                        <li><a href="dynamicdrilldown_dashboard.html">Dynamic Drilldown</a></li>
                    </ul>
                </div>

            </div>

            <div className="footer py-3 mt-4">
                <div className="container">
                    <p>
                        ©
                        <script>
                            document.write(new Date().getFullYear());
                        </script>
                        Innovation Force</p>
                </div>
            </div>
        </>
    );
}

export default Footer;