/* eslint-disable @typescript-eslint/ban-types */
import { useDispatch } from 'react-redux'
import { extractErrorMessage } from '../../../_config/functions'
import { store } from '../../../store'
import { ACTIONS, URLS } from '../../../_config'
import { toast } from 'react-hot-toast'
import { useAppLoader } from './appLoaderHook'
//import { useSignupAction } from '../actions/SignupActionHook'

export function useApiCall() {
  const dispatch = useDispatch()
  //const signupAction = useSignupAction()
  const { hideLoader } = useAppLoader()

  const callApi = (
    sagaAction: string,
    dataOrParams: object = {},
    callbackSuccess: Function,
    callbackError: Function,
  ) => {
    dispatch({
      type: sagaAction,
      payload: dataOrParams,
      callbackSuccess: (data: { message?: string }) => {
        const message =
          data && data.message ? data.message : 'Request processed successfully'
        const resp = data ? data : null
        callbackSuccess && callbackSuccess(message, resp)
      },
      callbackError: (error: any) => {
        // console.log(error)
        let message = extractErrorMessage(
          error,
          'Unable to process request, please try again',
        )
        if (error?.status_code === 401 || error?.status_code === 403) {
          hideLoader()
          toast.error(error?.message)
          localStorage.clear()
          store.dispatch({ type: ACTIONS.USER.LOGOUT })
          //signupAction.resetReducer()
          window.location.href = URLS.LOGIN
          message = ''
        }
        const resp = error?.data ? error?.data : error
        callbackError && callbackError(message, resp)
      },
    })
  }
  return callApi
}
