import { API_BASE_URL, API_BUS_BASE_URL, API_BUS_BASE_URL_ORIGIN } from './site_urls'

export const STORAGE = 'innsass'
export const APP_URL = window.location.origin
export const CURRENCYSYMBOL = '$'
export const CURRENCY = 'usd'
export const PERCENTSYMBOL = '%'
export const SYNCFUNCTION_LICENCE_KEY = 'ORg4AjUWIQA/Gnt2VlhhQlJCfV5DQmJLYVF2R2BJdlR0cF9FYUwgOX1dQl9gSH5SdURiXH5fdnJWRGk='
export const BASIS_THEORY_API_KEY = 'key_GBZCq4BZAdj8QqkJqL4gP7'
// export const HOST_NAME = '.fluxpay.com'
export const PER_PAGE = '10'

export const URLS = {
  LOGIN: '/login',
  SIGNUP: '/signup/',
  SIGNUP_NEXT: '/signup-next/:form_id',
  TWO_FACTOR: '/otp/:type',
  VERIFY_ACCOUNT: '/verify-account',
  THANKYOU: '/thank-you',
  PLANS: '/plans',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  DASHBOARD: '/dashboard',

  EMPLOYEE_LIST: '/employee',
  EMPLOYEE_CREATE: '/employee/create',
  EMPLOYEE_EDIT: '/employee/edit/',
  EMPLOYEE_ROLE_PERMISSION: '/employee/role/',


  VIRTUAL_TERMINAL: '/virtual-terminal',
  ACCOUNTS: '/accounts',
  ADD_NEW_ACCOUNT: '/add-new-account',
  ACCOUNT_DETAILS: '/accounts/:id',
  ACCOUNT_AUTHORIZATIONS: '/accounts/:id/authorizations',
  ACCOUNT_TRANSACTIONS: '/accounts/:id/transactions',
  ACCOUNT_SUBSCRIPTIONS: '/accounts/:id/subscriptions',
  ACCOUNT_INVOICES: '/accounts/:id/invoices',
  ACCOUNT_BILLS: '/accounts/:id/bills',
  ONBOARDING: '/onboarding',
  PAYEE: {
    VENDOR: '/payee',
  },
  HPP: '/hpp',
  HPP_PAYMENT_DONE: '/hpp/payment-done',
  INVOICE: '/invoice/',
  INVOICE_LIST: '/invoice-list/',
  INVOICE_DETAILS: '/invoice-details/',
  INVOICE_PREVIEW: '/invoice-preview/',
  INVOICE_ALL_TRXN: '/invoice-transaction/',
  PRODUCT_SERVICES: '/product-services',
  PRODUCT_LIST: '/product',
  PRODUCTS_DETAILS: '/product/:id',
  PRODUCTS_TRANSACTIONS: '/product/:id/transactions',
  PRODUCTS_INVOICES: '/product/:id/invoices',
  ACCOUNT_DETAILS_EDIT: '/accounts-edit',
  USER_PROFILE: '/user-profile',
  PRINT_INVOICE: '/print-invoice/:invoiceId',

  ROLES: '/role/list',
  AIRPORTS: '/airport/list',

  TENANTS: '/tenants',
  TENANTS_CREATE: '/tenants/create',
  TENANTS_EDIT: '/tenants/edit/',

  SYSTEM_CONFIGURATION: '/system-configurations',
  SYSTEM_CONFIGURATION_CREATE: '/system-configurations/create',
  SYSTEM_CONFIGURATION_EDIT: '/system-configurations/edit/',

  COMPANIES: '/company/list',
  SYSTEM_ENUMS: '/system-enums',
  SYSTEM_ENUMS_CREATE: '/system-enums/create',
  SYSTEM_ENUMS_EDIT: '/system-enums/edit/',

  DOMAINS: '/company/domains',
  CONTACTS: '/company/contacts',

  SCORE_QUESTION: {
    LIST: '/score-questions/list',
    DETAILS: '/score-questions/details/:id',
    CREATE: '/score-questions/create',
    EDIT: '/score-questions/edit/:id',
  },

  RUNWAY: {
    LIST: '/runway/list',
    DETAILS: '/runway/details/:id',
    CREATE: '/runway/create',
    EDIT: '/runway/edit/:id',
  }
}

export const UpdateWithVariables = (url: string, data: any) => {
  if (data) {
    let dynamicParams = url.match(/:[A-Z_]*/gi)
    dynamicParams?.map(key => {
      if (key.length > 1) url = url.replace(key, data[key.replace(':', '')])
    })
  }
  return url
}

export const API_URL = {
  COMMON: {
    SITE_MASTER: `${API_BASE_URL}/site-master/`,
    PHONE_CODES: `${API_BASE_URL}/geocoding/number-lookup`,
  },
  USER: {
    ME: `${API_BASE_URL}/auth/me`,
    LOGIN: `${API_BASE_URL}/login/token`,
    SIGNUP: `${API_BASE_URL}/auth/register`,
    VERIFY_EMAIL: `${API_BASE_URL}/verify/email`,
    FORGOT_PASSWORD: `${API_BASE_URL}/login/ForgotPassword`,
    RESET_PASSWORD: `${API_BASE_URL}/login/ForgotPasswordReset`,
    EMPLOYEE_LIST: `${API_BASE_URL}/member/GetMemberList`,
    EMPLOYEE_ACTIVE_INACTIVE: `${API_BASE_URL}/member/MemberActiveInactive`,
    EMPLOYEE_DELETE: `${API_BASE_URL}/Login/Register`,
    ROLE_ADD: `${API_BASE_URL}/Role/InsertRole`,
    ROLE_EDIT: `${API_BASE_URL}/Role/UpdateRole`,
    ROLE_DETAILS: `${API_BASE_URL}/Role/GetRoleDetails`,
    ROLE_LIST: `${API_BASE_URL}/Role/GetRoleList`,
    ROLE_DELETE: `${API_BASE_URL}/Role/DeleteRole`,


    LOGOUT: `${API_BASE_URL}/auth/logout`,
    GET_FORM_CONFIG: `${API_BUS_BASE_URL}/DynamicForm/getform/:id`,
    SUBMIT_DYNAMIC_FORM: `${API_BUS_BASE_URL_ORIGIN}/`,
    USE_CASE_LIST: `${API_BUS_BASE_URL}/UseCase/getAllUseCase`,
    USE_CASE_DETAILS: `${API_BUS_BASE_URL}/UseCase/getUseCase/:id`,
    REVERIFY_ACCOUNT: `${API_BASE_URL}/auth/reverify-account`,
    UPDATE_USER: `${API_BASE_URL}/users/:user_id`,
    SESSIONS_LIST: `${API_BASE_URL}/auth/sessions`,
    GET_MEMBER_DETAILS: `${API_BASE_URL}/Member/GetMemberDetails`,
    ADD_EMPLOYEE: `${API_BASE_URL}/Login/Register`,
    AIRPORT_LIST: `${API_BASE_URL}/Airport/GetAirportList`,
    AIRPORT_ADD: `${API_BASE_URL}/Airport/InsertAirport`,
    AIRPORT_EDIT: `${API_BASE_URL}/Airport/UpdateAirport`,
    AIRPORT_DETAILS: `${API_BASE_URL}/Airport/GetAirportDetails`,
    AIRPORT_DELETE: `${API_BASE_URL}/Airport/DeleteAirport`,
    BY_ROLE_NAME: `${API_BASE_URL}/Member/GetUsersByRoleName`,
    CONFIG_BY_ROLE_NAME: `${API_BASE_URL}/SystemConfiguration/getConfigByKey/:key`,


    USER_LIST_ALL: `${API_BASE_URL}/UserProfiles/getUserProfilesAll/:PageIndex/:PageSize`,
    USER_DETAILS: `${API_BASE_URL}/UserProfiles/getUserProfiles/:UserProfID`,
    USER_UPDATE: `${API_BASE_URL}/UserProfiles/Update`,
    USER_CHANGE_STATUS: `${API_BASE_URL}/UserProfiles/StatusUpdate`,
    USER_ROLE_MAP: `${API_BASE_URL}/Role/UserRoleMap`,


    COMPANY_LIST: `${API_BASE_URL}/Companies/getCompaniesAll/:index/:size`,
    COMPANY_ADD: `${API_BASE_URL}/Companies/SaveCompaniesFull`,
    COMPANY_EDIT: `${API_BASE_URL}/Companies/Update`,
    COMPANY_DETAILS: `${API_BASE_URL}/Companies/getCompanies/:id`,
    COMPANY_DELETE: `${API_BASE_URL}/Companies/Delete`,
    COMPANY_UPDATE_STATUS: `${API_BASE_URL}/Companies/StatusUpdate`,



    TENANTS_LIST: `${API_BASE_URL}/Tenants/getTenantsAll/:PageIndex/:PageSize`,
    TENANTS_GET: `${API_BASE_URL}/Tenants/getTenants/:TenantId`,
    TENANTS_SAVE: `${API_BASE_URL}/Tenants/Save`,
    TENANTS_UPDATE: `${API_BASE_URL}/Tenants/Update`,
    TENANTS_DELETE: `${API_BASE_URL}/Tenants/Delete`,
    TENANTS_CHANGE_STATUS: `${API_BASE_URL}/Tenants/StatusUpdate`,


    SYSTEM_CONFIG_LIST: `${API_BASE_URL}/SystemConfiguration/getConfigs/:PageIndex/:PageSize`,
    SYSTEM_CONFIG_GET: `${API_BASE_URL}/SystemConfiguration/getConfig/:ID`,
    SYSTEM_CONFIG_SAVE: `${API_BASE_URL}/SystemConfiguration/Save`,
    SYSTEM_CONFIG_UPDATE: `${API_BASE_URL}/SystemConfiguration/Update`,
    SYSTEM_CONFIG_DELETE: `${API_BASE_URL}/SystemConfiguration/Delete`,
    SYSTEM_CONFIG_CHANGE_STATUS: `${API_BASE_URL}/SystemConfiguration/StatusUpdate`,


    SYSTEM_ENUMS_LIST: `${API_BASE_URL}/SysEnums/getSysEnumsAll/:PageIndex/:PageSize`,
    SYSTEM_ENUMS_GET: `${API_BASE_URL}/SysEnums/getSysEnums/:SysEnumID`,
    SYSTEM_ENUMS_SAVE: `${API_BASE_URL}/SysEnums/Save`,
    SYSTEM_ENUMS_UPDATE: `${API_BASE_URL}/SysEnums/Update`,
    SYSTEM_ENUMS_DELETE: `${API_BASE_URL}/SysEnums/Delete`,
    SYSTEM_ENUMS_CHANGE_STATUS: `${API_BASE_URL}/SysEnums/StatusUpdate`,

    COUNTRY_LIST: `${API_BASE_URL}/Country/GetCountryList`,
    STATE_LIST: `${API_BASE_URL}/State/GetStateList`,
    CURRENCY_LIST: `${API_BASE_URL}/Currency/GetCurrencyList`,

    COMPANY_CONTACT_DETAILS: `${API_BASE_URL}/CompanyContacts/getCompanyContacts/:id`,
    COMPANY_CONTACT_LIST: `${API_BASE_URL}/CompanyContacts/getCompanyContactsByCompany/:id`,
    COMPANY_CONTACT_ALL_LIST: `${API_BASE_URL}/CompanyContacts/getCompanyContactsAll/:PageIndex/:PageSize`,
    COMPANY_CONTACT_ADD: `${API_BASE_URL}/CompanyContacts/Save`,
    COMPANY_CONTACT_EDIT: `${API_BASE_URL}/CompanyContacts/Update`,
    COMPANY_CONTACT_DELETE: `${API_BASE_URL}/CompanyContacts/Delete`,
    COMPANY_CONTACT_STATUS: `${API_BASE_URL}/CompanyContacts/StatusUpdate`,

    COMPANY_URLS_DETAILS: `${API_BASE_URL}/CompanyURLS/getCompanyURLS/:id`,
    COMPANY_URLS_LIST: `${API_BASE_URL}/CompanyURLS/getCompanyURLSByCompany/:id`,
    COMPANY_URLS_ALL_LIST: `${API_BASE_URL}/CompanyURLS/getCompanyURLSAll/:PageIndex/:PageSize`,
    COMPANY_URLS_ADD: `${API_BASE_URL}/CompanyURLS/Save`,
    COMPANY_URLS_EDIT: `${API_BASE_URL}/CompanyURLS/Update`,
    COMPANY_URLS_DELETE: `${API_BASE_URL}/CompanyURLS/Delete`,
    COMPANY_URLS_STATUS: `${API_BASE_URL}/CompanyURLS/StatusUpdate`,

    DISCOVERY_GET_SINGLE: `${API_BASE_URL}/Discovery/getDiscovery/:ID`,
    DISCOVERY_GET_ALL: `${API_BASE_URL}/Discovery/getAllDiscovery/:UseCaseID`,

  },
  FORM: {
    GET_WORKFLOWS: `${API_BUS_BASE_URL}/WorkFlows/getWorkFlowsByCompany/:companyID`,
    GET_MODULES: `${API_BUS_BASE_URL}/WorkFlows/getModulesByWorkFlowID/:WorkFowID/:companyID`,
    GET_FORMS: `${API_BUS_BASE_URL}/ModuleIs/getModuleForms/:ModuleID/:companyID`,
    GET_CONTROL_TYPES: `${API_BUS_BASE_URL}/ControlTypes/getControlTypesAll/:PageIndex/:PageSize`,
    GET_CONTROL_PROPS: `${API_BUS_BASE_URL}/ControlTypeProperties/getPropertiesByControlID/:ControlTypeID`,
    SAVE: `${API_BUS_BASE_URL}/Forms/SaveFull`,
  },

  SCORE_QUESTION: {
    LIST: `${API_BUS_BASE_URL}/ScoreQuestions/getScoreQuestionsAll/:PageIndex/:PageSize`,
    DETAILS: `${API_BUS_BASE_URL}/ScoreQuestions/getScoreQuestions/:ScoreQuestionID`,
    SAVE: `${API_BUS_BASE_URL}/ScoreQuestions/Save`,
    UPDATE: `${API_BUS_BASE_URL}/ScoreQuestions/Update`,
    CHANGE_STATUS: `${API_BUS_BASE_URL}/ScoreQuestions/StatusUpdate`,
    DELETE: `${API_BUS_BASE_URL}/ScoreQuestions/Delete`,
  },
  
  RUNWAY: {
    LIST: `${API_BUS_BASE_URL}/Runway/getRunwayAll/:PageIndex/:PageSize`,
    DETAILS: `${API_BUS_BASE_URL}/Runway/getRunway/:RunwayID`,
    SAVE: `${API_BUS_BASE_URL}/Runway/Save`,
    UPDATE: `${API_BUS_BASE_URL}/Runway/Update`,
    CHANGE_STATUS: `${API_BUS_BASE_URL}/Runway/StatusUpdate`,
    DELETE: `${API_BUS_BASE_URL}/Runway/Delete`,
  } 
 


}

export const ACTIONS = {
  RESET_REDUCER: 'ACTIONS/RESET_REDUCER',
  COMMON: {
    LOADER: {
      SET_FP_STATUS: 'ACTIONS/LOADER/SET_FP_STATUS',
    },
    SIDEBAR: {
      TOGGLE_COLLAPSED: 'ACTIONS/SIDEBAR/TOGGLE_COLLAPSED',
      TOGGLE_LOCKED: 'ACTIONS/SIDEBAR/TOGGLE_LOCKED',
    },
    SITE_MASTER: 'ACTIONS/COMMON/SITE_MASTER',
  },
  USER: {
    LOGIN: 'ACTIONS/USER/LOGIN',
    LOGOUT: 'ACTIONS/USER/LOGOUT',
    SIGNUP: 'ACTIONS/USER/SIGNUP',
    ME: 'ACTIONS/USER/ME',
    SIGNUP_SAVE: 'ACTIONS/USER/SIGNUP_SAVE',
    SIGNUP_RESET: 'ACTIONS/USER/SIGNUP_RESET',
    MERCHANT_SIGNUP: 'ACTIONS/USER/MERCHANT_SIGNUP',
  }
}

export const SAGA_ACTIONS: any = {
  COMMON: {
    SITE_MASTER: 'SAGA_ACTIONS/COMMON/SITE_MASTER',
    PHONE_CODES: 'SAGA_ACTIONS/COMMON/PHONE_CODES',
  },
  USER: {
    LOGIN: 'SAGA_ACTIONS/USER/LOGIN',
    LOGOUT: 'SAGA_ACTIONS/USER/LOGOUT',
    SIGNUP: 'SAGA_ACTIONS/USER/SIGNUP',
    FORGOT_PASSWORD: 'SAGA_ACTIONS/USER/FORGOT_PASSWORD',
    RESET_PASSWORD: 'SAGA_ACTIONS/USER/RESET_PASSWORD',
    EMPLOYEE_LIST: 'SAGA_ACTIONS/USER/EMPLOYEE_LIST',
    EMPLOYEE_ACTIVE_INACTIVE: 'SAGA_ACTIONS/USER/EMPLOYEE_ACTIVE_INACTIVE',
    ME: 'SAGA_ACTIONS/USER/ME',
    GET_FORM_CONFIG: 'SAGA_ACTIONS/USER/GET_FORM_CONFIG',
    SUBMIT_DYNAMIC_FORM: 'SAGA_ACTIONS/USER/SUBMIT_DYNAMIC_FORM',
    GET_MEMBER_DETAILS: 'SAGA_ACTIONS/USER/GET_MEMBER_DETAILS',
    ADD_EMPLOYEE: 'SAGA_ACTIONS/USER/ADD_EMPLOYEE',

    ROLE_ADD: 'SAGA_ACTIONS/USER/ROLE_ADD',
    ROLE_EDIT: 'SAGA_ACTIONS/USER/ROLE_EDIT',
    ROLE_DETAILS: 'SAGA_ACTIONS/USER/ROLE_DETAILS',
    ROLE_LIST: 'SAGA_ACTIONS/USER/ROLE_LIST',
    ROLE_DELETE: 'SAGA_ACTIONS/USER/ROLE_DELETE',


    USE_CASE_LIST: 'SAGA_ACTIONS/USER/USE_CASE_LIST',
    USE_CASE_DETAILS: 'SAGA_ACTIONS/USER/USE_CASE_DETAILS',
    AIRPORT_LIST: 'SAGA_ACTIONS/USER/AIRPORT_LIST',
    AIRPORT_ADD: 'SAGA_ACTIONS/USER/AIRPORT_ADD',
    AIRPORT_EDIT: 'SAGA_ACTIONS/USER/AIRPORT_EDIT',
    AIRPORT_DETAILS: 'SAGA_ACTIONS/USER/AIRPORT_DETAILS',
    AIRPORT_DELETE: 'SAGA_ACTIONS/USER/AIRPORT_DELETE',
    BY_ROLE_NAME: 'SAGA_ACTIONS/USER/BY_ROLE_NAME',
    CONFIG_BY_ROLE_NAME: 'SAGA_ACTIONS/USER/CONFIG_BY_ROLE_NAME',


    USER_LIST_ALL: 'SAGA_ACTIONS/USER/USER_LIST_ALL',
    USER_DETAILS: 'SAGA_ACTIONS/USER/USER_DETAILS',
    USER_UPDATE: 'SAGA_ACTIONS/USER/USER_UPDATE',
    USER_CHANGE_STATUS: 'SAGA_ACTIONS/USER/USER_CHANGE_STATUS',
    USER_ROLE_MAP: 'SAGA_ACTIONS/USER/USER_ROLE_MAP',

    COMPANY_LIST: 'SAGA_ACTIONS/USER/COMPANY_LIST',
    COMPANY_ADD: 'SAGA_ACTIONS/USER/COMPANY_ADD',
    COMPANY_EDIT: 'SAGA_ACTIONS/USER/COMPANY_EDIT',
    COMPANY_DETAILS: 'SAGA_ACTIONS/USER/COMPANY_DETAILS',
    COMPANY_DELETE: 'SAGA_ACTIONS/USER/COMPANY_DELETE',
    COMPANY_UPDATE_STATUS: 'SAGA_ACTIONS/USER/COMPANY_UPDATE_STATUS',

    TENANTS_LIST: 'SAGA_ACTIONS/USER/TENANTS_LIST',
    TENANTS_GET: 'SAGA_ACTIONS/USER/TENANTS_GET',
    TENANTS_SAVE: 'SAGA_ACTIONS/USER/TENANTS_SAVE',
    TENANTS_UPDATE: 'SAGA_ACTIONS/USER/TENANTS_UPDATE',
    TENANTS_DELETE: 'SAGA_ACTIONS/USER/TENANTS_DELETE',
    TENANTS_CHANGE_STATUS: 'SAGA_ACTIONS/USER/TENANTS_CHANGE_STATUS',


    SYSTEM_CONFIG_LIST: 'SAGA_ACTIONS/USER/SYSTEM_CONFIG_LIST',
    SYSTEM_CONFIG_GET: 'SAGA_ACTIONS/USER/SYSTEM_CONFIG_GET',
    SYSTEM_CONFIG_SAVE: 'SAGA_ACTIONS/USER/SYSTEM_CONFIG_SAVE',
    SYSTEM_CONFIG_UPDATE: 'SAGA_ACTIONS/USER/SYSTEM_CONFIG_UPDATE',
    SYSTEM_CONFIG_DELETE: 'SAGA_ACTIONS/USER/SYSTEM_CONFIG_DELETE',
    SYSTEM_CONFIG_CHANGE_STATUS: 'SAGA_ACTIONS/USER/SYSTEM_CONFIG_CHANGE_STATUS',


    SYSTEM_ENUMS_LIST: 'SAGA_ACTIONS/USER/SYSTEM_ENUMS_LIST',
    SYSTEM_ENUMS_GET: 'SAGA_ACTIONS/USER/SYSTEM_ENUMS_GET',
    SYSTEM_ENUMS_SAVE: 'SAGA_ACTIONS/USER/SYSTEM_ENUMS_SAVE',
    SYSTEM_ENUMS_UPDATE: 'SAGA_ACTIONS/USER/SYSTEM_ENUMS_UPDATE',
    SYSTEM_ENUMS_DELETE: 'SAGA_ACTIONS/USER/SYSTEM_ENUMS_DELETE',
    SYSTEM_ENUMS_CHANGE_STATUS: 'SAGA_ACTIONS/USER/SYSTEM_ENUMS_CHANGE_STATUS',



    COUNTRY_LIST: 'SAGA_ACTIONS/USER/COUNTRY_LIST',
    STATE_LIST: 'SAGA_ACTIONS/USER/STATE_LIST',
    CURRENCY_LIST: 'SAGA_ACTIONS/USER/CURRENCY_LIST',

    COMPANY_CONTACT_DETAILS: 'SAGA_ACTIONS/USER/COMPANY_CONTACT_DETAILS',
    COMPANY_CONTACT_LIST: 'SAGA_ACTIONS/USER/COMPANY_CONTACT_LIST',
    COMPANY_CONTACT_ALL_LIST: 'SAGA_ACTIONS/USER/COMPANY_CONTACT_ALL_LIST',
    COMPANY_CONTACT_ADD: 'SAGA_ACTIONS/USER/COMPANY_CONTACT_ADD',
    COMPANY_CONTACT_EDIT: 'SAGA_ACTIONS/USER/COMPANY_CONTACT_EDIT',
    COMPANY_CONTACT_DELETE: 'SAGA_ACTIONS/USER/COMPANY_CONTACT_DELETE',
    COMPANY_CONTACT_STATUS: 'SAGA_ACTIONS/USER/COMPANY_CONTACT_STATUS',

    COMPANY_URLS_DETAILS: 'SAGA_ACTIONS/USER/COMPANY_URLS_DETAILS',
    COMPANY_URLS_LIST: 'SAGA_ACTIONS/USER/COMPANY_URLS_LIST',
    COMPANY_URLS_ALL_LIST: 'SAGA_ACTIONS/USER/COMPANY_URLS_ALL_LIST',
    COMPANY_URLS_ADD: 'SAGA_ACTIONS/USER/COMPANY_URLS_ADD',
    COMPANY_URLS_EDIT: 'SAGA_ACTIONS/USER/COMPANY_URLS_EDIT',
    COMPANY_URLS_DELETE: 'SAGA_ACTIONS/USER/COMPANY_URLS_DELETE',
    COMPANY_URLS_STATUS: 'SAGA_ACTIONS/USER/COMPANY_URLS_STATUS',

    DISCOVERY_GET_SINGLE: 'SAGA_ACTIONS/USER/DISCOVERY_GET_SINGLE',
    DISCOVERY_GET_ALL: 'SAGA_ACTIONS/USER/DISCOVERY_GET_ALL',


  },
  FORM: {
    GET_WORKFLOWS: 'SAGA_ACTIONS/FORM/GET_WORKFLOWS',
    GET_MODULES: 'SAGA_ACTIONS/FORM/GET_MODULES',
    GET_FORMS: 'SAGA_ACTIONS/FORM/GET_FORMS',
    GET_CONTROL_TYPES: 'SAGA_ACTIONS/FORM/GET_CONTROL_TYPES',
    GET_CONTROL_PROPS: 'SAGA_ACTIONS/FORM/GET_CONTROL_PROPS',
    SAVE: 'SAGA_ACTIONS/FORM/SAVE',
  },

  SCORE_QUESTION: {
    LIST: 'SAGA_ACTIONS/SCORE_QUESTION/GET_WORKFLOWS',
    DETAILS: 'SAGA_ACTIONS/SCORE_QUESTION/DETAILS',
    SAVE: 'SAGA_ACTIONS/SCORE_QUESTION/SAVE',
    UPDATE: 'SAGA_ACTIONS/SCORE_QUESTION/UPDATE', 
    CHANGE_STATUS: 'SAGA_ACTIONS/SCORE_QUESTION/CHANGE_STATUS', 
    DELETE: 'SAGA_ACTIONS/SCORE_QUESTION/DELETE', 
  }, 

  RUNWAY: {
    LIST: 'SAGA_ACTIONS/RUNWAY/LIST',
    DETAILS: 'SAGA_ACTIONS/RUNWAY/DETAILS',
    SAVE: 'SAGA_ACTIONS/RUNWAY/SAVE',
    UPDATE: 'SAGA_ACTIONS/RUNWAY/UPDATE', 
    CHANGE_STATUS: 'SAGA_ACTIONS/RUNWAY/CHANGE_STATUS', 
    DELETE: 'SAGA_ACTIONS/RUNWAY/DELETE', 
  }, 


}
