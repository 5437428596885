/* eslint-disable @typescript-eslint/ban-types */
import { useApiCall } from '../common/appApiCallHook'
//import { BlankReq, LoginReq, LogoutReq } from '../../interfaces/ApiReqRes'
import { SAGA_ACTIONS } from '../../../_config/index'



export function useUserApi() {
  const callApi = useApiCall()



  const login = (data: any, onSuccess: Function, onError: Function) => {
    // console.log('data==>', data)
    callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError)
  }

  const getMemberDetails = (data: any, onSuccess: Function, onError: Function) => {
    // console.log('data==>', data)
    callApi(SAGA_ACTIONS.USER.GET_MEMBER_DETAILS, data, onSuccess, onError)
  }

  const logout = (data: any, onSuccess: Function, onError: Function) => {
    /*     onboardingAction.resetOnboarding()
        signupAction.resetSignup() */
    callApi(SAGA_ACTIONS.USER.LOGOUT, data, onSuccess, onError)
  }


  const forgotPassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.FORGOT_PASSWORD, data, onSuccess, onError)
  }

  const resetPassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.RESET_PASSWORD, data, onSuccess, onError)
  }

  const employeeList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.EMPLOYEE_LIST, data, onSuccess, onError)
  }
  const getFormConfig = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.GET_FORM_CONFIG, data, onSuccess, onError)
  }
  const submitDynamicForm = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SUBMIT_DYNAMIC_FORM, data, onSuccess, onError)
  }

  const memberActivation = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.EMPLOYEE_ACTIVE_INACTIVE, data, onSuccess, onError)
  }
  const addEmployee = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.ADD_EMPLOYEE, data, onSuccess, onError)
  }

  const deleteEmployee = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.EMPLOYEE_DELETE, data, onSuccess, onError)
  }


  const userDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.USER_DETAILS, data, onSuccess, onError)
  }
  const userUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.USER_UPDATE, data, onSuccess, onError)
  }
  const userChangeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.USER_CHANGE_STATUS, data, onSuccess, onError)
  }



  const addRole = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.ROLE_ADD, data, onSuccess, onError)
  }
  const editRole = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.ROLE_EDIT, data, onSuccess, onError)
  }
  const detailsRole = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.ROLE_DETAILS, data, onSuccess, onError)
  }
  const listRole = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.ROLE_LIST, data, onSuccess, onError)
  }
  const deleteRole = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.ROLE_DELETE, data, onSuccess, onError)
  }

  const userRoleMap = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.USER_ROLE_MAP, data, onSuccess, onError)
  }

  const useCaseList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.USE_CASE_LIST, data, onSuccess, onError)
  }
  const useCaseDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.USE_CASE_DETAILS, data, onSuccess, onError)
  }
  const airportList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.AIRPORT_LIST, data, onSuccess, onError)
  }


  const airportAdd = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.AIRPORT_ADD, data, onSuccess, onError)
  }
  const airportEdit = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.AIRPORT_EDIT, data, onSuccess, onError)
  }
  const airportDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.AIRPORT_DETAILS, data, onSuccess, onError)
  }
  const airportDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.AIRPORT_DELETE, data, onSuccess, onError)
  }

  const getUsersByRoleName = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.BY_ROLE_NAME, data, onSuccess, onError)
  }

  const getConfigByKey = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.CONFIG_BY_ROLE_NAME, data, onSuccess, onError)
  }

  const companyList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_LIST, data, onSuccess, onError)
  }

  const companyAdd = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_ADD, data, onSuccess, onError)
  }

  const companyEdit = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_EDIT, data, onSuccess, onError)
  }

  const companyDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_DETAILS, data, onSuccess, onError)
  }

  const companyDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_DELETE, data, onSuccess, onError)
  }

  const companyStatusUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_UPDATE_STATUS, data, onSuccess, onError)
  }



  const getTenantsAll = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.TENANTS_LIST, data, onSuccess, onError)
  }
  const getTenants = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.TENANTS_GET, data, onSuccess, onError)
  }
  const tenantSave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.TENANTS_SAVE, data, onSuccess, onError)
  }
  const tenantUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.TENANTS_UPDATE, data, onSuccess, onError)
  }
  const tenantDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.TENANTS_DELETE, data, onSuccess, onError)
  }
  const tenantChangeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.TENANTS_CHANGE_STATUS, data, onSuccess, onError)
  }



  const getSystemConfigAll = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SYSTEM_CONFIG_LIST, data, onSuccess, onError)
  }
  const getSystemConfig = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SYSTEM_CONFIG_GET, data, onSuccess, onError)
  }
  const systemConfigSave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SYSTEM_CONFIG_SAVE, data, onSuccess, onError)
  }
  const systemConfigUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SYSTEM_CONFIG_UPDATE, data, onSuccess, onError)
  }
  const systemConfigDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SYSTEM_CONFIG_DELETE, data, onSuccess, onError)
  }
  const systemConfigChangeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SYSTEM_CONFIG_CHANGE_STATUS, data, onSuccess, onError)
  }

 
  const getSystemEnumsAll = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SYSTEM_ENUMS_LIST, data, onSuccess, onError)
  }
  const getSystemEnums = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SYSTEM_ENUMS_GET, data, onSuccess, onError)
  }
  const systemEnumsSave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SYSTEM_ENUMS_SAVE, data, onSuccess, onError)
  }
  const systemEnumsUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SYSTEM_ENUMS_UPDATE, data, onSuccess, onError)
  }
  const systemEnumsDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SYSTEM_ENUMS_DELETE, data, onSuccess, onError)
  }
  const systemEnumsChangeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SYSTEM_ENUMS_CHANGE_STATUS, data, onSuccess, onError)
  }

  const getCountryList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COUNTRY_LIST, data, onSuccess, onError)
  }

  const getStateList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.STATE_LIST, data, onSuccess, onError)
  }

  const getCurrencyList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.CURRENCY_LIST, data, onSuccess, onError)
  }

  const companyContactsDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_CONTACT_DETAILS, data, onSuccess, onError)
  }

  const companyContactsList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_CONTACT_LIST, data, onSuccess, onError)
  }

  const companyContactsAllList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_CONTACT_ALL_LIST, data, onSuccess, onError)
  }

  const companyContactsAdd = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_CONTACT_ADD, data, onSuccess, onError)
  }

  const companyContactsEdit = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_CONTACT_EDIT, data, onSuccess, onError)
  }

  const companyContactsDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_CONTACT_DELETE, data, onSuccess, onError)
  }

  const companyContactsStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_CONTACT_STATUS, data, onSuccess, onError)
  }

  // CompanyURLS --------------------------

  const companyUrlsDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_URLS_DETAILS, data, onSuccess, onError)
  }

  const companyUrlsList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_URLS_LIST, data, onSuccess, onError)
  }

  const companyUrlsAllList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_URLS_ALL_LIST, data, onSuccess, onError)
  }

  const companyUrlsAdd = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_URLS_ADD, data, onSuccess, onError)
  }

  const companyUrlsEdit = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_URLS_EDIT, data, onSuccess, onError)
  }

  const companyUrlsDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_URLS_DELETE, data, onSuccess, onError)
  }

  const companyUrlsStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.COMPANY_URLS_STATUS, data, onSuccess, onError)
  }


  const getDiscovery = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DISCOVERY_GET_SINGLE, data, onSuccess, onError)
  }
  const getAllDiscovery = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DISCOVERY_GET_ALL, data, onSuccess, onError)
  }
 

  // /api/v{version}/Runway/getRunwayAll/{PageIndex}/{PageSize}
  const runwayList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RUNWAY.LIST, data, onSuccess, onError)
  } 
  const runwaySave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RUNWAY.SAVE, data, onSuccess, onError)
  }
  const runwayUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RUNWAY.UPDATE, data, onSuccess, onError)
  }
  const runwayDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RUNWAY.DETAILS, data, onSuccess, onError)
  }
  const runwayDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RUNWAY.DELETE, data, onSuccess, onError)
  }
  const runwayChangeStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RUNWAY.CHANGE_STATUS, data, onSuccess, onError)
  }
 
 

  return {
    login,
    logout,
    forgotPassword,
    resetPassword,
    employeeList,
    getFormConfig,
    submitDynamicForm,
    memberActivation,
    getMemberDetails,
    addEmployee,
    deleteEmployee,

    userDetails,
    userUpdate,
    userChangeStatus,


    addRole,
    editRole,
    detailsRole,
    listRole,
    deleteRole,
    userRoleMap,
    useCaseList,
    useCaseDetails,
    airportList,
    airportAdd,
    getUsersByRoleName,
    getConfigByKey,

    airportDetails,
    airportEdit,
    airportDelete,

    companyList,
    companyAdd,
    companyDetails,
    companyEdit,
    companyDelete,
    companyStatusUpdate,


    getTenantsAll,
    getTenants,
    tenantSave,
    tenantUpdate,
    tenantDelete,
    tenantChangeStatus,

    getSystemConfigAll,
    getSystemConfig,
    systemConfigSave,
    systemConfigUpdate,
    systemConfigDelete,
    systemConfigChangeStatus,
 
    getSystemEnumsAll,
    getSystemEnums,
    systemEnumsSave,
    systemEnumsUpdate,
    systemEnumsDelete,
    systemEnumsChangeStatus,

    getCountryList,
    getStateList,
    getCurrencyList,

    companyContactsDetails,
    companyContactsList,
    companyContactsAllList,
    companyContactsAdd,
    companyContactsEdit,
    companyContactsDelete,
    companyContactsStatus,

    companyUrlsDetails,    
    companyUrlsList,
    companyUrlsAllList,
    companyUrlsAdd,
    companyUrlsEdit,
    companyUrlsDelete,
    companyUrlsStatus,

    getDiscovery,
    getAllDiscovery, 
 
    runwayList,
    runwaySave,
    runwayUpdate,
    runwayDetails,
    runwayDelete,
    runwayChangeStatus,
     
  }
}
