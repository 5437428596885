import React from 'react'
// import Sidebar from './common/Sidebar'
//import SidebarPayee from './common/SidebarPayee'

const PrivateLayout = ({ children }:any) => {
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="d-flex flex-column min-vh-100">{children}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PrivateLayout
