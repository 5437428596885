export const replaceUrlParams = (url: string, params: any) => {
  Object.keys(params).map((param) => {
    url = url.replace(`:${param}`, params[param])
  })
  return url
}

export const capitalizeFirstLetter = (str: string) => {
  const firstSpaceIndex = str.indexOf(' ');
  if (firstSpaceIndex !== -1 && firstSpaceIndex < str.length - 1) {
      // Extract the first letter after the first space and make it uppercase
      return str.charAt(firstSpaceIndex + 1).toUpperCase();
  } else {
      // If there's no space or it's the last character, use the first letter and make it uppercase
      return str.charAt(0).toUpperCase();
  }
};

