import React, { useState } from "react";
import Error from "../elements/formFieldError";
import { Controller, useFieldArray } from "react-hook-form";
import Select from "react-select";
import Form from 'react-bootstrap/Form';
import { useFormApi } from "../../_common/hooks/api/FormApiHook";
import { SAGA_ACTIONS } from '../../_config/index'
import { Button } from "react-bootstrap";
interface props {
    index: number
    control: any
    register: any
    getValues: any
    setValue: any
    errors: any
    prop:any
}
const OptionGenerators = ({ index, control, register, getValues, setValue,  errors, prop }: props) => {

    /* const [controlProps,setControlProps] = useState<any>(null);

    const formApi = useFormApi(); */
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: "option", 
    });

    const addNewOption = ()=>{
        append({value:'',label:''})
    }

    const colSpanOptions = [
        {value:'1',label:'1'},
        {value:'2',label:'2'},
        {value:'3',label:'3'},
    ]

 

    return (
        <>
            <div className="row">
                {fields.map((field, i) => (
                    <div className="row mb-3" key={i}>
                        <div className="col-md-6">
                            <label htmlFor="tenant" className="form-label">Value</label>
                            <input className="form-control" type="text" {...register(`field.${index}.prop.${prop.propertyName}.options.${i}.value`)} />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="tenant" className="form-label">Label</label>
                            <input className="form-control" type="text" {...register(`field.${index}.prop.${prop.propertyName}.options.${i}.label`)} />
                        </div>
                    </div>
                ))}
            </div>
            <Button onClick={addNewOption}>Add New Option</Button>
        </>
    );
}

export default OptionGenerators;