import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import './intectFlow.scss'

import Masonry from 'masonry-layout';
import { useUserApi } from "../../_common/hooks/api/UserApiHook";


const IdeaListTwo = () => {

    const [useCaseList, setUseCaseList] = useState<Array<any>>([]);
    const userApi = useUserApi();

    const getUseCaseList = () => {
        userApi.useCaseList({}, (message: any, resp: any) => {            
            setUseCaseList(resp.data);
        }, (message: any, resp: any) => {
            // Error...
        })

    }

    const formatDate = (dateString: any) => {
        const options: object = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    }


    const masonryContainerRef = useRef<HTMLDivElement | null>(null);

    const initializeMasonry = () => {

        if (masonryContainerRef.current) {

            const containerElement = masonryContainerRef.current;
            let masonry: any = new Masonry(containerElement, {
                itemSelector: '.masonry-item',
                percentPosition: true,
            });    

        }

    }

    useEffect(() => {
        getUseCaseList();
        initializeMasonry();
        
    }, []);




    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><a href="#">Dashboard</a></li>
                                    <li>Use case library</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">Use case library</h1>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="table-filter-panel mb-3 d-flex gap-3">
                                <div className="flex-1">
                                    <label>Section</label>
                                    <select className="form-control">
                                        <option>All</option>
                                    </select>
                                </div>
                                <div className="flex-1">
                                    <label>Phase</label>
                                    <select className="form-control">
                                        <option>All</option>
                                    </select>
                                </div>
                                <div className="flex-1">
                                    <label>Airport</label>
                                    <select className="form-control">
                                        <option>All</option>
                                    </select>
                                </div>
                                <div className="flex-1">
                                    <label>Airport</label>
                                    <select className="form-control">
                                        <option>All</option>
                                    </select>
                                </div>
                                <div className="flex-1">
                                    <label>Captain</label>
                                    <select className="form-control">
                                        <option>All</option>
                                    </select>
                                </div>
                                <div className="flex-1">
                                    <label>Date</label>
                                    <div className="d-flex date-range-flds">
                                        <input type="date" className="form-control" placeholder="Start Date" />
                                        <input type="date" className="form-control" placeholder="End Date" />
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h1 className="h5 mb-0 font-weight-600"></h1>
                                <div className="btn-group ms-auto pe-2">
                                    <Link to="/use-case-library-list" className="btn btn-primary">List</Link>
                                    <Link to="/use-case-library-grid" className="btn btn-primary">Grid</Link>
                                </div>
                                <Link to="/idea-submit" className="btn btn-info py-2 add-more-files-btn">Create Usecase</Link>
                            </div>

                            <div className="pt-3">
                                <div className="masonry-container">
                                    <div className="masonry-idea-list">

                                        <div className="row" ref={masonryContainerRef} >
                                            {useCaseList && useCaseList.length > 0 ? (
                                                useCaseList.map((usecase) => (

                                                    <div className="col-sm-6 col-lg-3 mb-2 masonry-item" key={usecase.useCaseID}>
                                                        <div className="card">
                                                            <Link to={`/use-case/${usecase.useCaseID}`}>
                                                                <img src={usecase.imgURL}
                                                                    alt=""
                                                                    loading="lazy"
                                                                    data-src={usecase.imgURL}
                                                                    onLoad={() => { initializeMasonry() }}
                                                                />
                                                            </Link>
                                                            <div className="card-body">
                                                                <div className="d-flex justify-content-between">
                                                                    <div>
                                                                        <h4 className="text-sm font-weight-600 mb-0">{usecase.useCase}</h4>
                                                                        <p className="text-sm text-grey">Tanmay Mandal</p>
                                                                    </div>
                                                                    <div>
                                                                        <h6 className="text-xs text-grey">{formatDate(usecase.addedDate)}</h6>
                                                                        <span className="tags tags-purple">{usecase.status}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex idea-stats-panel mt-2">
                                                                    <div className="flex-1 idea-stats-block">
                                                                        <h6 className="text-sm text-white pb-2">Score</h6>
                                                                        <h6>0</h6>
                                                                    </div>
                                                                    <div className="flex-1 idea-stats-block">
                                                                        <img src="./images/rank.png" alt="#" />
                                                                        <h6>{usecase.rank ? usecase.rank : '0'}</h6>
                                                                    </div>
                                                                    <div className="flex-1 idea-stats-block">
                                                                        <img src="./images/vote.svg" alt="#" />
                                                                        <h6>{usecase.likes ? usecase.likes : '0'}</h6>
                                                                    </div>
                                                                    <div className="idea-stats-section">
                                                                        <h5 className="text-sm text-white">Section</h5>
                                                                        <h6 className="text-sm text-white">Hanger</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex mt-3">
                                                                    <div className="flex-1 px-1">
                                                                        <h5 className="text-sm">Airport</h5>
                                                                        <p className="text-sm mb-0">
                                                                            <Link to="#">{usecase.airPort ? usecase.airPort : 'NA'}</Link>
                                                                        </p>
                                                                    </div>
                                                                    <div className="flex-1 px-1">
                                                                        <h5 className="text-sm">Captain</h5>
                                                                        <p className="text-sm mb-0"><Link to="#">{usecase.captain ? usecase.captain : 'NA'}</Link></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <p>
                                                    {/* Data Not Found! */}
                                                </p>

                                            )}



                                            {/* <div className="col-sm-6 col-lg-3 mb-2 masonry-item">

                                                <div className="card">
                                                    <a href="discovery.html"><img src="./images/pic-1.avif" alt="" /></a>
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <h4 className="text-sm font-weight-600 mb-0">SVP, Energy Delivery IOU...</h4>
                                                                <p className="text-sm text-grey">Bob Taylor</p>
                                                            </div>
                                                            <div>
                                                                <h6 className="text-xs text-grey">09/15/23</h6>
                                                                <span className="tags tags-purple">Under Review</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex idea-stats-panel mt-2">
                                                            <div className="flex-1 idea-stats-block">
                                                                <h6 className="text-sm text-white pb-2">Score</h6>
                                                                <h6>500</h6>
                                                            </div>
                                                            <div className="flex-1 idea-stats-block">
                                                                <img src="./images/rank.png" alt="#" />
                                                                <h6>125</h6>
                                                            </div>
                                                            <div className="flex-1 idea-stats-block">
                                                                <img src="./images/vote.svg" alt="#" />
                                                                <h6>5000K</h6>
                                                            </div>
                                                            <div className="idea-stats-section">
                                                                <h5 className="text-sm text-white">Section</h5>
                                                                <h6 className="text-sm text-white">Hanger</h6>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mt-3">
                                                            <div className="flex-1 px-1">
                                                                <h5 className="text-sm">Airport</h5>
                                                                <p className="text-sm mb-0"><a href="#">Energy Efficiency & Demand Response</a></p>
                                                            </div>
                                                            <div className="flex-1 px-1">
                                                                <h5 className="text-sm">Captain</h5>
                                                                <p className="text-sm mb-0"><a href="#">Chris Howard</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3 mb-2 masonry-item">

                                                <div className="card">
                                                    <img src="./images/pic-2.avif" alt="" />
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <h4 className="text-sm font-weight-600 mb-0">SVP, Energy Delivery IOU...</h4>
                                                                <p className="text-sm text-grey">Bob Taylor</p>
                                                            </div>
                                                            <div>
                                                                <h6 className="text-xs text-grey">09/15/23</h6>
                                                                <span className="tags tags-purple">Under Review</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex idea-stats-panel mt-2">
                                                            <div className="flex-1 idea-stats-block">
                                                                <h6 className="text-sm text-white pb-2">Score</h6>
                                                                <h6>500</h6>
                                                            </div>
                                                            <div className="flex-1 idea-stats-block">
                                                                <img src="./images/rank.png" alt="#" />
                                                                <h6>125</h6>
                                                            </div>
                                                            <div className="flex-1 idea-stats-block">
                                                                <img src="./images/vote.svg" alt="#" />
                                                                <h6>5000K</h6>
                                                            </div>
                                                            <div className="idea-stats-section">
                                                                <h5 className="text-sm text-white">Section</h5>
                                                                <h6 className="text-sm text-white">Hanger</h6>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mt-3">
                                                            <div className="flex-1 px-1">
                                                                <h5 className="text-sm">Airport</h5>
                                                                <p className="text-sm mb-0"><a href="#">Energy Efficiency & Demand Response</a></p>
                                                            </div>
                                                            <div className="flex-1 px-1">
                                                                <h5 className="text-sm">Captain</h5>
                                                                <p className="text-sm mb-0"><a href="#">Chris Howard</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3 mb-2 masonry-item">
                                                <div className="card">
                                                    <img src="./images/pic-3.avif" alt="" />
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <h4 className="text-sm font-weight-600 mb-0">SVP, Energy Delivery IOU...</h4>
                                                                <p className="text-sm text-grey">Bob Taylor</p>
                                                            </div>
                                                            <div>
                                                                <h6 className="text-xs text-grey">09/15/23</h6>
                                                                <span className="tags tags-purple">Under Review</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex idea-stats-panel mt-2">
                                                            <div className="flex-1 idea-stats-block">
                                                                <h6 className="text-sm text-white pb-2">Score</h6>
                                                                <h6>500</h6>
                                                            </div>
                                                            <div className="flex-1 idea-stats-block">
                                                                <img src="./images/rank.png" alt="#" />
                                                                <h6>125</h6>
                                                            </div>
                                                            <div className="flex-1 idea-stats-block">
                                                                <img src="./images/vote.svg" alt="#" />
                                                                <h6>5000K</h6>
                                                            </div>
                                                            <div className="idea-stats-section">
                                                                <h5 className="text-sm text-white">Section</h5>
                                                                <h6 className="text-sm text-white">Hanger</h6>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mt-3">
                                                            <div className="flex-1 px-1">
                                                                <h5 className="text-sm">Airport</h5>
                                                                <p className="text-sm mb-0"><a href="#">Energy Efficiency & Demand Response</a></p>
                                                            </div>
                                                            <div className="flex-1 px-1">
                                                                <h5 className="text-sm">Captain</h5>
                                                                <p className="text-sm mb-0"><a href="#">Chris Howard</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3 mb-2 masonry-item">
                                                <div className="card">
                                                    <img src="./images/pic-4.avif" alt="" />
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <h4 className="text-sm font-weight-600 mb-0">SVP, Energy Delivery IOU...</h4>
                                                                <p className="text-sm text-grey">Bob Taylor</p>
                                                            </div>
                                                            <div>
                                                                <h6 className="text-xs text-grey">09/15/23</h6>
                                                                <span className="tags tags-purple">Under Review</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex idea-stats-panel mt-2">
                                                            <div className="flex-1 idea-stats-block">
                                                                <h6 className="text-sm text-white pb-2">Score</h6>
                                                                <h6>500</h6>
                                                            </div>
                                                            <div className="flex-1 idea-stats-block">
                                                                <img src="./images/rank.png" alt="#" />
                                                                <h6>125</h6>
                                                            </div>
                                                            <div className="flex-1 idea-stats-block">
                                                                <img src="./images/vote.svg" alt="#" />
                                                                <h6>5000K</h6>
                                                            </div>
                                                            <div className="idea-stats-section">
                                                                <h5 className="text-sm text-white">Section</h5>
                                                                <h6 className="text-sm text-white">Hanger</h6>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mt-3">
                                                            <div className="flex-1 px-1">
                                                                <h5 className="text-sm">Airport</h5>
                                                                <p className="text-sm mb-0"><a href="#">Energy Efficiency & Demand Response</a></p>
                                                            </div>
                                                            <div className="flex-1 px-1">
                                                                <h5 className="text-sm">Captain</h5>
                                                                <p className="text-sm mb-0"><a href="#">Chris Howard</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-sm-6 col-lg-3 mb-2 masonry-item">
                                                <div className="card">
                                                    <img src="./images/pic-5.avif" alt="" />
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <h4 className="text-sm font-weight-600 mb-0">SVP, Energy Delivery IOU...</h4>
                                                                <p className="text-sm text-grey">Bob Taylor</p>
                                                            </div>
                                                            <div>
                                                                <h6 className="text-xs text-grey">09/15/23</h6>
                                                                <span className="tags tags-purple">Under Review</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex idea-stats-panel mt-2">
                                                            <div className="flex-1 idea-stats-block">
                                                                <h6 className="text-sm text-white pb-2">Score</h6>
                                                                <h6>500</h6>
                                                            </div>
                                                            <div className="flex-1 idea-stats-block">
                                                                <img src="./images/rank.png" alt="#" />
                                                                <h6>125</h6>
                                                            </div>
                                                            <div className="flex-1 idea-stats-block">
                                                                <img src="./images/vote.svg" alt="#" />
                                                                <h6>5000K</h6>
                                                            </div>
                                                            <div className="idea-stats-section">
                                                                <h5 className="text-sm text-white">Section</h5>
                                                                <h6 className="text-sm text-white">Hanger</h6>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mt-3">
                                                            <div className="flex-1 px-1">
                                                                <h5 className="text-sm">Airport</h5>
                                                                <p className="text-sm mb-0"><a href="#">Energy Efficiency & Demand Response</a></p>
                                                            </div>
                                                            <div className="flex-1 px-1">
                                                                <h5 className="text-sm">Captain</h5>
                                                                <p className="text-sm mb-0"><a href="#">Chris Howard</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-3 mb-2 masonry-item">
                                                <div className="card">
                                                    <img src="./images/pic-6.avif" alt="" />
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <h4 className="text-sm font-weight-600 mb-0">SVP, Energy Delivery IOU...</h4>
                                                                <p className="text-sm text-grey">Bob Taylor</p>
                                                            </div>
                                                            <div>
                                                                <h6 className="text-xs text-grey">09/15/23</h6>
                                                                <span className="tags tags-purple">Under Review</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex idea-stats-panel mt-2">
                                                            <div className="flex-1 idea-stats-block">
                                                                <h6 className="text-sm text-white pb-2">Score</h6>
                                                                <h6>500</h6>
                                                            </div>
                                                            <div className="flex-1 idea-stats-block">
                                                                <img src="./images/rank.png" alt="#" />
                                                                <h6>125</h6>
                                                            </div>
                                                            <div className="flex-1 idea-stats-block">
                                                                <img src="./images/vote.svg" alt="#" />
                                                                <h6>5000K</h6>
                                                            </div>
                                                            <div className="idea-stats-section">
                                                                <h5 className="text-sm text-white">Section</h5>
                                                                <h6 className="text-sm text-white">Hanger</h6>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mt-3">
                                                            <div className="flex-1 px-1">
                                                                <h5 className="text-sm">Airport</h5>
                                                                <p className="text-sm mb-0"><a href="#">Energy Efficiency & Demand Response</a></p>
                                                            </div>
                                                            <div className="flex-1 px-1">
                                                                <h5 className="text-sm">Captain</h5>
                                                                <p className="text-sm mb-0"><a href="#">Chris Howard</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-sm-6 col-lg-3 mb-2 masonry-item">
                                                <div className="card">
                                                    <img src="./images/pic-7.avif" alt="" />
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <h4 className="text-sm font-weight-600 mb-0">SVP, Energy Delivery IOU...</h4>
                                                                <p className="text-sm text-grey">Bob Taylor</p>
                                                            </div>
                                                            <div>
                                                                <h6 className="text-xs text-grey">09/15/23</h6>
                                                                <span className="tags tags-purple">Under Review</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex idea-stats-panel mt-2">
                                                            <div className="flex-1 idea-stats-block">
                                                                <h6 className="text-sm text-white pb-2">Score</h6>
                                                                <h6>500</h6>
                                                            </div>
                                                            <div className="flex-1 idea-stats-block">
                                                                <img src="./images/rank.png" alt="#" />
                                                                <h6>125</h6>
                                                            </div>
                                                            <div className="flex-1 idea-stats-block">
                                                                <img src="./images/vote.svg" alt="#" />
                                                                <h6>5000K</h6>
                                                            </div>
                                                            <div className="idea-stats-section">
                                                                <h5 className="text-sm text-white">Section</h5>
                                                                <h6 className="text-sm text-white">Hanger</h6>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mt-3">
                                                            <div className="flex-1 px-1">
                                                                <h5 className="text-sm">Airport</h5>
                                                                <p className="text-sm mb-0"><a href="#">Energy Efficiency & Demand Response</a></p>
                                                            </div>
                                                            <div className="flex-1 px-1">
                                                                <h5 className="text-sm">Captain</h5>
                                                                <p className="text-sm mb-0"><a href="#">Chris Howard</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                        </div>

                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>
    );
}

export default IdeaListTwo;