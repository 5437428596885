import axios from 'axios'
import { STORAGE, URLS } from '../../_config'

function getToken() {
  let token_str = localStorage.getItem(STORAGE)
  if (token_str) {
    return JSON.parse(token_str).accessToken
  } else {
    return null
  }
}

export const responseHandler = (resp)=>{

  if (resp.status >= 200 && resp.status < 300 && resp.data) {
    if(resp.data.data.exceptions.length){
      return {status:false, data:resp.data.data.exceptions.join(',')}
    } else {
      return {status:true, data:resp.data}
    }
  } else {
    return {status:false, data:resp.data}
  }
}

export class CallApi {
  static GET(url, params, isAuth, isAdaptive) {
    
    return new Promise((resolve, reject) => {      
      const urls = new URL(window?.location?.href)
      let headers = { 'Content-Type': 'application/json' }      
      if (isAuth || isAdaptive) {
        const token = getToken()
        
        if (token) {
          headers.Authorization = `Bearer ${token}`
          headers.AppHost = urls.hostname
          // headers.AppHost = 'localhost'
        }
      } else {
        headers.AppHost = urls.hostname
        // headers.AppHost = 'localhost'
      }
      return axios({
        method: 'GET',
        url,
        headers,
        params,
      })
        .then(success => {
          return resolve(success)
        })
        .catch(error => {
          if (
            error.response &&
            error.response.status === 401 &&
            window.location.pathname != URLS.LOGIN
          ) {
            localStorage.removeItem(STORAGE)
            window.location.href = URLS.LOGIN
          }
          return reject(error.response)
        })
    })
  }

  static POST(url, data, isAuth, isAdaptive) {
    return new Promise((resolve, reject) => {
      const urls = new URL(window?.location?.href)
      // let headers = { 'Content-Type': 'application/json' }
      let headers = {}

      const token = getToken()
      if (isAuth || token) {
        const token = getToken()
        if (token) {
          headers.Authorization = `Bearer ${token}`
          headers.AppHost = urls.hostname
        }
      } else {
        headers.AppHost = urls.hostname
      }
      return axios({
        method: 'POST',
        url,
        headers,
        data,
      })
        .then(success => {
          return resolve(success)
        })
        .catch(error => {
          if (
            error.response &&
            error.response.status === 401 &&
            window.location.pathname != URLS.LOGIN
          ) {
            localStorage.removeItem(STORAGE)
            window.location.href = URLS.LOGIN
          }
          return reject(error.response)
        })
    })
  }

  static PUT(url, data, isAuth, isAdaptive) {
    return new Promise((resolve, reject) => {
      const urls = new URL(window?.location?.href)
      let headers = { 'Content-Type': 'application/json' }
      if (isAuth || isAdaptive) {
        const token = getToken()
        if (token) {
          headers.Authorization = `Bearer ${token}`
          headers.AppHost = urls.hostname
          // headers.AppHost = 'localhost'
        }
      } else {
        headers.AppHost = urls.hostname
        // headers.AppHost = 'localhost'
      }
      return axios({
        method: 'PUT',
        url,
        headers,
        data,
      })
        .then(success => {
          return resolve(success)
        })
        .catch(error => {
          if (
            error.response &&
            error.response.status === 401 &&
            window.location.pathname != URLS.LOGIN
          ) {
            localStorage.removeItem(STORAGE)
            window.location.href = URLS.LOGIN
          }
          return reject(error.response)
        })
    })
  }

  static DELETE(url, data, isAuth, isAdaptive) {
    return new Promise((resolve, reject) => {
      const urls = new URL(window?.location?.href)
      let headers = { 'Content-Type': 'application/json' }
      const token = getToken()
      if (isAuth || token) {
        // const token = getToken()
        if (token) {
          headers.Authorization = `Bearer ${token}`
          headers.AppHost = urls.hostname
          // headers.AppHost = 'localhost'
        }
      } else {
        headers.AppHost = urls.hostname
        // headers.AppHost = 'localhost'
      }
      return axios({
        method: 'DELETE',
        url,
        headers,
        data,
      })
        .then(success => {
          return resolve(success)
        })
        .catch(error => {
          if (
            error.response &&
            error.response.status === 401 &&
            window.location.pathname != URLS.LOGIN
          ) {
            localStorage.removeItem(STORAGE)
            window.location.href = URLS.LOGIN
          }
          return reject(error.response)
        })
    })
  }
}
