/* eslint-disable @typescript-eslint/ban-types */
import { useApiCall } from '../common/appApiCallHook'
//import { BlankReq, LoginReq, LogoutReq } from '../../interfaces/ApiReqRes'
import { SAGA_ACTIONS } from '../../../_config/index'



export function useFormApi() {
  const callApi = useApiCall()

  const call = (action:any,data: any, onSuccess: Function, onError: Function) => {
    callApi(action, data, onSuccess, onError)
  }

 
  const getAllScoreQuestions = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE_QUESTION.LIST, data, onSuccess, onError)
  }
  const scoreQuestionSave = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE_QUESTION.SAVE, data, onSuccess, onError)
  }
  const scoreQuestionUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE_QUESTION.UPDATE, data, onSuccess, onError)
  } 
  const getScoreQuestions = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE_QUESTION.DETAILS, data, onSuccess, onError)
  } 
  const scoreQuestionStatusChange = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE_QUESTION.CHANGE_STATUS, data, onSuccess, onError)
  } 
  const scoreQuestionDelete = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE_QUESTION.DELETE, data, onSuccess, onError)
  }  


  return {
    call,

    getAllScoreQuestions,
    scoreQuestionSave,
    scoreQuestionUpdate,
    getScoreQuestions,
    scoreQuestionStatusChange,
    scoreQuestionDelete,





  }
}
