import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import toast from 'react-hot-toast';
import { URLS } from "../../_config";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { useUser } from "../../_common/hooks/authHook";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

import { TbLockAccess, TbLockCog, TbSettings, TbTrashXFilled } from "react-icons/tb";
import { MdModeEditOutline } from "react-icons/md";


const EmployeeList = () => {
    const userApi = useUserApi();
    const dispatch = useDispatch();
    const [employees, setEmployees] = useState<Array<any>>([]);
    const [userStatus, setUserStatus] = useState('');
    const navigate = useNavigate();
    const userInfo: any = useUser();
    console.log('userInfo', userInfo)

    const getEmployeeList = () => {
        userApi.employeeList({}, (message: any, resp: any) => {
            setEmployees(resp.data.members);
        }, (message: any, resp: any) => {
            // Error...
        })

    }

    useEffect(() => {
        getEmployeeList()

    }, []);

    const submitData = (data: any) => {
        let formParam = {
            "userID": data.userID,
            "companyID": data.companyID,
            "isActive": data.isActive
        };

        return new Promise((resolve, reject) => {
            userApi.memberActivation([formParam], (message: any, resp: any) => {
                if (resp.success === true) {

                    resolve(resp.message);
                } else {
                    reject(resp.message);
                }
            }, (message: any, resp: any) => {
                // Error...
            })
        })
    }

    const statusChange = (status: boolean, userID: number, companyID: number) => {
        let msg = status === false ? 'In-Active' : 'Active';
        let data = {
            "userID": userID,
            "companyID": companyID,
            "isActive": status
        };

        Swal.fire({
            title: 'Confirm Status',
            text: `Are you sure you want to ${msg} this user?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, ${msg} it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    submitData(data), {
                    loading: 'Loading...',
                    success: (msg: any) => { getEmployeeList(); return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                }
                );

            }
        });


    }

    const deleteData = (data: any) => {
        let formParam = {
            "userID": data.userID,
            "isDeleted": true,
            //"updatedBy": userInfo?.,
        };
        return new Promise((resolve, reject) => {
            userApi.deleteRole(formParam, (message: any, resp: any) => {
                resolve(message);
            }, (message: any, resp: any) => {
                reject(message);
            })
        })
    }

    const deleteItem = (userID: number) => {
        let data = {
            "userID": userID,
        };

        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this role?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    deleteData(data), {
                    loading: 'Loading...',
                    success: (msg: any) => { getEmployeeList(); return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                }
                );

            }
        });
    }


    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><Link to={URLS.DASHBOARD}>Dashboard</Link></li>
                                    <li>Admin Employee</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">ADMIN EMPLOYEE</h1>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">

                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h1 className="h5 mb-0 font-weight-600"></h1>
                                <Link to="/employee/create" className="btn btn-info py-2 add-more-files-btn"> Add Employee</Link>
                            </div>

                            <div className="white-box px-4 pt-3">
                                <div className="table-responsive list-table">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr className="align-middle">

                                                <th scope="col">First Name</th>
                                                <th scope="col">Last Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Role Type</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {employees && employees.length > 0 ? (
                                                employees.map((member) => (
                                                    <tr key={member.userID} className="align-middle h-60">
                                                        <td>{member.firstName}</td>
                                                        <td>{member.lastName}</td>
                                                        <td>{member.email}</td>
                                                        <td>{member.phone}</td>
                                                        <td>
                                                            <span className="badge bg-secondary">{member.roleName}</span>
                                                            {/* <span className="badge bg-info">Admin</span> */}
                                                        </td>
                                                        <td>
                                                            <div className="d-flex gap-3">
                                                                {
                                                                    (member?.roleID != 1) &&
                                                                    <>

                                                                        <Link to={URLS.EMPLOYEE_EDIT + member.userProfID} className="icon text-info"><MdModeEditOutline /></Link>
                                                                        <Link to={URLS.EMPLOYEE_ROLE_PERMISSION + member.userProfID} className="icon text-primary" ><TbLockCog /> </Link>
                                                                        {/* <Link to="#" className="icon text-danger" onClick={() => { deleteItem(member.userID) }}><TbTrashXFilled /></Link> */}

                                                                        {member.isActive === true ? (
                                                                            <Link to="#" onClick={() => { statusChange(false, member.userID, member.companyID) }}><i className="active-icon"></i></Link>
                                                                        ) : (
                                                                            <Link to="#" onClick={() => { statusChange(true, member.userID, member.companyID) }}><i className="in-active"></i></Link>
                                                                        )}

                                                                    </>
                                                                }

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr className="align-middle h-60">
                                                    <td colSpan={6}>Data Not Found!</td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </div>

                                {/* <div className="pagination-panel">
                                    <ul className="d-flex justify-content-center">
                                        <li><a href="#" className="page-nav-arrow-left"></a></li>
                                        <li className="active"><a href="#">1</a></li>
                                        <li><a href="#">2</a></li>
                                        <li><a href="#">3</a></li>
                                        <li><a href="#">4</a></li>
                                        <li><a href="#">5</a></li>
                                        <li><a href="#" className="page-nav-arrow-right"></a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>
    );
}

export default EmployeeList;