import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const HangerDecision = () =>{
    return(
        <React.Fragment>
        <Header></Header>
        <p>HangerDecision</p>
        <Footer></Footer>
        </React.Fragment>
    );
}

export default HangerDecision;