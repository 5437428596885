import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import LeftPanel from "../components/leftPanel/LeftPanel";

const ChallengeStatement = () =>{
    return(
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-2">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <ul className="csmt-breadcrumb d-flex gap-3 mb-2 p-0">
                                    <li><a href="#">Dashboard</a></li>
                                    <li>Use case librery</li>
                                </ul>
                                <h1 className="h6 text-uppercase font-weight-700 mb-0">The hanger</h1>
                               
                            </div>
                            <div className="col-sm-6">
                                <div className="d-flex justify-content-end">
                                    <img src="assets/images/time.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="two-panel-wrapper d-flex">
                                <LeftPanel></LeftPanel>
                                <div className="right-panel">
                                    <div className="progress-steps-panel d-flex mb-4">
                                        <div className="progress-steps d-flex align-items-center gap-3">
                                            <div className="progress-number">1</div>
                                            <p>Discovery</p>
                                        </div>
                                        <div className="progress-steps active d-flex align-items-center gap-3">
                                            <div className="progress-number">2</div>
                                            <p>Challenge Statement</p>
                                        </div>
                                        <div className="progress-steps d-flex align-items-center gap-3">
                                            <div className="progress-number">3</div>
                                            <p>Solutions Framing</p>
                                        </div>
                                        <div className="progress-steps  d-flex align-items-center gap-3">
                                            <div className="progress-number">4</div>
                                            <p>Scoring/Prioritization</p>
                                        </div>
                                        <div className="progress-steps  d-flex align-items-center gap-3">
                                            <div className="progress-number">5</div>
                                            <p>Decision</p>
                                        </div>
                                    </div>
                                    <div className="white-box">
                                        <div className="form-area">
                                            <h2 className="h5">Context</h2>
                                            <hr />
                                            <label>What is the current state and why is it important to solve this challenge?</label>
                                            <textarea className="form-control mb-4" rows={4} cols={5} placeholder="Insert context to challenge statement here"></textarea>
                                            <label>Does my challenge statement have enough context for all stakeholders to understand?</label>
                                            <textarea className="form-control mb-4" rows={4} cols={5} placeholder="Insert context to challenge statement here"></textarea>
                                            <label>Do I understand what may have already been done to solve this problem? If so, is this use case building upon those learnings? How?</label>
                                            <textarea className="form-control mb-4" rows={4} cols={5} placeholder="Insert context to challenge statement here"></textarea>
                                            <h2 className="h5">Challenge Statement</h2>
                                            <hr />
                                            <label>Is my statement focused on identifying the problem, not the solution?</label>
                                            <textarea className="form-control mb-4" rows={4} cols={5} placeholder="Insert challenge statement here"></textarea>
                                            <label>Have you tried re-framing and simplifying your problem statement?</label>
                                            <textarea className="form-control mb-4" rows={4} cols={5} placeholder="Insert challenge statement here"></textarea>
                                            <div className="mb-4">
                                                <label>Complete this sentence</label>
                                                <div className="d-flex gap-3 align-items-center flex-col-mob">
                                                    <input type="text" placeholder="" className="form-control w-f-200" />
                                                    <label className="mb-0">needs</label>
                                                    <input type="text" placeholder="" className="form-control w-f-125" />
                                                    <label className="mb-0">in order to</label>
                                                    <input type="text" placeholder="" className="form-control w-f-125" />
                                                </div>
                                            </div>
                                            <h2 className="h5">Convincing Argument</h2>
                                            <hr />
                                            <h3 className="h6 text-purple font-weight-600 mb-4">Solution Scope</h3>
                                            <div className="row mb-4">
                                                <div className="col-sm-6">
                                                    <label>Do I have a clear solution scope?</label>
                                                    <input type="text" className="form-control" placeholder="Elaborate on your ‘convincing argument’ here" />
                                                </div>
                                                <div className="col-sm-6">
                                                    <label>Have I clearly defined the use case?</label>
                                                    <input type="text" className="form-control" placeholder="Elaborate on your ‘convincing argument’ here" />
                                                </div>
                                            </div>
                                            <label>Have you tried re-framing and simplifying your problem statement?</label>
                                            <textarea className="form-control mb-4" rows={4} cols={5} placeholder="Insert challenge statement here"></textarea>
                                            <label>Do I understand the high-level costs & resource requirements to deliver this?</label>
                                            <textarea className="form-control mb-4" rows={4} cols={5} placeholder="Elaborate on your ‘convincing argument’ here"></textarea>
                                            <h3 className="h6 text-purple font-weight-600 mb-4">Convincing Argument</h3>
                                            <div className="row mb-4">
                                                <div className="col-sm-6 mb-4">
                                                    <label>Is my use case and solution convincing?</label>
                                                    <input type="text" className="form-control" placeholder="Elaborate on your ‘convincing argument’ here" />
                                                </div>
                                                <div className="col-sm-6 mb-4">
                                                    <label>Do I know what the value proposition is?</label>
                                                    <input type="text" className="form-control" placeholder="Elaborate on your ‘convincing argument’ here" />
                                                </div>
                                                <div className="col-sm-6 mb-4">
                                                    <label>Do we have budget already allocated to spend on this work?</label>
                                                    <input type="text" className="form-control" placeholder="Elaborate on your ‘convincing argument’ here" />
                                                </div>
                                                <div className="col-sm-6 mb-4">
                                                    <label>As the Captain, am I ready to invest my time and energy into this work?</label>
                                                    <input type="text" className="form-control" placeholder="Elaborate on your ‘convincing argument’ here" />
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end gap-3">
                                                <button type="submit" className="btn btn-primary d-flex align-items-center gap-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Save as Draft <i className="arrow-right"></i></button>
                                            <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Submit <i className="arrow-right"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    );
}

export default ChallengeStatement;