import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import LeftPanel from "../components/leftPanel/LeftPanel";

const SolutionFraming = () =>{
    return(
        <React.Fragment>
        <Header></Header>
        
        <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-2">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <ul className="csmt-breadcrumb d-flex gap-3 mb-2 p-0">
                                    <li><a href="#">Dashboard</a></li>
                                    <li>Use case librery</li>
                                </ul>
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">The hanger</h1>
                                
                            </div>
                            <div className="col-sm-6">
                                <div className="d-flex justify-content-end">
                                    <img src="assets/images/time.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="two-panel-wrapper d-flex">
                                <LeftPanel></LeftPanel>
                                <div className="right-panel">
                                    <div className="progress-steps-panel d-flex mb-4">
                                        <div className="progress-steps d-flex align-items-center gap-3">
                                            <div className="progress-number">1</div>
                                            <p>Discovery</p>
                                        </div>
                                        <div className="progress-steps d-flex align-items-center gap-3">
                                            <div className="progress-number">2</div>
                                            <p>Challenge Statement</p>
                                        </div>
                                        <div className="progress-steps active d-flex align-items-center gap-3">
                                            <div className="progress-number">3</div>
                                            <p>Solutions Framing</p>
                                        </div>
                                        <div className="progress-steps  d-flex align-items-center gap-3">
                                            <div className="progress-number">4</div>
                                            <p>Scoring/Prioritization</p>
                                        </div>
                                        <div className="progress-steps  d-flex align-items-center gap-3">
                                            <div className="progress-number">5</div>
                                            <p>Decision</p>
                                        </div>
                                    </div>
                                    <div className="white-box">
                                        <div className="form-area">
                                            <h2 className="h5">Solution Overview</h2>
                                            <hr />
                                            <label>Describe Solution Overview</label>
                                            <textarea className="form-control mb-4" rows={4} cols={5} placeholder="Insert your solution solves the challenge statement here"></textarea>
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <h2 className="h5">Product Details</h2>
                                                <a href="#" className="btn btn-info py-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Add Product</a>
                                            </div>

                                            <div className="table-responsive list-table">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Product Name</th>
                                                            <th scope="col">Description</th>
                                                            <th scope="col">Product Type</th>
                                                            <th scope="col">Unit Cost</th>
                                                            <th scope="col">Unit Definition</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>SVP, Energy Delivery IOU</td>
                                                            <td>Energy Efficiency & Demand Response...</td>
                                                            <td>Hardware</td>
                                                            <td>$5.83</td>
                                                            <td>Flat fee</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SVP, Energy Delivery IOU</td>
                                                            <td>Energy Efficiency & Demand Response...</td>
                                                            <td>Hardware</td>
                                                            <td>$5.83</td>
                                                            <td>Flat fee</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SVP, Energy Delivery IOU</td>
                                                            <td>Energy Efficiency & Demand Response...</td>
                                                            <td>Hardware</td>
                                                            <td>$5.83</td>
                                                            <td>Flat fee</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SVP, Energy Delivery IOU</td>
                                                            <td>Energy Efficiency & Demand Response...</td>
                                                            <td>Hardware</td>
                                                            <td>$5.83</td>
                                                            <td>Flat fee</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="d-flex justify-content-end gap-3">
                                                <button type="submit" className="btn btn-primary d-flex align-items-center gap-2">Save as Draft <i className="arrow-right"></i></button>
                                            </div>
                                            <h2 className="h5">Specifications</h2>
                                            <hr />
                                            <div className="row mb-3">
                                                <div className="col-sm-6">
                                                    <label>Upload Solution files</label>
                                                    <div className="custom-file-uploader mb-2">
                                                        
                                                        <input type="file" />
                                                        <i className="file-upload-icon"></i>
                                                        <p className="mb-0">Drop files here or click to upload.</p>
                                                    </div>
                                                    <i className="text-purple text-xs d-block">Upload any applicable data sheets, product specifications, etc for your solution</i>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="uploaded-files d-flex w-100 gap-3 border border-2 border-light mt-4">
                                                        <span className="uploaded-file-icon"><img src="assets/images/pdf.svg" alt="" /></span>
                                                        <div className="file-upload-progress flex-grow-1">
                                                            <div className="d-flex justify-content-between">
                                                                <p>Energy Efficiency &amp; Demand Response (100%)</p>
                                                                <p className="green-text">Completed</p>
                                                            </div>
                                                            <div className="file-upload-progress-bar">
                                                                <div className="file-upload-progress-percent w-100"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <label className="mt-3">IT System requirements </label>
                                            <textarea className="form-control mb-2" rows={4} cols={5} placeholder="Insert Description here"></textarea>
                                            <i className="text-purple text-xs">IT System Requirements - What is your proposed IT system architecture for the project (if required)? Include any sample or reference diagrams. This could include network architecture, designs and diagrams. Note any hardware components or IT systems necessary to support the implementation of the Use Case.</i>
                                            
                                            <div className="row mb-3 mt-4">
                                                <div className="col-sm-6">
                                                    <label>Upload supporting documents</label>
                                                    <div className="custom-file-uploader mb-2">
                                                        <input type="file" />
                                                        <i className="file-upload-icon"></i>
                                                        <p className="mb-0">Drop files here or click to upload.</p>
                                                    </div>
                                                    

                                                    <div className="row my-3 align-items-center">
                                                        <div className="col-sm-4 col-12">
                                                            <label className="form-label">Add installation Time</label>
                                                            <input type="text" className="form-control" placeholder="Input Time" id="time" />
                                                        </div>
                                                        <div className="col-sm-8 col-12">
                                                            <div className="d-flex gap-3 mt-4">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                                                    <label className="form-check-label">
                                                                        Attach installation Guide File
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                                                    <label className="form-check-label">
                                                                        Add Installation Details
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <label>Attach installation Guide </label>
                                                    <div className="custom-file-uploader mb-2">
                                                        <input type="file" />
                                                        <i className="file-upload-icon"></i>
                                                        <p className="mb-0">Drop files here or click to upload.</p>
                                                    </div>
                                                    <label>Add Crew Member</label>
                                                    <div className="member-form">
                                                        <input type="text" className="form-control" placeholder="Add Crew member" />
                                                        <span className="tags tags-under-review">Member1</span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="uploaded-files d-flex w-100 gap-3 border border-2 border-light mt-4">
                                                        <span className="uploaded-file-icon"><img src="assets/images/pdf.svg" alt="" /></span>
                                                        <div className="file-upload-progress flex-grow-1">
                                                            <div className="d-flex justify-content-between">
                                                                <p>Energy Efficiency &amp; Demand Response (100%)</p>
                                                                <p className="green-text">Completed</p>
                                                            </div>
                                                            <div className="file-upload-progress-bar">
                                                                <div className="file-upload-progress-percent w-100"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            

                                            <div className="table-responsive list-table mt-4">
                                                <div className="d-flex align-items-center justify-content-between">
                                                   <label>Describe Maintenance Task</label> 
                                                   <div className=" mb-3 mt-2">
                                                    <button type="submit" className="btn btn-primary d-flex align-items-center gap-2">Add</button>
                                                </div>
                                                </div>
                                                
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Task Id</th>
                                                            <th scope="col">Task name</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>#123456</td>
                                                            <td>Energy Efficiency & Demand Response...</td>
                                                            <td><a href="#"><i className="delete-icon"></i></a></td>
                                                        </tr>
                                                        <tr>
                                                            <td>#124588</td>
                                                            <td>Energy Efficiency & Demand Response...</td>
                                                            <td><a href="#"><i className="delete-icon"></i></a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-12">
                                                    <div className="d-flex align-items-center gap-3 mb-4">
                                                        <label>Repeat</label>
                                                        <select className="form-control">
                                                            <option>All</option>
                                                        </select>
                                                        <div className="dropdown">
                                                            <button className="btn btn-primary-blue dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                Primary
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                              <li><a className="dropdown-item" href="#">Action</a></li>
                                                              <li><a className="dropdown-item" href="#">Another action</a></li>
                                                              <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                            </ul>
                                                          </div>
                                                    </div>
                                                    <label>End</label>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
                                                        <label className="form-check-label">
                                                            Never
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" />
                                                        <label className="form-check-label">
                                                            On
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault7" />
                                                        <label className="form-check-label">
                                                            After
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <h2 className="h5 mt-5">Test Flight Access</h2>
                                            <hr />
                                            <label>turn on follow button if individuals from outside the host organization will require access to the Test Flight data</label>
                                            <div className="mb-4">
                                                <div className="can-toggle">
                                                    <input id={"a"} type="checkbox" checked />
                                                    <label>
                                                      <div className="can-toggle__switch" data-checked="Yes" data-unchecked="No"></div>
                                                    </label>
                                                  </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <label>Input Number</label>
                                                    <input type="text" className="form-control" placeholder="Insert total number" />
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end gap-3 mb-3 mt-2">
                                                <button type="submit" className="btn btn-primary d-flex align-items-center gap-2">Save as Draft <i className="arrow-right"></i></button>
                                                <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Save <i className="arrow-right"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <Footer></Footer>
        </React.Fragment>
    );
}

export default SolutionFraming;