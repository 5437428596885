
import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import toast from 'react-hot-toast';
import { useUser } from "../../_common/hooks/authHook";
import { URLS } from "../../_config";

import { MdOutlineModeEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { replaceUrlParams } from "../../_config/helpers";


export const RunwayList = () => {
    const [dataList, setDataList] = useState<Array<any>>([]);
    const userApi = useUserApi();
    const userInfo: any = useUser();

    const getRunwayList = () => {
        userApi.runwayList({ urlParams: { PageIndex: 1, PageSize: 10 } }, (message: any, resp: any) => {
            setDataList(resp.data.runway);
        }, (message: any, resp: any) => {
            // Error...
        })
    }

    useEffect(() => {
        getRunwayList()
    }, []);

    const submitData = (data: any) => {
        return new Promise((resolve, reject) => {
            userApi.runwayDelete(data, (message: any, resp: any) => {
                if (resp.success === true) {
                    resolve(resp.message);
                } else {
                    reject(resp.message);
                }
            }, (message: any, resp: any) => {
                // Error...
            })
        })
    }

    const deleteItem = (itemID: number) => {
        let data = {
            "runwayID": itemID,
            updatedBy: userInfo?.userID
        };

        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    submitData(data), {
                    loading: 'Loading...',
                    success: (msg: any) => { getRunwayList(); return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                }
                );

            }
        });
    }

    const submitDataStatusChange = (data: any) => { 
        return new Promise((resolve, reject) => {
            userApi.runwayChangeStatus(data, (message: any, resp: any) => {
                resolve(message);
            }, (message: any, resp: any) => {
                reject(message)
            })
        })
    }

    const statusChange = (status: boolean, itemID: number) => {
        let msg = status === false ? 'In-Active' : 'Active';
        let data = {
            "runwayID": itemID,
            "isActive": status,
            "updatedBy": userInfo?.userID,
            'isDeleted': false
        };

        Swal.fire({
            title: 'Confirm Status',
            text: `Are you sure you want to ${msg} this runway?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, ${msg} it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    submitDataStatusChange(data), {
                    loading: 'Updating...',
                    success: (msg: any) => { getRunwayList(); return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                });
            }
        });
    }

    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><Link to={URLS.DASHBOARD}>Dashboard</Link></li>
                                    <li>Runway</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">Runway</h1>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">


                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h1 className="h5 mb-0 font-weight-600"></h1>

                                <Link to={URLS.RUNWAY.CREATE} className="btn btn-info py-2 add-more-files-btn"> Add Runway</Link>
                            </div>

                            <div className="white-box px-4 pt-3">
                                <div className="table-responsive list-table full-width">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr className="align-middle">
                                                <th scope="col">#</th>
                                                <th scope="col">Company</th>
                                                <th scope="col">Runway</th>
                                                <th scope="col">Details  </th>
                                                <th scope="col" className="w-150px">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {dataList && dataList.length > 0 ? (
                                                dataList.map((item, index) => (
                                                    <tr key={Math.random()} className="align-middle h-60">
                                                        <td >{index + 1}</td>
                                                        <td >{item.fkCompanyID}</td>
                                                        <td >{item.title}</td> 
                                                        <td >{item.description}</td> 
                                                        <td>
                                                            <div className="d-flex align-items-center gap-3">

                                                                <Link to={replaceUrlParams(URLS.RUNWAY.EDIT, {'id': item.runwayID})} className="react-icon-grey">
                                                                    <MdOutlineModeEdit />
                                                                </Link>

                                                                <Link to="#" onClick={() => { deleteItem(item.runwayID) }}  className="react-icon-grey"><MdDeleteOutline /></Link>
                                                                {item.isActive === true ? (
                                                                    <Link to="#" onClick={() => { statusChange(false, item.runwayID) }}><i className="active-icon"></i></Link>
                                                                ) : (
                                                                    <Link to="#" onClick={() => { statusChange(true, item.runwayID) }}><i className="in-active"></i></Link>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr className="align-middle h-60">
                                                    <td colSpan={6}>Data Not Found!</td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </div>

                                {/* <div className="pagination-panel">
                                    <ul className="d-flex justify-content-center">
                                        <li><a href="#" className="page-nav-arrow-left"></a></li>
                                        <li className="active"><a href="#">1</a></li>
                                        <li><a href="#">2</a></li>
                                        <li><a href="#">3</a></li>
                                        <li><a href="#">4</a></li>
                                        <li><a href="#">5</a></li>
                                        <li><a href="#" className="page-nav-arrow-right"></a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>
    )
}
