import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Link, useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { useForm, SubmitHandler, useFieldArray, Controller } from "react-hook-form"
import Error from "../../components/elements/formFieldError";
import { Button } from "react-bootstrap";
import { useFormApi } from "../../_common/hooks/api/FormApiHook";
import { useUser } from "../../_common/hooks/authHook";
import { SAGA_ACTIONS } from '../../_config/index'
import Select from 'react-select'
import CreateControl from "../../components/form/createControl";

export const CreateForm = () => {

    const user:any = useUser();
    const navigate = useNavigate();
    const formApi = useFormApi();

    const [controlTypes,setControlTypes] = useState<any[]>([])

    const [workflows,setWorkflows] = useState<any[]>([])
    const [modullies,setModullies] = useState<any[]>([])
    const [forms,setForms] = useState<any[]>([])
    
    const [controlProps,setControlProps] = useState<any[] | null>(null);
    const [isEdit,setIsEdit] = useState<boolean>(false);

   

    const {
        control,
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        formState: { errors },
    } = useForm<any>()

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: "field", 
    });
    const workflowWatcher = watch('workflow')
    const moduleWatcher = watch('module')
    const formWatcher = watch('form')
    const layoutWatcher = watch('layout')

    const controlPropMap:any = {
        TextBox:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'Multiple',
            "colSpan": 'ColSpan'
        },
        TextArea:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'Multiple',
            "colSpan": 'ColSpan',
            "row": 'Row',
            "column": 'Column',
            "maxLength": 'MaxLength',
        },
        Dropdown:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'MultiSelect',
            "colSpan": 'ColSpan'
        },
        CheckBox:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'MultiSelect',
            "colSpan": 'ColSpan'
        },
        Radio:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'MultiSelect',
            "colSpan": 'ColSpan'
        },
        FileField:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'MultiSelect',
            "colSpan": 'ColSpan'
        },
        Label:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'Multiple',
            "colSpan": 'ColSpan'
        },
        HeadLine:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'Multiple',
            "colSpan": 'ColSpan',
            "action":'Action'
        },
        CustomControl:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'Multiple',
            "colSpan": 'ColSpan',
            "action":'Action'
        },
        Button:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'Multiple',
            "colSpan": 'ColSpan',
            "action":'Action'
        },
        Submit:{
            "caption": 'Caption',
            "placeHolder": 'PlaceHolder',
            "isRequired": 'Required',
            "multiSelect": 'Multiple',
            "colSpan": 'ColSpan',
            "action":'Action'
        }
    }

    const getControlTypes = () => {
        formApi.call(SAGA_ACTIONS.FORM.GET_CONTROL_TYPES, {urlParams:{PageIndex:0,PageSize:999}}, (message: any, resp: any) => {
            console.log(resp.data.workFlows);
            setControlTypes(resp.data.controlTypes.map((control:any)=>{
                control.value = control.controlTypeID
                control.label = control.controlName
                return control
            }))
        }, (message: any, resp: any) => {

        })
    }
    const getWorkFlows = () => {
        formApi.call(SAGA_ACTIONS.FORM.GET_WORKFLOWS, {urlParams:{companyID:user.companyID}}, (message: any, resp: any) => {
            //console.log(resp.data.workFlows);
            setWorkflows(resp.data.workFlows.map((flow:any)=>{
                flow.value = flow.workFlowID
                flow.label = flow.title
                return flow
            }))
        }, (message: any, resp: any) => {

        })
    }
    const getModules = (workFlowID:any) => {
        formApi.call(SAGA_ACTIONS.FORM.GET_MODULES, {urlParams:{WorkFowID:workFlowID,companyID:user.companyID}}, (message: any, resp: any) => {
            console.log(resp.data);
            setModullies(resp.data.moduleIs.map((mod:any)=>{
                mod.value = mod.moduleID;
                mod.label = mod.pageTitle;
                return mod
            }))
        }, (message: any, resp: any) => {

        })
    }
    const getForms = (moduleID:any) => {
        formApi.call(SAGA_ACTIONS.FORM.GET_FORMS, {urlParams:{ModuleID:moduleID,companyID:user.companyID}}, (message: any, resp: any) => {
            console.log(resp.data);
            /* setForms(resp.data.forms.map((form:any)=>{
                form.value = form.formID;
                form.label = form.title;
                return form
            })) */
            setForms([{...resp.data,value:resp.data.fromID,label:resp.data.title}])
        }, (message: any, resp: any) => {

        })
    }

    const addNewField = ()=>{
        append({})
    }

    const submitFormConfigData = (data: any) => {
        return new Promise((resolve, reject) => {
            formApi.call(SAGA_ACTIONS.FORM.SAVE, data, (message: any, resp: any) => {
                console.log(resp.data);
                resolve(message)
            }, (message: any, resp: any) => {
                reject(message)
            })
        })
    }

    const getSelected = (value:any,node:any,options:any)=>{
        let res = null;
        options.map((option:any)=>{
            if(value == option[node]){
                res = option
            }
        });

        return res;
    }

    useEffect(()=>{
        console.log('formWatcher',formWatcher)
        if(formWatcher){
            //console.log('formWatcher', formWatcher.layout.replace('Col:',''))
            let opt = {value:1, col:12,label:'1 column'}
            if(formWatcher.layout.replace('Col:','') == 2) {
                opt = {value:2, col:6,label:'2 column'}
            }
            if(formWatcher.layout.replace('Col:','') == 3) {
                opt = {value:3, col:4,label:'3 column'}
            }
            setValue('layout',opt);


            console.log('.....................')
            console.log('.....................')
            console.log('')
            console.log('')
            
            if(formWatcher.controls.length){
                setIsEdit(true);// this form is in edit mode
                formWatcher.controls.map((control:any)=>{
                    console.log('control =>',control)
                    console.log('')
                    console.log('') 

                    let _data:any = {
                        controlType:getSelected(control.fkControlTypeID,'value',controlTypes),
                        prop:{
                            'Caption':control.caption,
                            'PlaceHolder':control.placeHolder,
                            'ColSpan':{value:control.colSpan,label:control.colSpan},
                            'Required':control.required
                        }
                    }

       

                    if(control.options){
                        // add options...
                    }
                    append(_data)
                }) 
            } else {
                setIsEdit(false);
            }
        } else {
            remove()
        }
    },[formWatcher])

    useEffect(()=>{
        getControlTypes()
        getWorkFlows()
        console.log(user)
    }, [])

    const onSubmit: SubmitHandler<any> = (data) => {
        console.log('data =>', data)

        let params = {
            "fkCompanyID": user.companyID,
            "fkModuleID": data.module.moduleID,
            "fkWorkFlowID": data.workflow.workFlowID,
            "fkTenantID": user.tenantID,
            "userID": user.userID,
            "forms": [{
                "formsID": data.form.fromID,
                "formcontrolDetails": data.field.map((_field:any,i:any)=>{
                    let data:any =  {
                        "controls": {
                            "fkControlTypeID": _field.controlType.controlTypeID,
                            "caption": _field.prop[controlPropMap[`${_field.controlType.controlName}`]['caption']],
                            "placeHolder":  _field.prop[controlPropMap[`${_field.controlType.controlName}`]['placeHolder']],
                            "displayOrder": i,
                            "isRequired":  _field.prop[controlPropMap[`${_field.controlType.controlName}`]['isRequired']],
                            "multiSelect":  _field.prop[controlPropMap[`${_field.controlType.controlName}`]['multiSelect']],
                            "text": _field.prop['Text']? ((typeof _field.prop['Text']) == 'object'?_field.prop['Text'].value:_field.prop['Text'])    :'',
                            "colSpan":  _field.prop[controlPropMap[`${_field.controlType.controlName}`]['colSpan']]?.value
                        }
                    }

                    switch(_field.controlType.controlName){
                        case 'CheckBox' :
                        case 'Radio' :
                        case 'Dropdown' :
                            data.options =  _field.prop['Values'].options.map((opt:any)=>{
                                return {
                                    "fkControlTypeID": _field.controlType.controlTypeID,
                                      "optionKey": opt.value,
                                      "optionValue": opt.label
                                    }
                            })
                        break;
                        case 'Submit' :
                            data.controls.actionUrl =  _field.prop['ActionUrl'].value
                        break;
                        default :
                    }

                    return data
                })
            }]
        }
        console.log('params=>', params);

        //return;

        toast.promise(
            submitFormConfigData(params), {
            loading: 'Saving...',
            success: (msg: any) => { navigate('/role/list'); return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }


    return (
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><a href="#">Dashboard</a></li>
                                    <li>Form</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">Add Form</h1>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box h-100">
                                <div className="form-area">
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <label htmlFor="tenant" className="form-label">Workflow</label>
                                                    <Controller
                                                        defaultValue=""
                                                        control={control}
                                                        name="workflow"
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        render={({ field: { onChange, value, ref, name } }) => (
                                                            <div>
                                                                <Select
                                                                    name={name}
                                                                    onChange={(data)=>{
                                                                        onChange(data);
                                                                        setModullies([])
                                                                        setForms([])
                                                                        setValue('module',null);
                                                                        setValue('form',null);
                                                                        getModules(data.value);
                                                                    }}
                                                                    value={value}
                                                                    options={workflows}
                                                                    isMulti={false}
                                                                />
                                                                <Error error={errors.workflow} />
                                                            </div>
                                                        )}
                                                    />
                                                </div>

                                                <div className="col-md-3">
                                                    <label htmlFor="tenant" className="form-label">Module</label>
                                                    <Controller
                                                        defaultValue=""
                                                        control={control}
                                                        name="module"
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        render={({ field: { onChange, value, ref, name } }) => (
                                                            <div>
                                                                <Select
                                                                    name={name}
                                                                    onChange={(data)=>{
                                                                        onChange(data);
                                                                        setForms([])
                                                                        setValue('form',null);

                                                                        getForms(data.value);
                                                                    }}
                                                                    value={value}
                                                                    options={modullies}
                                                                    isMulti={false}
                                                                />
                                                                <Error error={errors.module} />
                                                            </div>
                                                        )}
                                                    />
                                                    
                                                

                                                </div>

                                                <div className="col-md-3">
                                                    <label htmlFor="tenant" className="form-label">Form</label>
                                                    <Controller
                                                        defaultValue=""
                                                        control={control}
                                                        name="form"
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        render={({ field: { onChange, value, ref, name } }) => (
                                                            <div>
                                                                <Select
                                                                    name={name}
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    options={forms}
                                                                    isMulti={false}
                                                                />
                                                                <Error error={errors.form} />
                                                            </div>
                                                        )}
                                                    />
                                                    
                                                

                                                </div>

                                                <div className="col-md-3">
                                                    <label htmlFor="company" className="form-label">Layout</label>
                                                    <Controller
                                                        defaultValue=""
                                                        control={control}
                                                        name="layout"
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        render={({ field: { onChange, value, ref, name } }) => (
                                                            <div>
                                                                <Select
                                                                    name={name}
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    options={[{value:1, col:12,label:'1 column'},{value:2, col:6,label:'2 column'},{value:3, col:4,label:'3 column'}]}
                                                                    isMulti={false}
                                                                />
                                                                <Error error={errors.form} />
                                                            </div>
                                                        )}
                                                    />
                                                    {/* <Error error={errors?.fkCompanyID?.message} /> */}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                {workflowWatcher && moduleWatcher && formWatcher && layoutWatcher ?
                                                    <>
                                                        {fields.map((field, index) => (
                                                            <CreateControl
                                                                key={field.id}
                                                                index={index}
                                                                control={control}
                                                                register={register}
                                                                getValues={getValues}
                                                                setValue={setValue}
                                                                errors={errors}
                                                                controlTypes={controlTypes}
                                                                layout={layoutWatcher}
                                                                watch={watch}
                                                            />
                                                        ))}
                                                    </>
                                                    : null}
                                            </div>
                                            {  workflowWatcher && moduleWatcher && formWatcher && layoutWatcher

                                                    ?<Button className="mt-3" onClick={addNewField}>Add New Field</Button> :null
                                            }
                                            
                                        </div>


                                        {!isEdit ?
                                            <div className="col-12 d-flex justify-content-end gap-3">
                                                <button type="submit" className="btn btn-primary d-flex align-items-center gap-2">Submit <i className="arrow-right"></i></button>
                                            </div>
                                            : null}
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-sm-6">
                            <div className="pt-5">
                                <img src="../images/idea-pic.png" alt="" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}
