import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const RankingPrioritization = () =>{
    return(
        <React.Fragment>
        <Header></Header>

        <div className="main-container flex-grow-1">
        <div className="container">
            <div className="page-title py-2">
                <div className="row align-items-center">
                    <div className="col-sm-6">
                        <ul className="csmt-breadcrumb d-flex gap-3 mb-2 p-0">
                            <li><a href="#">Dashboard</a></li>
                            <li>Use case librery</li>
                        </ul>
                        <h1 className="h6 text-uppercase font-weight-700  mb-2">The hanger</h1>
                    </div>
                    <div className="col-sm-6">
                        <div className="d-flex justify-content-end">
                            <img src="assets/images/time.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="two-panel-wrapper d-flex">
                        
                        <div className="right-panel">
                            <div className="progress-steps-panel d-flex mb-4">
                                <div className="progress-steps d-flex align-items-center gap-3">
                                    <div className="progress-number">1</div>
                                    <p>Discovery</p>
                                </div>
                                <div className="progress-steps d-flex align-items-center gap-3">
                                    <div className="progress-number">2</div>
                                    <p>Challenge Statement</p>
                                </div>
                                <div className="progress-steps d-flex align-items-center gap-3">
                                    <div className="progress-number">3</div>
                                    <p>Solutions Framing</p>
                                </div>
                                <div className="progress-steps active  d-flex align-items-center gap-3">
                                    <div className="progress-number">4</div>
                                    <p>Scoring/Prioritization</p>
                                </div>
                                <div className="progress-steps  d-flex align-items-center gap-3">
                                    <div className="progress-number">5</div>
                                    <p>Decision</p>
                                </div>
                            </div>
                            <div className="white-box">
                                <div className="form-area">
                                    <h2 className="h5">Stakeholders</h2>
                                    <hr />

                                    <div className="table-responsive list-table">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="w-10 text-center">#</th>
                                                    <th scope="col" className="w-50">Name</th>
                                                    <th scope="col">Role</th>
                                                    <th scope="col" className="w-10">Operations</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-center"><div className="number-avatar">D</div></td>
                                                    <td>John Smith</td>
                                                    <td>SME</td>
                                                    <td><a href="#"><i className="delete-icon"></i></a></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center"><div className="number-avatar">D</div></td>
                                                    <td>John Smith</td>
                                                    <td>SME</td>
                                                    <td className="w-10"><a href="#"><i className="delete-icon"></i></a></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center"><div className="number-avatar">D</div></td>
                                                    <td>John Smith</td>
                                                    <td>SME</td>
                                                    <td className="w-10"><a href="#"><i className="delete-icon"></i></a></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center"><div className="number-avatar">D</div></td>
                                                    <td>John Smith</td>
                                                    <td>SME</td>
                                                    <td className="w-10"><a href="#"><i className="delete-icon"></i></a></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center"><div className="number-avatar">D</div></td>
                                                    <td>John Smith</td>
                                                    <td>SME</td>
                                                    <td className="w-10"><a href="#"><i className="delete-icon"></i></a></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center"><div className="number-avatar">D</div></td>
                                                    <td>
                                                        <select className="form-control w-50">
                                                            <option>Type name to search for stakeholder</option>
                                                            <option>David Miller</option>
                                                        </select>
                                                    </td>
                                                    <td></td>
                                                    <td className="w-10"><a href="#"><i className="delete-icon"></i></a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-end gap-3">
                                        <button type="submit" className="btn btn-primary d-flex align-items-center gap-2">Add More Stakeholders</button>
                                        <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Save & Notify</button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

        <Footer></Footer>
        </React.Fragment>
    );
}

export default RankingPrioritization;