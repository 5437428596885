import React from "react";

const PageTitle = () => {
    return (
        <div className="page-title py-4">
            <div className="row">
                <div className="col-sm-12">
                    <ul className="csmt-breadcrumb d-flex gap-3 mb-2 p-0">
                        <li><a href="#">Dashboard</a></li>
                        <li>Use case library </li>
                    </ul>
                </div>
                <div className="col-sm-12">
                    <h1 className="h6 text-uppercase font-weight-700  mb-0">Use case library</h1>
                </div>

            </div>
        </div>
    );
}

export default PageTitle;