import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { Link } from 'react-router-dom'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import Swal from 'sweetalert2'
import { toast } from 'react-hot-toast'
import { URLS } from '../../_config'

export const AirportList = () => {

    const [allAirportList, setAllAirportList] = useState<Array<any>>([]);
    const userApi = useUserApi();

    const getAirportList = () => {
        userApi.airportList({}, (message: any, resp: any) => {            
            setAllAirportList(resp.data.roles);
        }, (message: any, resp: any) => {
            // Error...
        })
    }

    useEffect(() => {
        getAirportList()

    }, []);

    const submitData = (data: any) => {
        let formParam = {
            "airportID": data.airportID,
            "fkCompanyID": data.fkCompanyID,

        };
        return new Promise((resolve, reject) => {
            userApi.airportDelete(formParam, (message: any, resp: any) => {
                if (resp.success === true) {

                    resolve(resp.message);
                } else {
                    reject(resp.message);
                }
            }, (message: any, resp: any) => {
                // Error...
            })
        })
    }

    const deleteItem = (airportID: number, companyID: number) => {
        let data = {
            "airportID": airportID,
            "fkCompanyID": companyID
        };

        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this airport?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    submitData(data), {
                    loading: 'Loading...',
                    success: (msg: any) => { getAirportList(); return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                }
                );

            }
        });
    }

    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-2">
                        <div className="row align-items-center">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex gap-3 mb-2 p-0">
                                <li><Link to={URLS.DASHBOARD}>Dashboard</Link></li>
                                    <li>Airport</li>
                                </ul>
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">Airports</h1>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="two-panel-wrapper ">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <h1 className="h5 mb-0 font-weight-600"></h1>
                                                <Link to="/airport/create" className="btn btn-info py-2 add-more-files-btn">Create Airport</Link>
                                            </div>
                                            <div className="right-panel">
                                                <div className="white-box">
                                                    <div className="table-responsive list-table full-width">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr className="align-middle">
                                                                    <th></th>
                                                                    <th scope="col">Airport Name</th>
                                                                    <th scope="col">Owner's Name</th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Array.isArray(allAirportList) && allAirportList.length > 0 ? (
                                                                    allAirportList.map((airport) => (
                                                                        <tr className="align-middle h-60" key={airport.airportID}>
                                                                            <td></td>
                                                                            <td>{airport.name}</td>
                                                                            <td>{airport.firstName} {airport.lastName}</td>
                                                                            <td>
                                                                                <div className="d-flex gap-3">
                                                                                    <Link to={`/airport/${airport.airportID}`}><i className="edit-icon"></i></Link>
                                                                                    <Link to="#" onClick={() => { deleteItem(airport.airportID, airport.fkCompanyID) }}><i className="delete-icon"></i></Link>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr className="align-middle h-60">
                                                                        <td colSpan={4} className='text-center'>Data Not Found!</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}
