import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const ProfileCreate = () =>{
    return(
        <React.Fragment>
            <Header></Header>
                <div className="main-container flex-grow-1">
                        <div className="container">
                            <div className="page-title py-2">
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <ul className="csmt-breadcrumb d-flex gap-3 mb-2 p-0">
                                            <li><a href="#">Dashboard</a></li>
                                            <li>The Hanger</li>
                                        </ul>
                                        <h1 className="h6 text-uppercase font-weight-700  mb-0">The hanger</h1>
                                        
                                    </div>
                                    <div className="col-6">
                                        <div className="d-flex justify-content-end">
                                            <img src="assets/images/time.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="white-box p-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h1 className="h5 mb-0 font-weight-600">Profile Creation</h1>
                                        </div>
                                    </div>
                                    <div className="light-blue-bg">
                                        <div className="row form-area">
                                            <div className="col-sm-12">
                                                <h2 className="h6 text-purple font-weight-600">Personal information</h2>
                                                <hr />
                                            </div>
                                            <div className="col-sm-6">
                                                <label>Name</label>
                                                <input type="text" className="form-control mb-3" placeholder="Enter name" />
                                            </div>
                                            <div className="col-sm-6">
                                                <label>Email</label>
                                                <input type="text" className="form-control mb-3" placeholder="Enter username" />
                                            </div>
                                            <div className="col-sm-4">
                                                <label>Phone Number</label>
                                                <input type="text" className="form-control mb-3" placeholder="Enter Phone Number" />
                                            </div>
                                            <div className="col-sm-4">
                                                <label>User name</label>
                                                <input type="text" className="form-control mb-3" placeholder="Enter User name" />
                                            </div>
                                            <div className="col-sm-4">
                                                <label>Password</label>
                                                <input type="text" className="form-control mb-3" placeholder="Enter Password" />
                                            </div>
                                            <div className="col-12 d-flex justify-content-end gap-3 mt-2">
                                                <button type="submit" className="btn btn-primary d-flex align-items-center gap-2">Save as Draft <i className="arrow-right"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white-box border-radius-0">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h2 className="h6 text-purple font-weight-600">Company information</h2>
                                                <hr />
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="row form-area">
                                                    <div className="col-sm-3">
                                                        <label>Upload Picture</label>
                                                        <div className="custom-file-uploader min-height-200 mb-4">
                                                            <input type="file" />
                                                            <i className="file-upload-icon"></i>
                                                            <p className="mb-0">Drop files here or click to upload.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-9">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <label>Display Name</label>
                                                                <input type="text" className="form-control mb-3" placeholder="Enter Display Name" />
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label>Company Name</label>
                                                                <input type="text" className="form-control mb-3" placeholder="Enter Company Name" />
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <label>Job Title</label>
                                                                <input type="text" className="form-control mb-3" placeholder="Enter job title" />
                                                            </div>
                                                        </div>
                                                        <div className="mt-2">
                                                            <label>I work for a</label>
                                                            <div className="d-flex gap-3">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                                                    <label className="form-check-label">
                                                                        Utility
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                                                    <label className="form-check-label">
                                                                        Solution Provider
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                                                    <label className="form-check-label">
                                                                        Other
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <label  className="form-label">Brief Bio</label>
                                                <textarea className="form-control" rows={5} placeholder="Enter Brief Bio"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="light-blue-bg">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h2 className="h6 text-purple font-weight-600">Areas of expertise</h2>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap three-col-block-wrapper">
                                            <div className="white-box three-col-block">
                                                <h2 className="h6 mb-3">Generation</h2>
                                            
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label">Select All</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1" />
                                                    <label className="form-check-label">Renewable Energy</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault2" />
                                                    <label className="form-check-label">Nuclear</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault3" />
                                                    <label className="form-check-label">Natural Gas</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault4" />
                                                    <label className="form-check-label">Coal</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault5" />
                                                    <label className="form-check-label">Energy Storage</label>
                                                </div>
                                            </div>
                                            <div className="white-box three-col-block">
                                                <h2 className="h6 mb-3">Generation</h2>
                                            
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault6" />
                                                    <label className="form-check-label">Select All</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault7" />
                                                    <label className="form-check-label">Renewable Energy</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault8" />
                                                    <label className="form-check-label">Nuclear</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault9" />
                                                    <label className="form-check-label">Natural Gas</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault10" />
                                                    <label className="form-check-label">Coal</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault11" />
                                                    <label className="form-check-label">Energy Storage</label>
                                                </div>
                                            </div>
                                            <div className="white-box three-col-block">
                                                <h2 className="h6 mb-3">Generation</h2>
                                            
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault12" />
                                                    <label className="form-check-label">Select All</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault13" />
                                                    <label className="form-check-label">Renewable Energy</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault14" />
                                                    <label className="form-check-label">Nuclear</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault15" />
                                                    <label className="form-check-label">Natural Gas</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault16" />
                                                    <label className="form-check-label">Coal</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault17" />
                                                    <label className="form-check-label">Energy Storage</label>
                                                </div>
                                            </div>
                                            <div className="white-box three-col-block">
                                                <h2 className="h6 mb-3">Generation</h2>
                                            
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault18" />
                                                    <label className="form-check-label">Select All</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault19" />
                                                    <label className="form-check-label">Renewable Energy</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault20" />
                                                    <label className="form-check-label">Nuclear</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault21" />
                                                    <label className="form-check-label">Natural Gas</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault22" />
                                                    <label className="form-check-label">Coal</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault23" />
                                                    <label className="form-check-label">Energy Storage</label>
                                                </div>
                                            </div>
                                            <div className="white-box three-col-block">
                                                <h2 className="h6 mb-3">Generation</h2>
                                            
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault24" />
                                                    <label className="form-check-label">Select All</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault25" />
                                                    <label className="form-check-label">Renewable Energy</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault26" />
                                                    <label className="form-check-label">Nuclear</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault27" />
                                                    <label className="form-check-label">Natural Gas</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault28" />
                                                    <label className="form-check-label">Coal</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault29" />
                                                    <label className="form-check-label">Energy Storage</label>
                                                </div>
                                            </div>
                                            <div className="white-box three-col-block">
                                                <h2 className="h6 mb-3">Generation</h2>
                                            
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault30" />
                                                    <label className="form-check-label">Select All</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault31" />
                                                    <label className="form-check-label">Renewable Energy</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault32" />
                                                    <label className="form-check-label">Nuclear</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault33" />
                                                    <label className="form-check-label">Natural Gas</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault34" />
                                                    <label className="form-check-label">Coal</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault35" />
                                                    <label className="form-check-label">Energy Storage</label>
                                                </div>
                                            </div>
                                            <div className="white-box three-col-block">
                                                <h2 className="h6 mb-3">Generation</h2>
                                            
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault36" />
                                                    <label className="form-check-label">Select All</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault37" />
                                                    <label className="form-check-label">Renewable Energy</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault38" />
                                                    <label className="form-check-label">Nuclear</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault39" />
                                                    <label className="form-check-label">Natural Gas</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault40" />
                                                    <label className="form-check-label">Coal</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault41" />
                                                    <label className="form-check-label">Energy Storage</label>
                                                </div>
                                            </div>
                                            <div className="white-box three-col-block">
                                                <h2 className="h6 mb-3">Generation</h2>
                                            
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault42" />
                                                    <label className="form-check-label">Select All</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault43" />
                                                    <label className="form-check-label">Renewable Energy</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault44" />
                                                    <label className="form-check-label">Nuclear</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault45" />
                                                    <label className="form-check-label" >Natural Gas</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault46" />
                                                    <label className="form-check-label">Coal</label>
                                                </div>
                                                <div className="d-flex gap-2 mb-2">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault47" />
                                                    <label className="form-check-label">Energy Storage</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            <Footer></Footer>
        </React.Fragment>

    );
}

export default ProfileCreate;