import { useDispatch } from 'react-redux'
import { ACTIONS } from '../../../_config'

export function useAppLoader() {
  const dispatch = useDispatch()
  const show = () => {
    dispatch({
      type: ACTIONS.COMMON.LOADER.SET_FP_STATUS,
      payload: true,
    })
  }
  const hide = () => {
    dispatch({
      type: ACTIONS.COMMON.LOADER.SET_FP_STATUS,
      payload: false,
    })
  }
  return { showLoader: show, hideLoader: hide }
}
