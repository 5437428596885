import React, { useEffect, useState } from "react";
import Error from "../elements/formFieldError";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useFormApi } from "../../_common/hooks/api/FormApiHook";
import { SAGA_ACTIONS } from '../../_config/index'
import ControlAttributeProp from "./ControlAttributeProp";

import './formConfig.scss'
interface props {
    index: number
    control: any
    register: any
    getValues: any
    setValue: any
    controlTypes: any
    errors: any
    layout: any
    watch: any
}
const CreateControl = ({ index, control, register, getValues, setValue, controlTypes, errors, layout , watch}: props) => {

    const [controlProps,setControlProps] = useState<any>(null);
    const [colSpan,setColSpan] = useState<any>(1);
    const formApi = useFormApi();

    const getControlTypes = (ControlTypeID:any)=>{
        formApi.call(SAGA_ACTIONS.FORM.GET_CONTROL_PROPS, {urlParams:{ControlTypeID:ControlTypeID}}, (message: any, resp: any) => {
            setControlProps(resp.data.controlTypeProperties) 
        }, (message: any, resp: any) => {

        })
    }
    const typeWatch = watch(`field.${index}.controlType`)


    useEffect(()=>{
        if(typeWatch){
            getControlTypes(typeWatch.value)
        }
    },[typeWatch])

    return (
        <div  className={`col-md-${(layout?parseInt(layout.col):1 )* parseInt(colSpan)} mb-3`}>
            <div className="form-control-config">
                <label htmlFor="tenant" className="form-label">Type</label>
                <Controller
                    defaultValue=""
                    control={control}
                    name={`field.${index}.controlType`}
                    rules={{required: true}}
                    render={({ field: { onChange, value, ref, name } }) => (
                        <div>
                            <Select
                                name={name}
                                onChange={onChange}
                                value={value}
                                options={controlTypes}
                                isMulti={false}
                            />
                            <Error error={errors[`field.${index}.controlType`]} />
                        </div>
                    )}
                />
                <hr />
                {controlProps?.map((prop:any, i:any)=>(
                <ControlAttributeProp key={i}
                    controlType={typeWatch}
                    index={index}
                    control={control}
                    register={register}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    prop={prop}
                    layout={layout}
                    setColSpan={setColSpan}
                    watch={watch}
                    
                />
            ))}

            </div>

            
        </div>
    );
}

export default CreateControl;