import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import LeftPanel from "../components/leftPanel/LeftPanel";

const FinalGoNogoDecision = () =>{
    return(
        <React.Fragment>
        <Header></Header>

        <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-2">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <ul className="csmt-breadcrumb d-flex gap-3 mb-2 p-0">
                                    <li><a href="#">Dashboard</a></li>
                                    <li>Landed</li>
                                </ul>
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">Landed | USE CASE: grid edge elastic computing</h1>
                                
                            </div>
                            <div className="col-sm-6">
                                <div className="d-flex justify-content-end">
                                    <img src="assets/images/time.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="two-panel-wrapper d-flex">
                                <LeftPanel></LeftPanel>
                                <div className="right-panel">
                                    <div className="white-box">
                                        <div className="text-center">
                                            <div className="progress-steps-panel go_nogonav d-inline-flex mb-4">
                                                <div className="progress-steps active d-flex align-items-center gap-3 like">
                                                    <div className="progress-number">
                                                        <i className="icon-asset material-symbols-outlined">thumb_up</i>
                                                    </div>
                                                    <p>Approve</p>
                                                </div>
                                                <div className="progress-steps d-flex align-items-center gap-3 reject">
                                                    <div className="progress-number">
                                                        <i className="icon-asset material-symbols-outlined">thumb_down</i>
                                                    </div>
                                                    <p>Reject</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <label>Note</label>
                                            <textarea className="form-control" cols={4} rows={10}></textarea>
                                        </div>
                                        <div className="col-12 d-flex justify-content-end gap-3 mt-4">
                                            
                                            <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Submit <i className="arrow-right"></i></button>
                                        </div>
                                       
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <Footer></Footer>
        </React.Fragment>
    );
}

export default FinalGoNogoDecision;