import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import Error from '../../components/elements/formFieldError'
import { URLS } from '../../_config'
import { useUser } from '../../_common/hooks/authHook'

export const AddEmployee = () => {

    const { control, register, handleSubmit, reset, getValues, formState: { errors, isValid }, } = useForm()
    const navigate = useNavigate()

    const userInfo: any = useUser();

    const userApi = useUserApi()
    const params: any = useParams();
    const [empId, setEmpId] = useState<any>(params?.id);

    const [roles, setRoles] = useState<Array<any>>([]);
    const [empDetails, setEmpDetails] = useState<any>({});

    const getUserDetails = () => {
        userApi.userDetails({ urlParams: { UserProfID: empId } }, (message: any, resp: any) => {
            reset({
                Title: resp.data.title,
                FirstName: resp.data.firstName,
                LastName: resp.data.lastName,
                DisplayName: resp.data.displayName,
                Email: resp.data.email,
                Phone: resp.data.phone,
                Bio: resp.data.bio,
            })
            setEmpDetails(resp.data);
        }, (message: any, resp: any) => {
            // Error...
        })
    }


    const getRoleList = () => {
        //userInfo?.companyID // this is for fontend user
        // 0 // for supper user
        userApi.listRole({ FKCompanyID: 0 }, (message: any, resp: any) => {
            setRoles(resp.data.roles);
        }, (message: any, resp: any) => {
            // Error...
        })

    }
    useEffect(() => {
        getRoleList();
        if (empId) {
            getUserDetails()
        }
    }, [empId]);


    const convertBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };


    const submitData = (data: any) => {
        return new Promise((resolve, reject) => {
            if (data.Image) {
                convertBase64((document.getElementById('employeeImage') as any).files[0]).then((base64File) => {
                    data.Avatar = base64File
                })
            }

            
            data.Title = data.Title
            data.FirstName = data.FirstName
            data.LastName = data.LastName
            data.DisplayName = data.DisplayName
            data.Phone = data.Phone
            data.Bio = data.Bio
            data.fkRoleID = data.fkRoleID
            data.FKCompanyID = data.FKCompanyID
            data.FKTenantID = data.FKTenantID
            data.FKWorkForID = data.FKWorkForID
            data.FKJobTitleID = data.FKJobTitleID
            data.Password = data.Password
            data.updatedBy = userInfo.userID

            if ((document.getElementById('employeeImage') as any).files.length) {
                data.Avatar = (document.getElementById('employeeImage') as any).files[0]
            }

            setTimeout(() => {
                if (!empId) {
                    data.Email = data.Email
                    data.Username = data.Email

                    userApi.addEmployee(data, (message: any, resp: any) => {
                        resolve(message)
                    }, (message: any) => {
                        reject(message)
                    })
                } else {
                    data.userProfID = empId
                    data.fkUserID = empDetails && empDetails.fkUserID;
                    data.isActive = true
                    
                    userApi.userUpdate(data, (message: any, resp: any) => {
                        resolve(message)
                    }, (message: any) => {
                        reject(message)
                    })
                }
            }, 1000)
            //resolve('success')
        })
    }

    const onSubmit = (data: any) => {
        toast.promise(
            submitData(data), {
            loading: 'Saving...',
            success: (msg: any) => { reset(); navigate(URLS.EMPLOYEE_LIST); return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }


    return (

        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><Link to={URLS.DASHBOARD}>Dashboard</Link></li>
                                    <li><Link to={URLS.EMPLOYEE_LIST}>Employee List</Link></li>
                                    <li>Employee Data</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">{(empId > 0) ? 'Edit' : 'Add'} Employee Data</h1>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-8">
                            <div className="white-box">
                                <div className="form-area">
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)} >
                                        <div className={'col-md-6'} >
                                            <label htmlFor="" className="form-label">Title</label>
                                            <Controller
                                                defaultValue=""
                                                control={control}
                                                name="Title"
                                                rules={{ required: true }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <input type="text" className="form-control" placeholder={''} ref={ref} onChange={onChange} value={value} />
                                                )}
                                            />
                                            <Error error={errors['Title']} />
                                        </div>

                                        <div className={'col-md-6'} >
                                            <label htmlFor="" className="form-label">First Name</label>
                                            <Controller
                                                defaultValue=""
                                                control={control}
                                                name="FirstName"
                                                rules={{ required: true }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <input type="text" className="form-control" placeholder={''} ref={ref} onChange={onChange} value={value} />
                                                )}
                                            />
                                            <Error error={errors['FirstName']} />
                                        </div>

                                        <div className={'col-md-6'} >
                                            <label htmlFor="" className="form-label">Last Name</label>
                                            <Controller
                                                defaultValue=""
                                                control={control}
                                                name="LastName"
                                                rules={{ required: true }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <input type="text" className="form-control" placeholder={''} ref={ref} onChange={onChange} value={value} />
                                                )}
                                            />
                                            <Error error={errors['LastName']} />
                                        </div>

                                        <div className={'col-md-6'} >
                                            <label htmlFor="" className="form-label">Display Name</label>
                                            <Controller
                                                defaultValue=""
                                                control={control}
                                                name="DisplayName"
                                                rules={{ required: true }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <input type="text" className="form-control" placeholder={''} ref={ref} onChange={onChange} value={value} />
                                                )}
                                            />
                                            <Error error={errors['DisplayName']} />
                                        </div>

                                        <div className={'col-md-6'} >
                                            <label htmlFor="" className="form-label">Email</label>
                                            <Controller
                                                defaultValue=""
                                                control={control}
                                                name="Email"
                                                rules={{ required: true }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <input type="text" className="form-control" placeholder={''} ref={ref}
                                                        onChange={onChange} value={value} disabled={empId > 0} />
                                                )}
                                            />
                                            <Error error={errors['Email']} />
                                        </div>

                                        <div className={'col-md-6'} >
                                            <label htmlFor="" className="form-label">Phone</label>
                                            <Controller
                                                defaultValue=""
                                                control={control}
                                                name="Phone"
                                                rules={{ required: false }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <input type="text" className="form-control" placeholder={''} ref={ref} onChange={onChange} value={value} />
                                                )}
                                            />
                                            <Error error={errors['Phone']} />
                                        </div>

                                        {(empId == null) &&
                                            <div className={'col-md-6'} >
                                                <label htmlFor="" className="form-label">Password</label>
                                                <Controller
                                                    defaultValue=""
                                                    control={control}
                                                    name="Password"
                                                    rules={{ required: true }}
                                                    render={({ field: { onChange, value, ref } }) => (
                                                        <input type="text" className="form-control" placeholder={''} ref={ref} onChange={onChange} value={value} />
                                                    )}
                                                />
                                                <Error error={errors['Password']} />
                                            </div>
                                        }

                                        <div className="col-md-6">
                                            <label htmlFor="fkRoleID" className="form-label">Primary Role </label>
                                            <select className="form-control" {...register("fkRoleID", { required: "Role is required" })} id="fkRoleID">
                                                <option value=''>Select one...</option>
                                                {
                                                    (roles && roles.length > 0) &&
                                                    roles.map((item: any) => item?.roleID != 1 &&
                                                        <option value={item?.roleID} key={item?.roleID} selected={(empDetails.fkRoleID == item?.roleID) ? true : false}>{item?.displayTitle}</option>
                                                    )
                                                }
                                            </select>
                                            <Error error={errors['fkRoleID']} />
                                        </div>


                                        <div className={'col-md-12'} >
                                            <label htmlFor="" className="form-label">Bio</label>
                                            <Controller
                                                defaultValue=""
                                                control={control}
                                                name="Bio"
                                                rules={{ required: false }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <input type="text" className="form-control" placeholder={''} ref={ref} onChange={onChange} value={value} />
                                                )}
                                            />
                                            <Error error={errors['Bio']} />
                                        </div>

                                        <div className="col-md-12">
                                            <div className="custom-file-uploader mb-4">
                                                <Controller
                                                    defaultValue=""
                                                    control={control}
                                                    name={'Image'}
                                                    rules={{
                                                        required: false,
                                                    }}
                                                    render={({ field: { onChange, value, ref, name } }) => (
                                                        <input id="employeeImage" type="file" name={name} ref={ref} onChange={onChange} value={value} />
                                                    )}
                                                />
                                                <i className="file-upload-icon"></i>
                                                <p className="mb-0">Click to upload.</p>
                                            </div>
                                            <Error error={errors['Image']} />
                                        </div>



                                        <input type="hidden" value={1} {...register('FKCompanyID')} />
                                        <input type="hidden" value={0} {...register('FKTenantID')} />
                                        <input type="hidden" value={1} {...register('FKJobTitleID')} />
                                        <input type="hidden" value={1} {...register('FKWorkForID')} />


                                        <div className="col-12 d-flex justify-content-end gap-3">
                                            <Link to={URLS.EMPLOYEE_LIST} className={`btn btn-info d-flex align-items-center gap-2`} >Back</Link>

                                            <button type="submit" className={`btn btn-primary d-flex align-items-center gap-2`} >{(empId > 0) ? 'Update' : 'Add'} Employee <i className="arrow-right"></i></button>

                                        </div>
                                    </form>
                                </div>
                                {/* <DynamicForm config={config} onSubmitSuccess={()=>{navigate('/employee-list')}}/> */}
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="pt-5">
                                <img src="/../images/idea-pic.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>

    )
}
