import React from "react";
import PageTitle from '../../components/pageTitle/PageTitle';
import Table from '../../components/table/Table';
import Pagination from '../../components/pagination/Pagination';
import DataFilters from '../../components/dataFilters/DataFilters';
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import './intectFlow.scss'

const IdeaList = () => {
  return (
    <React.Fragment>
      <Header></Header>

      <div className="main-container flex-grow-1">
        <div className="container">
          <PageTitle></PageTitle>
          <div className="row">
            <div className="col-sm-12">
              <DataFilters></DataFilters>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h1 className="h5 mb-0 font-weight-600"></h1>
                <div className="btn-group ms-auto pe-2">
                  <Link to="/use-case-library-list" className="btn btn-primary">List</Link>
                  <Link to="/use-case-library-grid" className="btn btn-primary">Grid</Link>
                </div>
                <Link to="/idea-submit" className="btn btn-info py-2 add-more-files-btn">Create Usecase</Link>
              </div>
              <div className="white-box px-4 pt-3">
                <Table></Table>
                {/* <Pagination></Pagination> */}
              </div>
            </div>
          </div>
        </div>
      </div>     

      <Footer></Footer>
    </React.Fragment>
  );
}

export default IdeaList;