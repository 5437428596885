import React from "react";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const KpiTrendingdashboard = () =>{
    return(
      <React.Fragment>
        <Header></Header>

        <div className="main-container flex-grow-1">
        <div className="container">
            <div className="page-title py-4">
                <div className="row align-items-center">
                    <div className="col-sm-12">
                        <ul className="csmt-breadcrumb d-flex gap-3 mb-2 p-0">
                            <li><a href="#">Dashboard</a></li>
                            <li>Executive summary</li>
                        </ul>
                        <h1 className="h6 text-uppercase font-weight-700  mb-0">KPI and Trending Dashboard</h1>
                        
                    </div>
                    
                </div>
            </div>
            <div className="table-filter-panel mb-4 d-flex gap-3">
                <div className="flex-1">
                    <label>Section</label>
                    <select className="form-control">
                        <option>All</option>
                    </select>
                </div>
                <div className="flex-1">
                    <label>Phase</label>
                    <select className="form-control">
                        <option>All</option>
                    </select>
                </div>
                <div className="flex-1">
                    <label>Airport</label>
                    <select className="form-control">
                        <option>All</option>
                    </select>
                </div>
                <div className="flex-1">
                    <label>Captain</label>
                    <select className="form-control">
                        <option>All</option>
                    </select>
                </div>
                <div className="flex-1">
                    <label>Runway</label>
                    <select className="form-control">
                        <option>All</option>
                    </select>
                </div>
                <div className="flex-1">
                    <label>Date</label>
                    <div className="d-flex">
                        <input className="form-control borderradius_startdate" type="date" placeholder="Start Date"/>
                        <input className="form-control borderradius_enddate" type="date" placeholder="End Date"/>
                    </div>
                    
                </div>
            </div>

            <div className=" executive-dashboard">
                <div className="dashboard-top-part kpidashboard_top mb-5">
                    <div className="d-flex flex-wrap gap-3">
                        <div className="dashboard-top-part-body flex-1">
                            <h3>Avg. Hanger duration</h3>
                            <div className="dashboard-topgraph">
                                <div className="dashboard-topgraphleft">
                                    <div className="d-flex align-items-center">
                                        <div className="dashboard-topPrice">0.00</div>
                                        <div className="dashboard-topOk">
                                            <img src="../images/Done.png" alt=""/>
                                        </div>
                                    </div>
                                    <p>Goal : 90(+100%)</p>
                                </div>
                                <div className="dashboard-topgraphright">
                                    <img src="../images/Vector 9.png" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-top-part-body flex-1">
                            <h3>Avg. runway duration</h3>
                            <div className="dashboard-topgraph">
                                <div className="dashboard-topgraphleft">
                                    <div className="d-flex align-items-center">
                                        <div className="dashboard-topPrice">0.00</div>
                                        <div className="dashboard-topOk">
                                            <img src="../images/Done.png" alt=""/>
                                        </div>
                                    </div>
                                    <p>Goal : 150(+100%)</p>
                                </div>
                                <div className="dashboard-topgraphright">
                                    <img src="../images/Vector 14.png" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-top-part-body flex-1">
                            <h3>Avg. in-the-air duration</h3>
                            <div className="dashboard-topgraph">
                                <div className="dashboard-topgraphleft">
                                    <div className="d-flex align-items-center">
                                        <div className="dashboard-topPrice">7.00</div>
                                        <div className="dashboard-topOk">
                                            <img src="../images/Done.png" alt=""/>
                                        </div>
                                    </div>
                                    <p>Goal : 90(+92.22%)</p>
                                </div>
                                <div className="dashboard-topgraphright">
                                    <img src="../images/Vector 13.png" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-top-part-body flex-1">
                            <h3>Avg. landed durations</h3>
                            <div className="dashboard-topgraph">
                                <div className="dashboard-topgraphleft">
                                    <div className="d-flex align-items-center">
                                        <div className="dashboard-topPrice">320.00</div>
                                        <div className="dashboard-topOk">
                                            <img src="../images/Done.png"/>
                                        </div>
                                    </div>
                                    <p>Goal : 395(+18.99%)</p>
                                </div>
                                <div className="dashboard-topgraphright">
                                    <img src="../images/Vector 15.png"/>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-top-part-body flex-1">
                            <h3>total use cases</h3>
                            <div className="dashboard-topgraph">
                                <div className="dashboard-topgraphleft">
                                    <div className="d-flex align-items-center">
                                        <div className="dashboard-topPrice">47</div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-top-part kpidashboard_top mb-5">
                    <div className="d-flex flex-wrap gap-3">
                        <div className="dashboard-top-part-body flex-1">
                            <h3>Avg. duration by date</h3>
                            <div className="dashboard-topgraph">
                                <img src="../images/graph_01.jpg" alt="" />
                            </div>
                        </div>
                        <div className="dashboard-top-part-body flex-1">
                            <h3>use cases by date and section</h3>
                            <div className="dashboard-topgraph">
                                <img src="../images/graph_02.jpg" alt="" />
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="dashboard-top-part kpidashboard_top mb-5">
                    <div className="d-flex flex-wrap gap-3">
                        <div className="dashboard-top-part-body flex-1">
                            <h3>Avg. duration by phase</h3>
                            <div className="dashboard-topgraph">
                                <img src="../images/graph_03.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="dashboard-top-part-body flex-1">
                            <h3>Avg. duration by section</h3>
                            <div className="dashboard-topgraph">
                                <img src="../images/graph_04.jpg" alt="" />
                            </div>
                        </div>
                        
                    </div>
                </div>



            </div>
        </div>
    </div>
          
        <Footer></Footer>
        </React.Fragment>
    );
}

export default KpiTrendingdashboard;