import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from 'react-hot-toast';
import { useForm, SubmitHandler } from "react-hook-form"
import { URLS } from "../../_config";

export const SystemConfigurationForm = () => {
    const navigate = useNavigate();
    const userApi = useUserApi();
    const params: any = useParams();
    type Inputs = {
        key: string
        value: string
        description: string
        configGroup: string
        fkCompanyID: number
    }

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        reset,
        formState: { errors },
    } = useForm<Inputs>()

    const [systemConfigId, setTenantsId] = useState<any>(params?.id);
    const [companyListData, setCompanyListData] = useState<any>({});

    const getSystemConfig = () => {
        userApi.getSystemConfig({ urlParams: { ID: systemConfigId } }, (message: any, resp: any) => {
            reset({
                key: resp.data.key,
                value: resp.data.value,
                description: resp.data.description,
                configGroup: resp.data.configGroup,
                fkCompanyID: resp.data.fkCompanyID,
            })
        }, (message: any, resp: any) => {
            // Error...
        })
    }
    const getCompanyList = () => {
        userApi.companyList({ urlParams: { index: 1, size: 100 } }, (message: any, resp: any) => {
            setCompanyListData(resp.data.companies)
        }, (message: any, resp: any) => {
            // Error...
        })
    }
    useEffect(() => {
        getCompanyList();
        if (systemConfigId) {
            getSystemConfig()
        }
    }, [systemConfigId]);


    const submitData = (data: any) => {
        let formParam = {
            ...data,
            "isActive": true
        };
        if (!systemConfigId) {
            return new Promise((resolve, reject) => {
                userApi.systemConfigSave(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message)
                })
            })
        } else {
            formParam.sysConfgID = systemConfigId;
            return new Promise((resolve, reject) => {
                userApi.systemConfigUpdate(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message)
                })
            })
        }
    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        toast.promise(
            submitData(data), {
            loading: (!systemConfigId) ? 'Creating...' : 'Updating...',
            success: (msg: any) => { navigate(URLS.SYSTEM_CONFIGURATION); return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    return (
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><Link to={URLS.DASHBOARD}>Dashboard</Link></li>
                                    <li><Link to={URLS.SYSTEM_CONFIGURATION}>System Config</Link></li>
                                    <li>{(systemConfigId > 0) ? 'Edit' : 'Add'}  System Config</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">{(systemConfigId > 0) ? 'Edit' : 'Add'} System Config</h1>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="white-box h-100">
                                <div className="form-area">
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>

                                        <div className="col-md-6">
                                            <label htmlFor="key" className="form-label">Config Key</label>
                                            <input   {...register("key", { required: "Key is required" })} type="text" className="form-control" placeholder="Put Key" id="key" />
                                            {errors.key && <span className="text-danger">{errors.key.message}</span>}
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="value" className="form-label">Config Value</label>
                                            <input type="text"   {...register("value", { required: "Value is required" })} className="form-control" placeholder="Put Value" id="value" />
                                            {errors.value && <span className="text-danger">{errors.value.message}</span>}
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="fkCompanyID" className="form-label">Company </label>
                                            <select className="form-control" {...register("fkCompanyID", { required: "Company is required" })} id="fkCompanyID">
                                                <option value=''>Select one...</option>
                                                <option value={0}>GLOBAL</option>
                                                {
                                                    (companyListData && companyListData.length > 0) &&
                                                    companyListData.map((compItem: any) =>
                                                        <option value={compItem?.companyID} key={compItem?.companyID}>{compItem?.companyName}</option>
                                                    )
                                                }
                                            </select>
                                            {errors.fkCompanyID && <span className="text-danger">{errors.fkCompanyID.message}</span>}
                                        </div>

                                        <div className="col-md-12">
                                            <label htmlFor="configGroup" className="form-label">Config Group</label>
                                            <input type="text"   {...register("configGroup", { required: "Config Group is required" })} className="form-control" placeholder="Put Config Group" id="configGroup" />
                                            {errors.configGroup && <span className="text-danger">{errors.configGroup.message}</span>}
                                        </div>


                                        <div className="col-md-12">
                                            <label htmlFor="description" className="form-label">Description</label>
                                            <textarea  {...register("description", { required: "Value is required" })} className="form-control" placeholder="Put description" id="description" />
                                            {errors.description && <span className="text-danger">{errors.description.message}</span>}
                                        </div>


                                        <div className="col-12 d-flex justify-content-end gap-3">
                                            <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Submit <i className="arrow-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="pt-5">
                                <img src="/../images/idea-pic.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}
