import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { useUserApi } from '../../_common/hooks/api/UserApiHook';
import './intectFlow.scss'

export const UseCaseDetails = () => {
    const { id } = useParams();
    const userApi = useUserApi();
    const [isPanelCollapsed, setPanelCollapsed] = useState(false);
    const [caseDetails, setCaseDetails] = useState<any>([]);
    const [chooseDecision, setChooseDecision] = useState<any>([]);
    const [allAirport, setAllAirport] = useState<any>([]);
    const [selectedOption, setSelectedOption] = useState(0);

    const getChooseDecision = () => {
        const options = [
            { value: 1, label: 'Proceed' },
            { value: 2, label: 'Review in 3 months' },
            { value: 3, label: 'Review in 6 months' },
            { value: 4, label: 'Review in 12 months' },
            { value: 5, label: 'Pass' },
        ];
        setChooseDecision(options);
    }

    const getAllAirport = () => {
        const options = [
            { value: 1, label: 'Proceed' },
            { value: 2, label: 'Review in 3 months' },
            { value: 3, label: 'Review in 6 months' },
            { value: 4, label: 'Review in 12 months' },
            { value: 5, label: 'Pass' },
        ];
        setAllAirport(options);
    }


    const chooseDecisionOnChange = (event: any) => {
        const selectedValue = parseInt(event.target.value, 10);
        setSelectedOption(selectedValue);
    };

    const handleButtonClick = () => {
        setPanelCollapsed((prevState) => !prevState);
    }

    const handleCloseClick = () => {
        setPanelCollapsed(false);
        setSelectedOption(0);
    };

    const getUserCaseInfo = () => {
        userApi.useCaseDetails({ urlParams: { id: id } }, (message: any, resp: any) => {
            setCaseDetails(resp.data)
        }, (message: any) => {

        })
    }

    useEffect(() => {
        getUserCaseInfo()
        getChooseDecision();
        getAllAirport();
    }, [])

    const formatDate = (dateString: any) => {
        const options: object = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    }


    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row align-items-center">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex gap-3 mb-2 p-0">
                                    <li><a href="#">Dashboard</a></li>
                                    <li>Use case library</li>
                                </ul>
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">Use case library</h1>

                            </div>

                        </div>
                    </div>

                    <div className="white-box">
                        <div className="row ">
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className="feature-image"><img src={caseDetails.imgURL ? caseDetails.imgURL : '../images/runway-use-case.svg'} alt="#" /></div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className="feature-image-text pt-34">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <button type="submit" className="btn btn-primary d-flex align-items-center gap-2 mb-3">{caseDetails.status}</button>
                                        <a href="#" className="edit_iconbtn"><i className="edit-icon"></i></a>
                                    </div>

                                    <h2 className="project-sub-hed mb-1">{caseDetails.useCase}</h2>
                                    <div className="feature-date">
                                        <h5>Created by - <span>Tanmay Mandal</span></h5>
                                        <h5>Created date - <span>{formatDate(caseDetails.addedDate)}</span></h5>
                                    </div>
                                    <div className="feature-graphis-icon pt-25 ">
                                        <div className="feature-icon"><img src="../images/runway-use-case.svg" alt="#" /></div>
                                        <div className="feature-icon-text">
                                            <span>Airport</span>
                                            <h5>NA</h5>
                                        </div>
                                    </div>
                                    <div className="feature-graphis-line-icon">
                                        <div className="row mx-15">
                                            <div className="col-lg-4 col-md-6 col-12 feature-graphis-border feature-graphis-border-right resp-border">
                                                <div className="feature-graphis-icon ps-0">
                                                    <div className="feature-icon"><img src="../images/air-pilot-hat.svg" alt="#" /></div>
                                                    <div className="feature-icon-text">
                                                        <span>Captain</span>
                                                        <h5>NA</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 feature-graphis-border feature-graphis-border-right resp-border">
                                                <div className="feature-graphis-icon">
                                                    <div className="feature-icon"><img src="../images/menu.svg" alt="#" /></div>
                                                    <div className="feature-icon-text">
                                                        <span>Section</span>
                                                        <h5>NA</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 feature-graphis-border resp-border">
                                                <div className="feature-graphis-icon pe-0">
                                                    <div className="feature-icon"><img src="../images/night-portrait.svg" alt="#" /></div>
                                                    <div className="feature-icon-text">
                                                        <span>Phase</span>
                                                        <h5>NA</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 feature-graphis-border-right resp-border">
                                                <div className="feature-graphis-icon ps-0">
                                                    <div className="feature-icon"><img src="../images/one-to-one.svg" alt="#" /></div>
                                                    <div className="feature-icon-text">
                                                        <span>Score</span>
                                                        <h5>0</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 feature-graphis-border-right resp-border">
                                                <div className="feature-graphis-icon">
                                                    <div className="feature-icon"><img src="../images/rating.svg" alt="#" /></div>
                                                    <div className="feature-icon-text">
                                                        <span>Rank</span>
                                                        <h5>#0</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 resp-border">
                                                <div className="feature-graphis-icon pe-0">
                                                    <div className="feature-icon"><img src="../images/singles-choice.svg" alt="#" /></div>
                                                    <div className="feature-icon-text">
                                                        <span>Like</span>
                                                        <h5>0</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" className="btn-secondary btn w-auto show-btn-2" onClick={handleButtonClick}>Review History</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flight-section flight-section-gray mx-0">
                            <ul>
                                <li className="active">
                                    <img src="../images/hanger_disable.svg" alt="#" />
                                    <span>Hanger</span>
                                </li>
                                <li>
                                    <img src="../images/runway-gray.svg" alt="#" />
                                    <span>Runway</span>
                                </li>
                                <li>
                                    <img src="../images/flight-gray.svg" alt="#" />
                                    <span>In-flight</span>
                                </li>
                                <li>
                                    <img src="../images/land-gray.svg" alt="#" />
                                    <span>landed</span>
                                </li>
                            </ul>
                        </div>

                        <div className="usecase-paragraph mb-50">
                            <div className="form-area">
                                <label>Please describe the big picture idea and potential learning objectives driving interest in the use case. What is the product or technology this opportunity is based on?</label>
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.</p>
                            </div>
                        </div>
                        <div className="usecase-paragraph mb-50">
                            <div className="form-area">
                                <label>What question(s) would we want to answer in a</label>
                                <ul className="question-checkbox">
                                    <li className="active">
                                        <i className="q-check-box"></i> Demonstration
                                    </li>
                                    <li >
                                        <i className="q-check-box"></i> Prototype
                                    </li>
                                    <li >
                                        <i className="q-check-box"></i> Pilot
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="usecase-paragraph mb-50">
                            <div className="form-area">
                                <label>How do we meet this need today?</label>
                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                            </div>
                        </div>
                        <div className="usecase-paragraph mb-50">
                            <div className="form-area">
                                <label>If this solution proves viable, how would it change our approach?</label>
                                <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                            </div>
                        </div>
                        <div className="usecase-paragraph mb-50">
                            <div className="form-area">
                                <label>What risks do we see in the testing of this use case?</label>
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                            </div>
                        </div>
                        <div className="usecase-paragraph mb-50">
                            <div className="form-area">
                                <label>Do you have a product / vendor in mind?</label>
                                <ul className="question-checkbox">
                                    <li className="active">
                                        <i className="q-check-box"></i> In the list
                                    </li>
                                    <li >
                                        <i className="q-check-box"></i> Not Listed
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="usecase-paragraph mb-50">
                            <div className="form-area">
                                <label>Is the product / service commercially available?</label>
                                <ul className="question-checkbox">
                                    <li >
                                        <i className="q-check-box"></i> Yes
                                    </li>
                                    <li >
                                        <i className="q-check-box"></i> No
                                    </li>
                                    <li className="active">
                                        <i className="q-check-box"></i> Prototype/Pre-Commercial
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="usecase-paragraph ">
                            <div className="form-area">
                                <label>Anything else we should know?</label>
                                <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                            </div>
                        </div>
                    </div>

                </div>
                {/* <!-- right-bar2 --> */}
                <div className={`right-float-bar right-float-bar-2 w-500 slow ${isPanelCollapsed ? 'collapse-panel-right' : ''}`}>
                    <div className="right-float-bar-body">
                        <Link to="#" className="time-cancel hide-btn right-panel-cancel-btn" onClick={handleCloseClick} >
                            <img src="../images/time-cancel.svg" alt="#" />
                        </Link>
                        <h6>Submit Your Review</h6>
                        <form className="mt-5 form-area">
                            <div className="row">

                                <div className="col-lg-12 col-md-12 col-12">
                                    <div className="form-input">
                                        <label>Status</label>
                                        <select className="form-control mb-3" value={selectedOption} onChange={chooseDecisionOnChange}>
                                            <option value={0}> Choose Decision </option>

                                            {chooseDecision.length > 0 ? (
                                                chooseDecision.map((option: any) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))
                                            ) : (
                                                <option value="" disabled>
                                                    No options available
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                {selectedOption >= 2 ? (
                                    <section className={selectedOption >= 2 ? 'show' : 'hide'}>
                                        <div className="col-12">
                                            <div className="form-input">
                                                <label>Review</label>
                                                <textarea className="form-control mb-3" rows={5}></textarea>
                                            </div>
                                        </div>
                                    </section>
                                ) : null}

                                {selectedOption === 1 ? (
                                    <section className={selectedOption === 1 ? 'show' : 'hide'}>
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="form-input">
                                                <label>Chose Airport</label>
                                                <select className="form-control mb-3">
                                                    <option value={0}> Select </option>
                                                    {allAirport.length > 0 ? (
                                                        allAirport.map((airport: any) => (
                                                            <option key={airport.value} value={airport.value}>
                                                                {airport.label}
                                                            </option>
                                                        ))
                                                    ) : (
                                                        <option value="" disabled>
                                                            No options available
                                                        </option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="form-input">
                                                <label>Chose Runway</label>
                                                <select className="form-control mb-3">
                                                    <option> Select </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="form-input">
                                                <label>Choose Captain</label>
                                                <select className="form-control mb-3">
                                                    <option> Select </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="form-input">
                                                <label>Choose Co-Captain</label>
                                                <select className="form-control mb-3">
                                                    <option> Select </option>
                                                </select>
                                            </div>
                                        </div>
                                    </section>
                                ) : null}

                                <div className="col-12 d-flex justify-content-end gap-3 mt-4">
                                    {/* <!-- <button type="submit" className="btn btn-primary d-flex align-items-center gap-2">Cancel <i className="arrow-right"></i></button> --> */}
                                    <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Submit <i className="arrow-right"></i></button>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>
                {/* <!-- right-bar2 --> */}
            </div>
            <Footer></Footer>
        </React.Fragment>
    )
}
