import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import LeftPanel from "../components/leftPanel/LeftPanel";

const TestPlan = () =>{
    return(
        <React.Fragment>
        <Header></Header>
        
        <div className="main-container flex-grow-1">
        <div className="container">
            <div className="page-title py-2">
                <div className="row align-items-center">
                    <div className="col-sm-6">
                        <ul className="csmt-breadcrumb d-flex gap-3 mb-2 p-0">
                            <li><a href="#">Dashboard</a></li>
                            <li>Use case librery</li>
                        </ul>
                        <h1 className="h6 text-uppercase font-weight-700  mb-0">The hanger</h1>
                        
                    </div>
                    <div className="col-sm-6">
                        <div className="d-flex justify-content-end">
                            <img src="../images/time.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="two-panel-wrapper d-flex">
                        <LeftPanel></LeftPanel>
                        <div className="right-panel">
                            <div className="progress-steps-panel d-flex mb-4">
                                <div className="progress-steps d-flex align-items-center gap-3">
                                    <div className="progress-number check-done">1</div>
                                    <p>Project Plan</p>
                                </div>
                                <div className="progress-steps d-flex align-items-center gap-3">
                                    <div className="progress-number check-done">2</div>
                                    <p>Procurement Plan</p>
                                </div>
                                <div className="progress-steps active d-flex align-items-center gap-3">
                                    <div className="progress-number">3</div>
                                    <p>Test Plan</p>
                                </div>
                                
                            </div>
                            <div className="white-box">
                                <div className="form-area mb-5">
                                    <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="mb-0">Testing Roles and Responsibilities</h6>
                                    <a href="#" className="btn btn-info py-2 show-btn" >Add More</a>
                                    </div>
                                    <hr />

                                    <div className="table-responsive list-table">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr className="align-middle">
                                                    <th scope="col" className="w-10 text-center">
                                                        <div className="form-check table-center-form-check">
                                                            <input className="form-check-input wh-20" type="checkbox" id="gridCheck" />
                                                        </div>
                                                    </th>
                                                    <th scope="col">Role</th>
                                                    <th scope="col" className="w-50">Name</th>
                                                    <th scope="col" className="w-10">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="align-middle h-60">
                                                    <th className="text-center">
                                                        <div className="form-check table-center-form-check">
                                                            <input className="form-check-input wh-20" type="checkbox" id="gridCheck2" />
                                                        </div>
                                                    </th>
                                                    <td>System Reliability Testing</td>
                                                    <td>James Smith</td>
                                                    <td>
                                                        <div className="d-flex gap-3">
                                                            <a href="#"><i className="delete-icon"></i></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="align-middle h-60">
                                                    <th className="text-center">
                                                        <div className="form-check table-center-form-check">
                                                            <input className="form-check-input wh-20" type="checkbox" id="gridCheck2" />
                                                        </div>
                                                    </th>
                                                    <td>Grid Performance Testing</td>
                                                    <td>Dennis T. Bullock</td>
                                                    <td>
                                                        <div className="d-flex gap-3">
                                                            <a href="#"><i className="delete-icon"></i></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="align-middle h-60">
                                                    <th className="text-center">
                                                        <div className="form-check table-center-form-check">
                                                            <input className="form-check-input wh-20" type="checkbox" id="gridCheck2" />
                                                        </div>
                                                    </th>
                                                    <td>Test Lead</td>
                                                    <td>Michael R. Cody</td>
                                                    <td>
                                                        <div className="d-flex gap-3">
                                                            <a href="#"><i className="delete-icon"></i></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="align-middle h-60">
                                                    <th className="text-center">
                                                        <div className="form-check table-center-form-check">
                                                            <input className="form-check-input wh-20" type="checkbox" id="gridCheck2" />
                                                        </div>
                                                    </th>
                                                    <td>Smart Grid Testing</td>
                                                    <td>Terry K. Reed</td>
                                                    <td>
                                                        <div className="d-flex gap-3">
                                                            <a href="#"><i className="delete-icon"></i></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="align-middle h-60">
                                                    <th className="text-center">
                                                        <div className="form-check table-center-form-check">
                                                            <input className="form-check-input wh-20" type="checkbox" id="gridCheck2" />
                                                        </div>
                                                    </th>
                                                    <td>Smart Grid Testing</td>
                                                    <td>Steve Menninga</td>
                                                    <td>
                                                        <div className="d-flex gap-3">
                                                            <a href="#"><i className="delete-icon"></i></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pagination-panel">
                                        <ul className="d-flex justify-content-center">
                                            <li><a href="#" className="page-nav-arrow-left"></a></li>
                                            <li className="active"><a href="#">1</a></li>
                                            <li><a href="#">2</a></li>
                                            <li><a href="#">3</a></li>
                                            <li><a href="#">4</a></li>
                                            <li><a href="#">5</a></li>
                                            <li><a href="#" className="page-nav-arrow-right"></a></li>
                                        </ul>
                                    </div>
                                    
                                </div>

                                <div className="form-area">
                                    <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="mb-0">Test Cases And Acceptance Criteria</h6>
                                    <a href="#" className="btn btn-info py-2">Add More</a>
                                    </div>
                                    <hr />

                                    <div className="table-responsive list-table resp-table">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr className="align-middle">
                                                    <th scope="col" className="w-10 text-center">
                                                        # Number
                                                    </th>
                                                    <th scope="col" className="w-50">Test Case </th>
                                                    <th scope="col" className="w-25"></th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="align-middle h-60">
                                                    <td className="text-center">
                                                        TC1
                                                    </td>
                                                    <td><h6>Grid Reliability Testing</h6>
                                                        <p>To verify the reliability of the electricity grid during peak load conditions</p>
                                                    </td>
                                                    <td className="d-flex gap-3 align-items-center">
                                                        <div className="table-pic">
                                                            <img src="../images/solution-pic-1.png" width="66" alt="" />
                                                        </div>
                                                        James Smith
                                                    </td>
                                                    <td>
                                                        <div className="d-flex gap-3">
                                                            <a href="#"><i className="delete-icon"></i></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="align-middle h-60">
                                                    <td className="text-center">
                                                        TC2
                                                    </td>
                                                    <td><h6>SCADA System Security</h6>
                                                        <p>To evaluate the security of the Supervisory Control and Data Acquisition (SCADA) system.</p>
                                                    </td>
                                                    <td className="d-flex gap-3 align-items-center">
                                                        <div className="table-pic">
                                                            <img src="../images/solution-pic-1.png" width="66" alt="" />
                                                        </div>
                                                        James Smith
                                                    </td>
                                                    <td>
                                                        <div className="d-flex gap-3">
                                                            <a href="#"><i className="delete-icon"></i></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="align-middle h-60">
                                                    <td className="text-center">
                                                        TC3
                                                    </td>
                                                    <td><h6>Grid Resilience Testing</h6>
                                                        <p>To assess the resilience of the electricity grid against physical threats.</p>
                                                    </td>
                                                    <td className="d-flex gap-3 align-items-center">
                                                        <div className="table-pic">
                                                            <img src="../images/solution-pic-1.png" width="66" alt="" />
                                                        </div>
                                                        James Smith
                                                    </td>
                                                    <td>
                                                        <div className="d-flex gap-3">
                                                            <a href="#"><i className="delete-icon"></i></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="align-middle h-60">
                                                    <td className="text-center">
                                                        TC4
                                                    </td>
                                                    <td><h6>Smart Grid Data Accuracy</h6>
                                                        <p>To verify the accuracy of data collected and transmitted by the smart grid.</p>
                                                    </td>
                                                    <td className="d-flex gap-3 align-items-center">
                                                        <div className="table-pic">
                                                            <img src="../images/solution-pic-2.png" width="66" alt="" />
                                                        </div>
                                                        Patricia Martin
                                                    </td>
                                                    <td>
                                                        <div className="d-flex gap-3">
                                                            <a href="#"><i className="delete-icon"></i></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="align-middle h-60">
                                                    <td className="text-center">
                                                        TC5
                                                    </td>
                                                    <td><h6>Emergency Response Simulation</h6>
                                                        <p>To evaluate the effectiveness of emergency response procedures during a simulated disaster</p>
                                                    </td>
                                                    <td className="d-flex gap-3 align-items-center">
                                                        <div className="table-pic">
                                                            <img src="../images/solution-pic-2.png" width="66" alt="" />
                                                        </div>
                                                        Patricia Martin
                                                    </td>
                                                    <td>
                                                        <div className="d-flex gap-3">
                                                            <a href="#"><i className="delete-icon"></i></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pagination-panel">
                                        <ul className="d-flex justify-content-center">
                                            <li><a href="#" className="page-nav-arrow-left"></a></li>
                                            <li className="active"><a href="#">1</a></li>
                                            <li><a href="#">2</a></li>
                                            <li><a href="#">3</a></li>
                                            <li><a href="#">4</a></li>
                                            <li><a href="#">5</a></li>
                                            <li><a href="#" className="page-nav-arrow-right"></a></li>
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        
                            
                        <div className="right-float-bar slow">
                            <div className="right-float-bar-body">
                            <a href="#" className="time-cancel hide-btn right-panel-cancel-btn" ><img src="../images/time-cancel.svg" alt="#" /></a>
                            <h6>Add Test Cases And Acceptance Criteria</h6>
                            <form className="mt-5 form-area">
                                <div className="form-input">
                                    <label>Summary</label>
                                    <input type="text" className="form-control mb-3" placeholder="Grid Reliability Testing" />
                                    <label>Assignee</label>
                                    <select className="form-control mb-3">
                                        <option>James Smith</option>
                                    </select>
                                    <div className="custom-file-uploader mb-2">
                                        <input type="file" />
                                        <i className="file-upload-icon"></i>
                                        <p className="mb-0">Drop files here or click to upload.</p>
                                    </div>
                                    <div className="uploaded-files d-flex w-100 gap-3 uploaded-files-two my-3">
                                        <span className="uploaded-file-icon"><img src="../images/pdf.svg" alt="" /></span>
                                        <div className="file-upload-progress flex-grow-1">
                                            <div className="d-flex justify-content-between">
                                                <p>Energy Efficiency &amp; Demand Response (100%)</p>
                                                <p className="green-text">Completed</p>
                                            </div>
                                            <div className="file-upload-progress-bar">
                                                <div className="file-upload-progress-percent w-100"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <label>Description</label>
                                    <textarea className="form-control mb-3" rows={5} placeholder="To verify the reliability of the electricity grid during peak load conditions"></textarea>
                                    <label>Acceptance Criteria</label>
                                    <textarea className="form-control mb-3" rows={5} ></textarea>
                                    <label>Test Scope</label>
                                    <textarea className="form-control mb-3" rows={5} ></textarea>
                                    <label>Testing Assumptions</label>
                                    <textarea className="form-control mb-3" rows={5} ></textarea>
                                    <label>Out of Scope Tasks</label>
                                    <textarea className="form-control mb-3" rows={5} ></textarea>
                                </div>
                            </form>
                            <div className="col-12 d-flex justify-content-end gap-3 mt-4">
                                <button type="submit" className="btn btn-primary d-flex align-items-center gap-2">Cancel <i className="arrow-right"></i></button>
                            <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Save <i className="arrow-right"></i></button>
                            </div>
                        </div>
                    </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

        <Footer></Footer>
        </React.Fragment>
    );
}

export default TestPlan;