import React from "react";
import './DataFilters.scss'

const DataFilters = () => {
    return (
        <div className="table-filter-panel mb-3 d-flex gap-3">
            <div className="flex-1">
                <label>Section</label>
                <select className="form-control">
                    <option>All</option>
                </select>
            </div>
            <div className="flex-1">
                <label>Phase</label>
                <select className="form-control">
                    <option>All</option>
                </select>
            </div>
            <div className="flex-1">
                <label>Airport</label>
                <select className="form-control">
                    <option>All</option>
                </select>
            </div>
            <div className="flex-1">
                <label>Airport</label>
                <select className="form-control">
                    <option>All</option>
                </select>
            </div>
            <div className="flex-1">
                <label>Captain</label>
                <select className="form-control">
                    <option>All</option>
                </select>
            </div>
            <div className="flex-1">
                <label>Date</label>
                <div className="d-flex date-range-flds">
                    <input type="date" className="form-control " />
                    <input type="date" className="form-control" placeholder="End Date" />
                </div>
            </div>
        </div>
    );
}

export default DataFilters;