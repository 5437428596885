import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from 'react-hot-toast';
import { useForm, SubmitHandler } from "react-hook-form"
import { URLS } from "../../_config";

export const TenantForm = () => {
    const navigate = useNavigate();
    const userApi = useUserApi();
    const params: any = useParams();
    type Inputs = {
        tenantName: string
        tenantDomain: string
    }

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        reset,
        formState: { errors },
    } = useForm<Inputs>()


    const [tenantsId, setTenantsId] = useState<any>(params?.id); 

    const getTenants = () => {
        userApi.getTenants({ urlParams: { TenantId: tenantsId } }, (message: any, resp: any) => {
            reset({
                tenantName: resp.data.tenantName,
                tenantDomain: resp.data.tenantDomain
            })
        }, (message: any, resp: any) => {
            // Error...
        })

    }

    useEffect(() => {
        if (tenantsId) {
            getTenants()
        }
    }, [tenantsId]);


    const submitData = (data: any) => {
        let formParam = {
            ...data,
            "isActive": true
        };
        if (!tenantsId) {
            return new Promise((resolve, reject) => {
                userApi.tenantSave(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message)
                })
            })
        } else {
            formParam.tenantId = tenantsId;
            return new Promise((resolve, reject) => {
                userApi.tenantUpdate(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message)
                })
            })
        }

    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        toast.promise(
            submitData(data), {
            loading: (!tenantsId) ? 'Creating...' : 'Updating...',
            success: (msg: any) => { navigate(URLS.TENANTS); return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    return (
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><Link to={URLS.DASHBOARD}>Dashboard</Link></li>
                                    <li><Link to={URLS.TENANTS}>Tenants</Link></li>
                                    <li>{(tenantsId > 0) ? 'Edit' : 'Add'} Tenant</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">{(tenantsId > 0) ? 'Edit' : 'Add'} Tenant</h1>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="white-box h-100">
                                <div className="form-area">
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>

                                        <div className="col-md-12">
                                            <label htmlFor="tenantName" className="form-label">Tenant Name</label>
                                            <input   {...register("tenantName", { required: "Tenant name is required" })} type="text" className="form-control" placeholder="Put tenant name" id="tenantName" />
                                            {errors.tenantName && <span className="text-danger">{errors.tenantName.message}</span>}
                                        </div>

                                        <div className="col-md-12">
                                            <label htmlFor="tenantDomain" className="form-label">Tenant Domain</label>
                                            <input type="text"   {...register("tenantDomain", { required: "Tenant domain name is required" })} className="form-control" placeholder="Put tenant domain" id="tenantDomain" />
                                            {errors.tenantDomain && <span className="text-danger">{errors.tenantDomain.message}</span>}
                                        </div>


                                        <div className="col-12 d-flex justify-content-end gap-3">
                                            <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Submit <i className="arrow-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="pt-5">
                                <img src="/../images/idea-pic.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}
