import React from "react";
import { useForm, SubmitHandler } from "react-hook-form"
import { Link, useNavigate } from 'react-router-dom';
import './Auth.scss'
import toast from "react-hot-toast"
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Console } from "console";


const Login = () => {
    const navigate = useNavigate();
    const userApi = useUserApi()

    type Inputs = {
        username: string
        password: string
    }
    
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<Inputs>()

    const submitData = (data:any) =>{        
        return new Promise((resolve,reject)=>{           
            userApi.login(data,(message:any, resp:any)=>{            
                if (resp.success === true ){
                    userApi.getMemberDetails({},(message:any, response:any)=>{            
                        if (response.success === true ){
                            resolve(resp.message);                       
                        } else {
                            reject(response.message);
                        }
                    },(message:any)=>{
                        reject(message);
                        // Error...
                    })     
                    resolve(resp.message);          
                } else {
                    reject(resp.message);
                }
            },(message:any)=>{
                reject(message);
                // Error...
            })           
        })
    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        
        toast.promise(
            submitData(data),{
               loading: 'Logging...',
               success: (msg:any)=>{ navigate('/use-case-library-list'); return <b>{msg}</b>},
               error: (msg:any)=>{return <b>{msg}</b>},
             }
           );
        
    }

    return (
        <div className="login_page">
            <div className="loin_area">
                <div className="login_logo d-flex justify-content-center mb-2">
                    <Link to='#'><img src="./images/logo.png" /></Link>
                </div>
                <h3 className="h3">Welcome!</h3>
                <p>Sign in to your account.</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login_content">
                        <div className="loin_input">
                            <input type="text" {...register("username", { required: "Email Address is required" })} placeholder="Email Address" className="input_box" />
                            <span className="username-icon position-absolute right-0 top-0"></span>
                        </div>
                        {errors.username && <span className="text-danger">{errors.username.message}</span>}
                        <div className="loin_input">
                            <input type="password" {...register("password", { required: "Password is required" })} placeholder="Password" className="input_box" />
                            <span className="password-icon position-absolute right-0 top-0"></span>
                        </div>
                        {errors.password && <span className="text-danger">{errors.password.message}</span>}
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        {/* <div className="remember_txt">
                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">Remember Me</label>
                        </div> */}
                        <p><Link to="/forgot-password" className="text-purple text-sm"><i>Forgot password?</i></Link></p>
                    </div>

                    <div className="d-flex justify-content-center pt-2">
                        <button type="submit" className="btn btn-primary d-flex justify-content-between align-items-center py-2 px-4 gap-2">Sign in <i className="arrow-right"></i></button>
                    </div>
                </form>
                <p className="dont_account mt-3">Don't have an account? <Link to="/signup">Sign Up</Link></p>
            </div>
        </div>
    );
}

export default Login;