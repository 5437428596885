import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import Select from 'react-select'
import Error from '../../components/elements/formFieldError'
import { useUser } from '../../_common/hooks/authHook'
import { URLS } from '../../_config'

export const DomainAddEdit = () => {
    const userInfo: any = useUser();
    const navigate = useNavigate();
    const userApi = useUserApi();
    const { id, domainid } = useParams();
    const paramId: any = id;   
    const [domainData, setDomainData] = useState<any>([]); 
    
console.log('-----------------',id, domainid);


    type Inputs = {
        domainPrefix: string
        domainURL: string
          
    }

    const {
        register,
        control,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
    } = useForm<Inputs>()


  

    const getDomainByID = (id: any) => {

        userApi.companyUrlsDetails({ urlParams: { id: id } }, (message: any, resp: any) => {
            setDomainData(resp.data)
            reset({
                domainPrefix: resp.data.domainPrefix,                
                domainURL: resp.data.domainURL,     
            })
           

        }, (message: any, resp: any) => {
            // Error...
        })
    }

    useEffect(() => {

        if (id) {
            getDomainByID(id)
        }
       

    }, [id]);


   

    const submitDomain = (data: any) => {
        console.log('data',data);
        return new Promise((resolve: any, reject: any) => {
            if (id) {
                data.companyURLID = id;
                data.updatedBy = userInfo?.userID;
                userApi.companyUrlsEdit(data, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message);
                })

            } else {                                
                data.addedBy = userInfo?.userID;
                userApi.companyUrlsAdd(data, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message);
                })
            }
        })
    }

    const submitData = (data: any) => {   
        data.isActive = true; 
        data.fkCompanyID = domainid;
        return new Promise((resolve, reject) => { 
            submitDomain(data).then(resolve).catch(reject)
        })

    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {

        toast.promise(
            submitData(data), {
            loading: 'Loading...',
            success: (msg: any) => { navigate(`/company/domains/${domainid}` ); return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        }
        );
    }
   


    return (
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><Link to={URLS.DASHBOARD}>Dashboard</Link></li>
                                    <li><Link to={URLS.COMPANIES}>Companies</Link></li>
                                    <li><Link to={`${URLS.DOMAINS}/${domainid}`}>Domains</Link></li>
                                    <li>{(paramId > 0) ? 'Edit' : 'Add'} Domain</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">{(paramId > 0) ? 'Edit' : 'Create'} Domain</h1>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="white-box h-100">
                                <div className="form-area">
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>                                         

                                        <div className="col-md-6">
                                            <label className="form-label">Domain Prefix</label>
                                            <input type="text" {...register("domainPrefix")} className="form-control" placeholder="Put the domain prefix" />
                                            <Error error={errors.domainPrefix} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Domain Url</label>
                                            <input type="text" {...register("domainURL", { required: "Domain url is required" })} className="form-control" placeholder="Put the  domain url" />
                                            <Error error={errors.domainURL} />
                                        </div>   

                                        <div className="col-12 d-flex justify-content-end gap-3">
                                            <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Submit <i className="arrow-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="pt-5">
                                <img src="./../images/idea-pic.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}
