import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import { URLS } from '../../_config'
import { useUser } from '../../_common/hooks/authHook'
import Swal from 'sweetalert2'

export const EmployeeRolePermission = () => {

    const { control, register, handleSubmit, reset, getValues, formState: { errors, isValid }, } = useForm()
    const navigate = useNavigate()

    const userInfo: any = useUser();

    const userApi = useUserApi()
    const params: any = useParams();
    const [empId, setEmpId] = useState<any>(params?.id);

    const [roles, setRoles] = useState<Array<any>>([]);
    const [empDetails, setEmpDetails] = useState<any>({});

    const getUserDetails = () => {
        userApi.userDetails({ urlParams: { UserProfID: empId } }, (message: any, resp: any) => {
            setEmpDetails(resp.data);
        }, (message: any, resp: any) => {
            // Error...
        })
    }

    const getRoleList = () => {
        //userInfo?.companyID // this is for fontend user
        // 0 // for supper user
        userApi.listRole({ FKCompanyID: 0 }, (message: any, resp: any) => {
            setRoles(resp.data.roles);
        }, (message: any, resp: any) => {
            // Error...
        })

    }
    useEffect(() => {
        getRoleList();
        getUserDetails()
    }, [empId]);



    const submitData = (data: any) => {
        return new Promise((resolve, reject) => {

            console.log('datadata', data)
            data.FKCompanyID = data.FKCompanyID
            data.FKTenantID = data.FKTenantID


            setTimeout(() => {
                return new Promise((resolve, reject) => {
                    let isPrimary = data.isPrimary;
                    let fkRoleID = data.fkRoleID;
                    let roleArr: any = [];

                    if (fkRoleID.length > 0) {
                        fkRoleID.map((idRole: any) => {
                            let roleData = {
                                fkUserID: data.fkUserID,
                                fkRoleID: idRole,
                                fkCompanyID: data.FKCompanyID,
                                fkTenantID: data.FKTenantID,
                                isPrimary: (isPrimary == idRole) ? true : false,
                                addedBy: userInfo?.userID,
                            }
                            roleArr.push(roleData);
                        })

                    }


                    if (!fkRoleID.includes(isPrimary)) { // If Primary Role not selected in checkbox
                        let roleData = {
                            fkUserID: data.fkUserID,
                            fkRoleID: isPrimary,
                            fkCompanyID: data.FKCompanyID,
                            fkTenantID: data.FKTenantID,
                            isPrimary: true,
                            addedBy: userInfo?.userID,
                        }
                        roleArr.push(roleData);
                    }

                    userApi.userRoleMap(roleArr, (message: any, resp: any) => {
                        console.log('respresp', resp)
                        resolve(message)
                    }, (message: any) => {
                        reject(message)
                    })
                });

            }, 1000)
            //resolve('success')
        })
    }

    const onSubmit = (data: any) => {
        if (data.isPrimary == undefined) {
            Swal.fire({
                title: 'Select Primary Role',
                icon: 'warning',
                confirmButtonColor: '#B32F84',
                confirmButtonText: `OK`
            })
        } else {

            toast.promise(
                submitData(data), {
                loading: 'Saving...',
                success: (msg: any) => {
                    reset();
                    getUserDetails();
                    navigate(URLS.EMPLOYEE_LIST);
                    return <b>{msg}</b>
                },
                error: (msg: any) => { return <b>{msg}</b> },
            });
        }
    }


    return (

        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><Link to={URLS.DASHBOARD}>Dashboard</Link></li>
                                    <li><Link to={URLS.EMPLOYEE_LIST}>Employee List</Link></li>
                                    <li>Employee Role Permission</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">Manage Employee Role Permission</h1>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-8">
                            <div className="white-box">
                                <div className="form-area">
                                    <table className='table'>
                                        <tbody>
                                            <tr>
                                                <th>Employee Full Name</th>
                                                <td> {`${empDetails?.title} ${empDetails?.firstName}  ${empDetails?.lastName}`} </td>
                                            </tr>
                                            <tr>
                                                <th>Email</th>
                                                <td> {`${empDetails?.email}`} </td>
                                            </tr>
                                            <tr>
                                                <th>Phone</th>
                                                <td> {`${(empDetails?.phone) ? empDetails?.phone : '--'}`} </td>
                                            </tr>
                                            <tr>
                                                <th>Current Primary Role</th>
                                                <td> {`${empDetails?.roleName}`} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <hr />

                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)} >
                                        <div className="col-md-12">
                                            <div className="col-md-12 row">

                                                <table className='table'>
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan={3} className='text-center'><u>Select Employee Role</u></th>
                                                        </tr>
                                                        <tr>
                                                            <th>Select</th>
                                                            <th>Role Name</th>
                                                            <th>Primary Role</th>
                                                        </tr>

                                                        {
                                                            (roles && roles.length > 0) &&
                                                            roles.map((item: any) => item?.roleID != 1 &&
                                                                <tr key={Math.random()}>
                                                                    <td>
                                                                        <input type='checkbox' {...register('fkRoleID[]')} value={item?.roleID}
                                                                            defaultChecked={(empDetails.fkRoleID == item?.roleID) ? true : false}
                                                                            title='Add role' />
                                                                    </td>
                                                                    <td>
                                                                        <span>{item?.displayTitle}</span>
                                                                    </td>
                                                                    <td>
                                                                        <input type="radio" {...register('isPrimary')} value={item?.roleID}
                                                                            defaultChecked={(empDetails.fkRoleID == item?.roleID) ? true : false}
                                                                            title='Set Primary Role' />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>


                                            </div>
                                        </div>


                                        <input type="hidden" value={1} {...register('FKCompanyID')} />
                                        <input type="hidden" value={0} {...register('FKTenantID')} />
                                        <input type="hidden" value={1} {...register('FKJobTitleID')} />
                                        <input type="hidden" value={1} {...register('FKWorkForID')} />


                                        <div className="col-12 d-flex justify-content-end gap-3">
                                            <Link to={URLS.EMPLOYEE_LIST} className={`btn btn-info d-flex align-items-center gap-2`} >Back</Link>
                                            <button type="submit" className={`btn btn-primary d-flex align-items-center gap-2`} >Update Employee Role <i className="arrow-right"></i></button>

                                        </div>
                                    </form>
                                </div>
                                {/* <DynamicForm config={config} onSubmitSuccess={()=>{navigate('/employee-list')}}/> */}
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="pt-5">
                                <img src="/../images/idea-pic.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>

    )
}
