import React from "react";

const Pagination = () =>{
    return(
        <div className="pagination-panel">
            <ul className="d-flex justify-content-center">
                <li><a href="#" className="page-nav-arrow-left"></a></li>
                <li className="active"><a href="#">1</a></li>
                <li><a href="#">2</a></li>
                <li><a href="#">3</a></li>
                <li><a href="#">4</a></li>
                <li><a href="#">5</a></li>
                <li><a href="#" className="page-nav-arrow-right"></a></li>
            </ul>
        </div>
    );
}

export default Pagination;