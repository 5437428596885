import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Pagination from "../../components/pagination/Pagination";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from 'react-hot-toast';
import { useForm, SubmitHandler } from "react-hook-form"
import { URLS } from "../../_config";
import Swal from "sweetalert2";
import { useUser } from "../../_common/hooks/authHook";
import { Console } from "console";

export const RoleAddEdit = () => {

    const navigate = useNavigate();
    const userApi= useUserApi();
    const { id } = useParams();
    const paramRole: any = id;
    const userInfo: any = useUser();
    
    type Inputs = {
        title: string
        displayTitle: string
        // fkCompanyID: number
        // fkTenantID: number
    }

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        reset,
        formState: { errors },
    } = useForm<Inputs>()

    const getRoleByID = (id: any) => {
        let param = {
            RoleID: id,
            FKCompanyID: 0
        }

        userApi.detailsRole(param, (message: any, resp: any) => {            
            reset({               
                title: resp.data.title,
                displayTitle: resp.data.displayTitle,
            })            

        }, (message: any, resp: any) => {
            // Error...
        })
    }

    useEffect(() => {
        if (id ) {
            getRoleByID(id)
        }
    }, [id]);

    const submitData = (data:any) =>{     
        let formParam = {                     
            ...data,
            "isPlatform": true
        };   
        return new Promise((resolve,reject)=>{           
            if (id) {
                formParam.roleID = id;
                userApi.editRole(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message);
                })

            } else {

                userApi.addRole(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message);
                })
            }           
        })
    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {       
       
        toast.promise(
            submitData(data),{
               loading: 'Loading...',
               success: (msg:any)=>{ navigate('/role/list'); return <b>{msg}</b>},
               error: (msg:any)=>{return <b>{msg}</b>},
             }
           );
    }
   

    return (
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><Link to={URLS.DASHBOARD}>Dashboard</Link></li>
                                    <li><Link to={URLS.ROLES}>Roles</Link></li>
                                    <li>{( paramRole > 0) ? 'Edit' : 'Add'} Role</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">{(paramRole > 0) ? 'Edit' : 'Add'} Role</h1>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="white-box h-100">
                                <div className="form-area">
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>

                                        <div className="col-md-6">
                                            <label htmlFor="rolename" className="form-label">Role Name</label>
                                            <input {...register("title", { required: "Role name is required" })} type="text" className="form-control" placeholder="Put role name" id="rolename" />
                                            {errors.title && <span className="text-danger">{errors.title.message}</span>}
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="roledisplayname" className="form-label">Role Display Name</label>
                                            <input type="text" {...register("displayTitle", { required: "Display name is required" })} className="form-control" placeholder="Put display name" id="roledisplayname" />
                                            {errors.displayTitle && <span className="text-danger">{errors.displayTitle.message}</span>}
                                        </div>

                                        {/* <div className="col-md-6">
                                            <label htmlFor="tenant" className="form-label">Tenant</label>
                                            <select className="form-control" {...register("fkTenantID", { required: "Tenant is required" })} id="tenant">
                                                <option value=''>---- All Tenant ----</option>
                                                <option value={0}>Tenant -1 </option>
                                            </select>
                                            {errors.fkTenantID && <span className="text-danger">{errors.fkTenantID.message}</span>}
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="company" className="form-label">Company</label>
                                            <select className="form-control" {...register("fkCompanyID", { required: "Company is required" })} id="company">
                                                <option value=''>---- All Company ----</option>
                                                <option value={0}>DTS</option>
                                            </select>
                                            {errors.fkCompanyID && <span className="text-danger">{errors.fkCompanyID.message}</span>}
                                        </div> */}

                                        <div className="col-12 d-flex justify-content-end gap-3">
                                            <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Submit <i className="arrow-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="pt-5">
                                <img src="../images/idea-pic.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}
