import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { Link } from 'react-router-dom'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import Swal from 'sweetalert2'
import { toast } from 'react-hot-toast'
import { useUser } from '../../_common/hooks/authHook'
import { Dropdown } from 'react-bootstrap';
import { TbTrashXFilled } from "react-icons/tb";
import { MdModeEditOutline } from "react-icons/md";

import { MdOutlineModeEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";

export const CompanyList = () => {

    const [allCompanyList, setAllCompanyList] = useState<Array<any>>([]);
    const userApi = useUserApi();
    const userInfo: any = useUser();

    const getCompanyList = () => {
        userApi.companyList({ urlParams: { index: 1, size: 10 } }, (message: any, resp: any) => {
            setAllCompanyList(resp.data.companies);
        }, (message: any, resp: any) => {
            // Error...
        })
    }

    useEffect(() => {
        getCompanyList()

    }, []);

    const deleteData = (data: any) => {
        let formParam = {
            "companyID": data.companyID,
            "updatedBy": userInfo?.userID,
        };
        return new Promise((resolve, reject) => {
            userApi.companyDelete(formParam, (message: any, resp: any) => {
                if (resp.success === true) {

                    resolve(resp.message);
                } else {
                    reject(resp.message);
                }
            }, (message: any, resp: any) => {
                // Error...
            })
        })
    }

    const deleteItem = (companyID: number) => {
        let data = {
            "companyID": companyID,
        };

        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this company?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    deleteData(data), {
                    loading: 'Loading...',
                    success: (msg: any) => { getCompanyList(); return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                }
                );

            }
        });
    }
    

    const statusData = (data: any) => {
        let formParam = {
            "updatedBy": userInfo?.userID,
            "companyID": data.companyID,
            "isActive": data.isActive
        };

        return new Promise((resolve, reject) => {
            userApi.companyStatusUpdate(formParam, (message: any, resp: any) => {
                if (resp.success === true) {

                    resolve(resp.message);
                } else {
                    reject(resp.message);
                }
            }, (message: any, resp: any) => {
                // Error...
            })
        })
    }

    const statusChange = (status: boolean, companyID: number) => {
        let msg = status === false ? 'In-Active' : 'Active';
        let data = {

            "companyID": companyID,
            "isActive": status
        };

        Swal.fire({
            title: 'Confirm Status',
            text: `Are you sure you want to ${msg} this company?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, ${msg} it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    statusData(data), {
                    loading: 'Loading...',
                    success: (msg: any) => { getCompanyList(); return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                }
                );

            }
        });


    }

    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-2">
                        <div className="row align-items-center">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex gap-3 mb-2 p-0">
                                    <li><Link to="#">Dashboard</Link></li>
                                    <li>Company</li>
                                </ul>
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">Company</h1>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="two-panel-wrapper ">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <h1 className="h5 mb-0 font-weight-600"></h1>
                                                <Link to="/company/create" className="btn btn-info py-2 add-more-files-btn">Create Company</Link>
                                            </div>
                                            <div className="right-panel">
                                                <div className="white-box">
                                                    <div className="table-responsive list-table full-width">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr className="align-middle">
                                                                    <th></th>
                                                                    <th scope="col">Company Name</th>
                                                                    <th scope="col">Address</th>
                                                                    <th scope="col">City</th>
                                                                    <th scope="col">Phone Number</th>
                                                                    <th scope="col">Email</th>
                                                                    <th scope="col" className="w-300px">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Array.isArray(allCompanyList) && allCompanyList.length > 0 ? (
                                                                    allCompanyList.map((company) => (
                                                                        <tr className="align-middle h-60" key={company.companyID}>
                                                                            <td></td>
                                                                            <td>{company.companyName}</td>
                                                                            <td>{company.address ? company.address : '--'}</td>
                                                                            <td>{company.city ? company.city : '--'}</td>
                                                                            <td>{company.phone ? company.phone : '--'}</td>
                                                                            <td>{company.email ? company.email : '--'}</td>
                                                                            <td>
                                                                                <div className="d-flex gap-3 align-items-center">
                                                                                    <Link className='react-icon-grey' to={`/company/${company.companyID}`}><MdOutlineModeEdit/></Link>
                                                                                    <Link className='react-icon-grey delete' to="#" onClick={() => { deleteItem(company.companyID) }}><MdDeleteOutline/></Link>
                                                                                    {company.isActive === true ? (
                                                                                        <Link to="#" onClick={() => { statusChange(false, company.companyID) }}><i className="active-icon"></i></Link>
                                                                                    ) : (
                                                                                        <Link to="#" onClick={() => { statusChange(true, company.companyID) }}><i className="in-active"></i></Link>
                                                                                    )}

                                                                                    <Dropdown>
                                                                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='text-white'>
                                                                                            Settings
                                                                                        </Dropdown.Toggle>

                                                                                        <Dropdown.Menu>
                                                                                            <Dropdown.Item as={Link} to={`/company/domains/${company.companyID}`}>Domains</Dropdown.Item>
                                                                                            <Dropdown.Item as={Link} to={`/company/contacts/${company.companyID}`}>Contacts</Dropdown.Item>                                                                                            
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>


                                                                                </div>


                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr className="align-middle h-60">
                                                                        <td colSpan={7} className='text-center'>Data Not Found!</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}
