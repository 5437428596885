import { combineReducers } from 'redux'
import loaderReducer from './common/loaderReducer'
 import userReducer from './userReducer'
/*import sidebarReducer from './common/sidebarReducer'
import columnReducer from './common/columnReducer'
import signupReducer from './user/signupReducer'
import onboardingReducer from './merchant/onboardingReducer'
import sitemasterReducer from './common/sitemasterReducer'
import activeKeyReducer from './merchant/activeKeyReducer'
import merchantDetailsReducer from './merchant/merchantDetailsReducer'
import hppReducer from './hpp/hppReducer'

import invoicePreviewReducer from './invoice/invoicePreviewReducer' */

import { ACTIONS } from '../../_config'

// const rootReducer = combineReducers({
const appReducer = combineReducers({
  loader: loaderReducer,
  user: userReducer,
  /* sidebar: sidebarReducer,
  columns: columnReducer,
  signup: signupReducer,
  onboarding: onboardingReducer,
  sitemaster: sitemasterReducer,
  activekey: activeKeyReducer,
  merchantDetails: merchantDetailsReducer,
  hpp: hppReducer,
  invoicePreview: invoicePreviewReducer, */
})

const rootReducer = (state:any, action:any) => {
  if (action.type === ACTIONS.RESET_REDUCER) {
    // Reset the state to its initial values
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer