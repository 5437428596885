import React from "react";
import { Link } from 'react-router-dom'; 

const LeftPanel = () =>{
    return(
        <div className="left-panel">
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button d-flex align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <span className="menu-icon">
                        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="email-outline 1">
                            <path id="Vector" d="M12.1809 3.25008C12.1809 2.65425 11.6934 2.16675 11.0975 2.16675H2.43087C1.83503 2.16675 1.34753 2.65425 1.34753 3.25008V9.75008C1.34753 10.3459 1.83503 10.8334 2.43087 10.8334H11.0975C11.6934 10.8334 12.1809 10.3459 12.1809 9.75008V3.25008ZM11.0975 3.25008L6.7642 5.95841L2.43087 3.25008H11.0975ZM11.0975 9.75008H2.43087V4.33341L6.7642 7.04175L11.0975 4.33341V9.75008Z" fill="#C964A5"/>
                            </g>
                        </svg>
                        </span> Hanger
                    </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <ul className="submenu">
                        <li><Link to="/discovery">Discovery</Link></li>
                        <li><Link to="/challenge-statement">Challenge Statement</Link></li>
                        <li><Link to="/solution-framing">Solution Framing</Link></li>
                        <li><Link to="/ranking-prioritization">Ranking and Prioritization</Link></li>
                        <li><Link to="/hanger-decision">Hanger Decision</Link></li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span className="menu-icon">
                            <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="star-outline 1">
                                <path id="Vector" d="M6.7642 8.33617L4.72753 9.56575L5.26378 7.24742L3.46545 5.68742L5.83795 5.487L6.7642 3.29867L7.69045 5.487L10.063 5.68742L8.26462 7.24742L8.80087 9.56575L6.7642 8.33617ZM12.1809 5.00492L8.28628 4.6745L6.7642 1.08325L5.24212 4.6745L1.34753 5.00492L4.29962 7.567L3.4167 11.3749L6.7642 9.3545L10.1117 11.3749L9.22337 7.567L12.1809 5.00492Z" fill="#BCC1DA"/>
                                </g>
                            </svg> 
                        </span>
                        Runway
                    </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <ul className="submenu">
                        <li><Link to="/project-plan">Project Plan</Link></li>
                        <li><Link to="/procurement-plan">Procurement Plan</Link></li>
                        <li><Link to="/test-plan">Test Plan</Link></li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <span className="menu-icon">
                            <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="diamond-stone 1">
                                <path id="Vector" d="M8.93087 4.87492H10.5559L7.84753 8.66659L8.93087 4.87492ZM5.68087 4.87492H7.84753L6.7642 9.20825L5.68087 4.87492ZM2.97253 4.87492H4.59753L5.68087 8.66659L2.97253 4.87492ZM8.3892 2.16659H9.47253L10.5559 3.79159H8.93087L8.3892 2.16659ZM6.22253 2.16659H7.30587L7.84753 3.79159H5.68087L6.22253 2.16659ZM4.05587 2.16659H5.1392L4.59753 3.79159H2.97253L4.05587 2.16659ZM3.5142 1.08325L1.34753 4.33325L6.7642 11.9166L12.1809 4.33325L10.0142 1.08325H3.5142Z" fill="#BCC1DA"/>
                                </g>
                            </svg>   
                        </span>
                        In the Air
                    </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <ul className="submenu">
                            <li><Link to="/in-field-testing">In-Field Testing</Link></li>
                            <li><Link to="/day-in-the-life">Day in the Life </Link></li>
                            <li><Link to="/field-acceptance">Field Assistance</Link></li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        <span className="menu-icon">
                            <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="file-outline 1">
                                <path id="Vector" d="M7.84745 1.08325H3.51412C3.2268 1.08325 2.95125 1.19739 2.74809 1.40055C2.54492 1.60372 2.43079 1.87927 2.43079 2.16659V10.8333C2.43079 11.1206 2.54492 11.3961 2.74809 11.5993C2.95125 11.8024 3.2268 11.9166 3.51412 11.9166H10.0141C10.3014 11.9166 10.577 11.8024 10.7802 11.5993C10.9833 11.3961 11.0975 11.1206 11.0975 10.8333V4.33325L7.84745 1.08325ZM10.0141 10.8333H3.51412V2.16659H7.30579V4.87492H10.0141V10.8333Z" fill="#BCC1DA"/>
                                </g>
                            </svg>                                                        
                        </span>
                        Landed
                        </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        <ul className="submenu">
                            <li><Link to="/final-go-nogo-decision">Go/No Go</Link></li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>

            <a href="#" className="collapse-arrow"><i className="collapse-arrow-icon"></i></a>
        </div>
    );
}

export default LeftPanel;