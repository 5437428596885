import React, { useEffect, useState } from "react";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import './Table.scss';


const Table = () => {

    const [useCaseList, setUseCaseList] = useState<Array<any>>([]);
    const userApi = useUserApi();

    const getUseCaseList = () => {
        userApi.useCaseList({}, (message: any, resp: any) => {
            //console.log(resp.data)
            setUseCaseList(resp.data);
        }, (message: any, resp: any) => {
            // Error...
        })

    }

    useEffect(() => {
        getUseCaseList()

    }, []);

    const formatDate = (dateString: any) => {
        const options: object = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    }

    return (
        <div className="table-responsive list-table full-width">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" className="w-5 text-center">Use Case ID</th>
                        <th scope="col"></th>
                        <th scope="col">Use Case Name</th>
                        <th scope="col">Airport</th>
                        <th scope="col">Captain</th>
                        <th scope="col">Phase</th>
                        <th scope="col">Rank</th>
                        <th scope="col">Likes</th>
                        <th scope="col">Strategic Value</th>
                        <th scope="col">Section</th>
                        <th scope="col">Submission Date</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>


                    {useCaseList && useCaseList.length > 0 ? (
                        useCaseList.map((usecase) => (
                            <tr key={usecase.useCaseID}>
                                <th className="text-center">{usecase.useCaseID}</th>
                                <td>
                                    <div className="table-pic">
                                        <Link to='#'>
                                            <img src={usecase.imgURL} alt="" className="table-pic-img" />
                                        </Link>
                                    </div>
                                </td>
                                <td className="use-case-name">
                                    <h6>
                                        <Link to={`/use-case/${usecase.useCaseID}`} className="text-dark">
                                            {usecase.useCase}
                                        </Link>
                                    </h6>
                                    <p>
                                        {/* {usecase.addedBy} */}
                                        Tanmay Mandal
                                    </p>
                                </td>
                                <td>{usecase.airPort ? usecase.airPort : '--'}</td>
                                <td>{usecase.captain ? usecase.captain : '--'}</td>
                                <td>{usecase.currentPhase ? usecase.currentPhase : '--'}</td>
                                <td>{usecase.rank ? usecase.rank : '--'}</td>
                                <td>{usecase.likes ? usecase.likes : '--'}</td>
                                <td>{usecase.strategicValue ? usecase.strategicValue : '--'}</td>
                                <td>{usecase.section ? usecase.section : '--'}</td>
                                <td>{formatDate(usecase.addedDate)}</td>
                                <td>
                                    <span className={`tags tags-${usecase.status.toLowerCase()}`}>
                                        {usecase.status}
                                    </span>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr className="align-middle h-60">
                            <td colSpan={12}>Data Not Found!</td>
                        </tr>
                    )}

                </tbody>
            </table>
        </div>
    );
}

export default Table;