import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import LeftPanel from "../components/leftPanel/LeftPanel";

const Discovery = () =>{
    return(
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-2">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <ul className="csmt-breadcrumb d-flex gap-3 mb-2 p-0">
                                    <li><a href="#">Dashboard</a></li>
                                    <li>Use case librery</li>
                                </ul>
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">The hanger</h1>
                                
                            </div>
                            <div className="col-sm-6">
                                <div className="d-flex justify-content-end">
                                    <img src="assets/images/time.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="two-panel-wrapper d-flex">
                                <LeftPanel></LeftPanel>
                                <div className="right-panel">
                                    <div className="progress-steps-panel d-flex mb-4">
                                        <div className="progress-steps active d-flex align-items-center gap-3">
                                            <div className="progress-number">1</div>
                                            <p>Discovery</p>
                                        </div>
                                        <div className="progress-steps d-flex align-items-center gap-3">
                                            <div className="progress-number">2</div>
                                            <p>Challenge Statement</p>
                                        </div>
                                        <div className="progress-steps d-flex align-items-center gap-3">
                                            <div className="progress-number">3</div>
                                            <p>Solutions Framing</p>
                                        </div>
                                        <div className="progress-steps  d-flex align-items-center gap-3">
                                            <div className="progress-number">4</div>
                                            <p>Scoring / Prioritization</p>
                                        </div>
                                        <div className="progress-steps  d-flex align-items-center gap-3">
                                            <div className="progress-number">5</div>
                                            <p>Decision</p>
                                        </div>
                                    </div>
                                    <div className="white-box p-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h1 className="h5 mb-0 font-weight-600">Discovery</h1>
                                            <a href="#" className="btn btn-info py-2 add-more-files-btn">Add more files</a>
                                        </div>
                                    </div>
                                    <div className="light-blue-bg files-add-panel">
                                        <div className="row form-area">
                                            <div className="col-sm-12">
                                                <div className="d-flex justify-content-end">
                                                    <a href="#" className="close-btn"><i className="close-icon"></i></a>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <label>Title</label>
                                                <input type="text" className="form-control mb-3" placeholder="Enter file name" />
                                                <div className="custom-file-uploader mb-2">
                                                    <input type="file" />
                                                    <i className="file-upload-icon"></i>
                                                    <p className="mb-0">Drop files here or click to upload.</p>
                                                </div>
                                                <div className="uploaded-files d-flex w-100 gap-3">
                                                    <span className="uploaded-file-icon"><img src="assets/images/pdf.svg" alt="" /></span>
                                                    <div className="file-upload-progress flex-grow-1">
                                                        <div className="d-flex justify-content-between">
                                                            <p>Energy Efficiency & Demand Response (100%)</p>
                                                            <p className="green-text">Completed</p>
                                                        </div>
                                                        <div className="file-upload-progress-bar">
                                                            <div className="file-upload-progress-percent w-100"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <label>Note</label>
                                                <textarea className="form-control" cols={4} rows={10}></textarea>
                                            </div>
                                            <div className="col-12 d-flex justify-content-end gap-3 mt-4">
                                                <button type="submit" className="btn btn-primary d-flex align-items-center gap-2">Save as Draft <i className="arrow-right"></i></button>
                                            <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Submit <i className="arrow-right"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white-box py-3 px-3">
                                        <div className="table-responsive list-table">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="w-5">#</th>
                                                        <th scope="col">Title</th>
                                                        <th scope="col">File name</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Weight</th>
                                                        <th scope="col">Uploaded at</th>
                                                        <th scope="col">Operations</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <div className="number-avatar">D</div>
                                                        </th>
                                                        <td>
                                                            <h6>SVP, Energy Delivery IOU...</h6>
                                                            <p>Bob Taylor</p>
                                                        </td>
                                                        <td>Energy Efficiency & 
                                                            Demand Response
                                                        </td>
                                                        <td>pdf</td>
                                                        <td>5.83kb</td>
                                                        <td>2022-02-08  15:31</td>
                                                        <td>
                                                            <div className="d-flex gap-3">
                                                                <a href="#"><i className="edit-icon"></i></a>
                                                                <a href="#"><i className="delete-icon"></i></a>
                                                                <a href="#"><i className="upload-icon"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            <div className="number-avatar">D</div>
                                                        </th>
                                                        <td>
                                                            <h6>SVP, Energy Delivery IOU...</h6>
                                                            <p>Bob Taylor</p>
                                                        </td>
                                                        <td>Energy Efficiency & 
                                                            Demand Response
                                                        </td>
                                                        <td>pdf</td>
                                                        <td>5.83kb</td>
                                                        <td>2022-02-08  15:31</td>
                                                        <td>
                                                            <div className="d-flex gap-3">
                                                                <a href="#"><i className="edit-icon"></i></a>
                                                                <a href="#"><i className="delete-icon"></i></a>
                                                                <a href="#"><i className="upload-icon"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            <div className="number-avatar">D</div>
                                                        </th>
                                                        <td>
                                                            <h6>SVP, Energy Delivery IOU...</h6>
                                                            <p>Bob Taylor</p>
                                                        </td>
                                                        <td>Energy Efficiency & 
                                                            Demand Response
                                                        </td>
                                                        <td>pdf</td>
                                                        <td>5.83kb</td>
                                                        <td>2022-02-08  15:31</td>
                                                        <td>
                                                            <div className="d-flex gap-3">
                                                                <a href="#"><i className="edit-icon"></i></a>
                                                                <a href="#"><i className="delete-icon"></i></a>
                                                                <a href="#"><i className="upload-icon"></i></a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <Footer></Footer>
        </React.Fragment>
    );
}

export default Discovery;