
import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Pagination from "../../components/pagination/Pagination";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { useDispatch } from "react-redux";
import { Link,  useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import toast from 'react-hot-toast';
import { useUser } from "../../_common/hooks/authHook";
import { URLS } from "../../_config";

import { MdOutlineModeEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";


export const RoleList = () => {

    const [roles, setRoles] = useState<Array<any>>([]);
    const userApi = useUserApi();
    const userInfo: any = useUser();
   
   

    const getRoleList = () => {
        //userInfo?.companyID // this is for fontend user
        // 0 // for supper user
        userApi.listRole({FKCompanyID: 0}, (message: any, resp: any) => {
            setRoles(resp.data.roles);
        }, (message: any, resp: any) => {
            // Error...
        })

    }

    useEffect(() => {
        getRoleList()

    }, []);

   

    const deleteData = (data: any) => {
        let formParam = {
            "roleID": data.roleID,
            "fkCompanyID": 0,
        };
        return new Promise((resolve, reject) => {
            userApi.deleteRole(formParam, (message: any, resp: any) => {
                resolve(message);
            }, (message: any, resp: any) => {
                reject(message);
            })
        })
    }

    const deleteItem = (roleID: number) => {
        let data = {
            "roleID": roleID,            
        };

        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this role?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    deleteData(data), {
                    loading: 'Loading...',
                    success: (msg: any) => { getRoleList(); return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                }
                );

            }
        });
    }


    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><Link to={URLS.DASHBOARD}>Dashboard</Link></li>
                                    <li>Roles</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">Roles</h1>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">


                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h1 className="h5 mb-0 font-weight-600"></h1>

                                <Link to="/role/create" className="btn btn-info py-2 add-more-files-btn"> Add Role</Link>
                            </div>

                            <div className="white-box px-4 pt-3">
                                <div className="table-responsive list-table full-width">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr className="align-middle">
                                                <th scope="col">Name</th>
                                                <th scope="col">Dispaly Name</th>
                                                <th scope="col" className="w-150px">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {roles && roles.length > 0 ? (
                                                roles.map((role) => (
                                                    <tr key={role.roleID} className="align-middle h-60">
                                                        <td >{role.title}</td>
                                                        <td>{role.displayTitle}</td>
                                                        <td>
                                                            <div className="d-flex gap-3">
                                                                <Link to={`/role/${role.roleID}`}  className="react-icon-grey">
                                                                    <MdOutlineModeEdit />
                                                                </Link>
                                                                <Link to="#" onClick={() => { deleteItem(role.roleID) }}  className="react-icon-grey"><MdDeleteOutline /></Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr className="align-middle h-60">
                                                    <td colSpan={6}>Data Not Found!</td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </div>

                                {/* <div className="pagination-panel">
                                    <ul className="d-flex justify-content-center">
                                        <li><a href="#" className="page-nav-arrow-left"></a></li>
                                        <li className="active"><a href="#">1</a></li>
                                        <li><a href="#">2</a></li>
                                        <li><a href="#">3</a></li>
                                        <li><a href="#">4</a></li>
                                        <li><a href="#">5</a></li>
                                        <li><a href="#" className="page-nav-arrow-right"></a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>
    )
}
