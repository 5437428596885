import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const PreiodComprisonchatDashboard = () =>{
    return(
      <React.Fragment>
        <Header></Header>

        <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row align-items-center">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex gap-3 mb-2 p-0">
                                    <li><a href="#">Dashboard</a></li>
                                    <li>Executive summary</li>
                                </ul>
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">Executive summary dashboard</h1>
                                
                            </div>
                            
                        </div>
                    </div>
                    <div className="table-filter-panel mb-4 d-flex gap-3">
                        <div className="flex-1">
                            <label>Section</label>
                            <select className="form-control">
                                <option>All</option>
                            </select>
                        </div>
                        <div className="flex-1">
                            <label>Phase</label>
                            <select className="form-control">
                                <option>All</option>
                            </select>
                        </div>
                        <div className="flex-1">
                            <label>Airport</label>
                            <select className="form-control">
                                <option>All</option>
                            </select>
                        </div>
                        <div className="flex-1">
                            <label>Captain</label>
                            <select className="form-control">
                                <option>All</option>
                            </select>
                        </div>
                        <div className="flex-1">
                            <label>Runway</label>
                            <select className="form-control">
                                <option>All</option>
                            </select>
                        </div>
                        <div className="flex-1">
                            <label>Date</label>
                            <div className="d-flex">
                                <input className="form-control borderradius_startdate" type="date" placeholder="Start Date"/>
                                <input className="form-control borderradius_enddate" type="date" placeholder="End Date"/>
                            </div>
                            
                        </div>
                    </div>

                    <div className=" executive-dashboard">
                        <div className="dashboard-top-part mb-5">
                            <div className="row">
                                <div className="col-md-8 offset-md-4">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="preiod_txt"><span>Period 1 </span>: 1/1/2022 to 12/31/2022</div>
                                        <div  className="preiod_txt"><span>Period 2</span> : 1/1/2023 to 7/4/2023</div>
                                        <div className="priod_toggle">
                                            <div className="form-check form-switch form-switch-md">
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked />
                                              </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="dashboard-top-part-body flex-1">
                                        <h3>avg duration comparison by period</h3>
                                        <div>
                                            <img src="../images/graph_05.jpg"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    
                                    <div className="dashboard-top-part-body flex-1">
                                        <h3>Avg duration comparison by section</h3>
                                        <div>
                                            <img src="../images/graph_06.jpg"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="dashboard-top-part-body flex-1 p-0 mt-3">
                                        <div className="table-responsive">
                                            <table className="table priod_table">
                                            <thead>
                                                <tr>
                                                  <th>&nbsp;</th>
                                                  <th>Section</th>
                                                  <th>Period 1</th>
                                                  <th>Period 2</th>
                                                  <th>% Change</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <input type="checkbox"/>
                                                  </td>
                                                  <td>4 - landed</td>
                                                  <td>26.27</td>
                                                  <td>0.00</td>
                                                  <td>-100.00%</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        &nbsp;
                                                      </td>
                                                    <td>
                                                        <div>Final decision</div>
                                                        <div>Value proposition</div>
                                                    </td>
                                                    <td>
                                                        <div>303.00</div>
                                                        <div>6.50</div>
                                                    </td>
                                                    <td>
                                                        <div>0.00</div>
                                                        <div>0.00</div>
                                                    </td>
                                                    <td>
                                                        <div>-100.00%</div>
                                                        <div>-100.00%</div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <input type="checkbox"/>
                                                    </td>
                                                    <td>3 - in-the-air</td>
                                                    <td>0.00</td>
                                                    <td>0.09</td>
                                                    <td>0.00%</td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                        &nbsp;
                                                      </td>
                                                    <td>
                                                        <div>field acceptance </div>
                                                        <div>day in the life feedback</div>
                                                        <div>in-field testing</div>
                                                    </td>
                                                    <td>
                                                        <div>0.00</div>
                                                        <div>0.00</div>
                                                        <div>0.00</div>
                                                    </td>
                                                    <td>
                                                        <div>0.00</div>
                                                        <div>0.00</div>
                                                        <div>4.00</div>
                                                    </td>
                                                    <td>
                                                        <div>0.00%</div>
                                                        <div>0.00%</div>
                                                        <div>0.00%</div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <input type="checkbox"/>
                                                    </td>
                                                    <td>2 - runway</td>
                                                    <td>80.59</td>
                                                    <td>145.29</td>
                                                    <td>80.27%</td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      &nbsp;
                                                    </td>
                                                    <td>
                                                        <div>Test plan </div>
                                                        <div>procurement</div>
                                                        <div>project plan</div>
                                                        <div>security review</div>
                                                        <div>solution framing</div>
                                                    </td>
                                                    <td>
                                                        <div>233.00    </div>
                                                        <div>0.00</div>
                                                        <div>17.15</div>
                                                        <div>55.64</div>
                                                        <div>71.11</div>
                                                    </td>
                                                    <td>
                                                        <div>259.99</div>
                                                        <div>0.00</div>
                                                        <div>20.00</div>
                                                        <div>152.51</div>
                                                        <div>0.00</div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            11.59%
                                                        </div>
                                                        <div>0.00%</div>
                                                        <div>16.65%</div>
                                                        <div>174.09%</div>
                                                        <div>-100.00%</div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <input type="checkbox"/>
                                                    </td>
                                                    <td>1 - hanger</td>
                                                    <td>133.19</td>
                                                    <td>174.26</td>
                                                    <td>30.84%</td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      &nbsp;
                                                    </td>
                                                    <td>
                                                        <div>Challenge Statement </div>
                                                    </td>
                                                    <td>
                                                        <div>10.85</div>
                                                    </td>
                                                    <td>
                                                        <div>0.00</div>
                                                    </td>
                                                    <td>
                                                        <div>-100.00%</div>
                                                    </td>

                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      &nbsp;
                                                    </td>
                                                    <td>
                                                        Total
                                                    </td>
                                                    <td>
                                                        111.90
                                                    </td>
                                                    <td>
                                                      159.23
                                                    </td>
                                                    <td>
                                                        42.30%
                                                    </td>
                                                    
                                                  </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    
                                    <div className="dashboard-top-part-body flex-1 mt-3">
                                        <h3>Avg duration comparison by phase</h3>
                                        <div>
                                            <img src="../images/graph_07.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       

 

                    </div>
                </div>
            </div>

          
        <Footer></Footer>
        </React.Fragment>
    );
}

export default PreiodComprisonchatDashboard;