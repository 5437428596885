import React from "react";
import { Container, Dropdown, NavDropdown, Navbar } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import { Link, useNavigate } from "react-router-dom";
import './header.scss'
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { useUser } from "../../_common/hooks/authHook";
import { URLS } from "../../_config";


import { SlPeople } from "react-icons/sl";
import { MdOutlineSettings } from "react-icons/md";
import { TbReport, TbUsersPlus } from "react-icons/tb";
import { TbBulb } from "react-icons/tb";
import { GrDocumentText } from "react-icons/gr";
import { GiAirplaneDeparture } from "react-icons/gi";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { MdLocalAirport } from "react-icons/md";

const Header = () => {

    const navigate = useNavigate();
    const userApi = useUserApi();
    const userInfo: any = useUser();
    //console.log(userInfo?.firstName)
    const logout = () => {
        userApi.logout({}, (message: any, resp: any) => {
            navigate("/");
        }, (message: any) => {
            // Error...
        })


    }
    return (
        <div className="position-sticky top-0 left-0 right-0 sticky-nav">
            <nav className="navbar bg-dark bg-dark-cutom navbar-expand-lg" data-bs-theme="dark">
                <div className="container">
                    <a className="navbar-brand" href="#">
                        <img src="/../images/logo-white.svg" alt="" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="d-flex gap-4 for-mob">
                        <div className="dropdown notification-dropdown">
                            <a href="#" data-bs-toggle="dropdown" aria-expanded="false" className="dropdown-toggle"><i className="bell-icon"></i></a>
                            <div className="dropdown-menu dropdown-menu-end ">
                                <h6 className="text-uppercase font-weight-700 mt-3 mb-3 text-center text-dark">Notification</h6>
                                <hr />
                                <div className="notification-body">
                                    <div className="d-flex align-items-center justify-content-between mark-text mb-4">
                                        <span className="text-uppercase">Yestarday</span>
                                        <span>Mark as all read</span>
                                    </div>
                                    <div className="flex-grow-1">
                                        <div className="notification-body-text mb-4">
                                            <div className="notification-body-pic"><div className="number-avatar">JD</div></div>
                                            <div className="notification-body-inner-text">
                                                <span className="d-block"><strong>John Doe changed an issue fromi in Progress to Done</strong> 15 hours ago</span>
                                                <span className="d-block mt-1">Activity Diagram of Runway Section<br /><small>IWS-81.Done</small></span>
                                                <a href="#"><span>JD</span> +1 comment from John Doe</a>
                                            </div>
                                            <div className="notification-body-unread-dot"><span></span></div>
                                        </div>
                                        <div className="notification-body-text mb-4">
                                            <div className="notification-body-pic"><div className="number-avatar">JD</div></div>
                                            <div className="notification-body-inner-text">
                                                <span className="d-block"><strong>John Doe changed an issue fromi in Progress to Done</strong> 15 hours ago</span>
                                                <span className="d-block mt-1">Activity Diagram of Runway Section<br /><small>IWS-82.Done</small></span>
                                                <a href="#"><span>JD</span> +1 comment from John Doe</a>
                                            </div>
                                            <div className="notification-body-unread-dot"><span></span></div>
                                        </div>
                                        <div className="notification-body-text mb-4">
                                            <div className="notification-body-pic"><div className="number-avatar">JD</div></div>
                                            <div className="notification-body-inner-text">
                                                <span className="d-block"><strong>John Doe changed an issue fromi in Progress to Done</strong> 15 hours ago</span>
                                                <span className="d-block mt-1">Activity Diagram of Runway Section<br /><small>IWS-83.Done</small></span>
                                                <a href="#"><span>JD</span> +1 comment from John Doe</a>
                                            </div>
                                            <div className="notification-body-unread-dot"><span></span></div>
                                        </div>
                                    </div>
                                    <div className="unread-notify notification-body-inner-text text-center mt-4">
                                        <span>That's all your <strong>unread</strong> notification<br />from the last 30 days.</span>
                                        <button type="button" className="btn btn-primary w-100 text-center mt-2">Clear All</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dropdown">
                            <a href="#" className="d-flex gap-2 align-items-center user-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className="top-avatar">
                                    <img src="../images/user-image.png" alt="" />
                                </div>
                                <span>Henry</span>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end top-dropdown">
                                <li><a className="dropdown-item" href="#">Profile</a></li>
                                <li><a className="dropdown-item text-purple" href="#">Logout</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                        <div className="navbar-nav me-auto mb-2 mb-lg-0 gap-4">
                        </div>
                        {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0 gap-4 d-none">
                            <li className="nav-item">
                                <a className="nav-link active d-flex align-items-center gap-2" aria-current="page" href="#"><i className="home-icon"></i> Dashboard</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link d-flex align-items-center gap-2" href="#"><i className="airport-icon"></i> Airport</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link d-flex align-items-center gap-2" href="#"><i className="usecase-icon"></i> Use Case</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link d-flex align-items-center gap-2" href="#"><i className="report-icon"></i> Reports</a>
                            </li>
                        </ul> */}
                        <div className="d-flex gap-4">
                            <div className="dropdown notification-dropdown">
                                <a href="#" data-bs-toggle="dropdown" aria-expanded="false" className="dropdown-toggle"><i className="bell-icon"></i></a>
                                <div className="dropdown-menu dropdown-menu-end ">
                                    <h6 className="text-uppercase font-weight-700 mt-3 mb-3 text-center text-dark">Notification</h6>
                                    <hr />
                                    <div className="notification-body">
                                        <div className="d-flex align-items-center justify-content-between mark-text mb-4">
                                            <span className="text-uppercase">Yestarday</span>
                                            <span>Mark as all read</span>
                                        </div>
                                        <div className="flex-grow-1">
                                            <div className="notification-body-text mb-4">
                                                <div className="notification-body-pic"><div className="number-avatar">JD</div></div>
                                                <div className="notification-body-inner-text">
                                                    <span className="d-block"><strong>John Doe changed an issue fromi in Progress to Done</strong> 15 hours ago</span>
                                                    <span className="d-block mt-1">Activity Diagram of Runway Section<br /><small>IWS-81.Done</small></span>
                                                    <a href="#"><span>JD</span> +1 comment from John Doe</a>
                                                </div>
                                                <div className="notification-body-unread-dot"><span></span></div>
                                            </div>
                                            <div className="notification-body-text mb-4">
                                                <div className="notification-body-pic"><div className="number-avatar">JD</div></div>
                                                <div className="notification-body-inner-text">
                                                    <span className="d-block"><strong>John Doe changed an issue fromi in Progress to Done</strong> 15 hours ago</span>
                                                    <span className="d-block mt-1">Activity Diagram of Runway Section<br /><small>IWS-82.Done</small></span>
                                                    <a href="#"><span>JD</span> +1 comment from John Doe</a>
                                                </div>
                                                <div className="notification-body-unread-dot"><span></span></div>
                                            </div>
                                            <div className="notification-body-text mb-4">
                                                <div className="notification-body-pic"><div className="number-avatar">JD</div></div>
                                                <div className="notification-body-inner-text">
                                                    <span className="d-block"><strong>John Doe changed an issue fromi in Progress to Done</strong> 15 hours ago</span>
                                                    <span className="d-block mt-1">Activity Diagram of Runway Section<br /><small>IWS-83.Done</small></span>
                                                    <a href="#"><span>JD</span> +1 comment from John Doe</a>
                                                </div>
                                                <div className="notification-body-unread-dot"><span></span></div>
                                            </div>
                                        </div>
                                        <div className="unread-notify notification-body-inner-text text-center mt-4">
                                            <span>That's all your <strong>unread</strong> notification<br />from the last 30 days.</span>
                                            <button type="button" className="btn btn-primary w-100 text-center mt-2">Clear All</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="dropdown">
                                <div className="d-flex gap-2 align-items-center user-link ">
                                    <div className="top-avatar">
                                        <img src="/../images/user-image.png" alt="" />
                                    </div>
                                    <NavDropdown title={userInfo?.firstName} id="basic-nav-dropdown" className="">

                                        <NavDropdown.Item as={Link} to="#">Profile</NavDropdown.Item>
                                        <NavDropdown.Item className="text-purple" onClick={() => logout()} >Logout</NavDropdown.Item>
                                    </NavDropdown>
                                </div>
                            </div>

                            {/* <Dropdown>
                                <Dropdown.Toggle variant="dropdown" className="d-flex gap-2 align-items-center user-link" id="dropdown-basic">
                                    <div className="top-avatar">
                                        <img src="../images/user-image.png" alt="" />
                                    </div>
                                    Henry1
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu dropdown-menu-end top-dropdown">
                                    <Dropdown.Item as={Link} to="/">Profile</Dropdown.Item>
                                    <Dropdown.Item className="text-purple" as={Link} to="/">Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> */}

                            {/* <div className="dropdown">
                                <a href="#" className="d-flex gap-2 align-items-center user-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div className="top-avatar">
                                        <img src="../images/user-image.png" alt="" />
                                    </div>
                                    <span>Henry1</span>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end top-dropdown">
                                    <li><a className="dropdown-item" href="#">Profile</a></li>
                                    <li><a className="dropdown-item text-purple" href="#">Logout</a></li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </nav>

            <div className="sub-nav-menu">
                <Navbar expand="lg" className="bg-dark bg-dark-cutom ">
                    <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="navbar-nav me-auto mb-2 mb-lg-0 gap-4">
                                <div className="react-dashboard-nav">
                                <MdOutlineDashboardCustomize />
                                    <NavDropdown title="Dashboard" id="basic-nav-dropdown" className="sub-header-dropdown">
                                        <div className="top-dropdown">

                                            <NavDropdown.Item as={Link} to="#" >Executive Summary</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="#" >KPI and Trending</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="#" >Period Comparison Chart</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="#" >Trending by Phase Chart</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="#" >Dynamic Drilldown</NavDropdown.Item>
                                        </div>
                                    </NavDropdown>
                                </div>
                                <Nav.Link as={Link} to="/airport/list" className="nav-link d-flex align-items-center gap-2"><MdLocalAirport /> Airport</Nav.Link>
                                <Nav.Link as={Link} to={URLS.RUNWAY.LIST} className="nav-link d-flex align-items-center gap-2"><GiAirplaneDeparture  style={{fontSize:'21px'}}/> Runway</Nav.Link>
                                <Nav.Link as={Link} to="/use-case-library-list" className="nav-link d-flex align-items-center gap-2"><GrDocumentText /> Use Case</Nav.Link>
                                {/* <Nav.Link as={Link} to={URLS.EMPLOYEE_LIST} className="nav-link d-flex align-items-center gap-2"><i className="usecase-icon"></i> Employee</Nav.Link> */}
                                <Nav.Link as={Link} to="#" className="nav-link d-flex align-items-center gap-2"><TbBulb  style={{fontSize:'22px'}}/> Solution Provider</Nav.Link>
                                <Nav.Link as={Link} to="#" className="nav-link d-flex align-items-center gap-2"><TbReport /> Reports</Nav.Link>
                                <Nav.Link as={Link} to={URLS.TENANTS} className="nav-link d-flex align-items-center gap-2"><TbUsersPlus/> Tenants</Nav.Link>
                                

                                <div className="react-dashboard-nav">
                                <MdOutlineSettings />
                                    <NavDropdown title="Settings" id="basic-nav-dropdown" className="sub-header-dropdown">
                                        <div className="top-dropdown">
                                            <NavDropdown.Item as={Link} to={URLS.SYSTEM_CONFIGURATION} >System Configuration</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={URLS.SYSTEM_ENUMS} >System Enums</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="/role/list" >Role</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="/company/list" >Company</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to="/form" >Manage Forms</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={URLS.SCORE_QUESTION.LIST}>Manage Score Questions</NavDropdown.Item>
                                        </div>
                                    </NavDropdown>
                                </div>




                                



                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>


            {/* <div className="sub-nav-menu d-none">
                <nav className="navbar bg-dark bg-dark-cutom navbar-expand-lg" data-bs-theme="dark">
                    <div className="container">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 gap-4">
                                <li className="nav-item">
                                    <a className="nav-link active d-flex align-items-center gap-2" aria-current="page" href="#"><i className="home-icon"></i> Dashboard</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link d-flex align-items-center gap-2" href="#"><i className="airport-icon"></i> Airport</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link d-flex align-items-center gap-2" href="#"><i className="usecase-icon"></i> Use Case</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link d-flex align-items-center gap-2" href="#"><i className="report-icon"></i> Reports</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div> */}


        </div>
    );
}

export default Header;