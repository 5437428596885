import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import Select from 'react-select'
import Error from '../../components/elements/formFieldError'
import { useUser } from '../../_common/hooks/authHook'
import { URLS } from '../../_config'

export const ContactAddEdit = () => {
    const userInfo: any = useUser();
    const navigate = useNavigate();
    const userApi = useUserApi();
    const { id, comid } = useParams();
    const paramId: any = id;   
    const [contactData, setContactData] = useState<any>([]); 

    type Inputs = {
        firstName: string
        lastName: string
        phone: string
        email: string      
    }

    const {
        register,
        control,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
    } = useForm<Inputs>()


  

    const getContactByID = (id: any) => {

        userApi.companyContactsDetails({ urlParams: { id: id } }, (message: any, resp: any) => {
            setContactData(resp.data)
            reset({
                firstName: resp.data.firstName,                
                lastName: resp.data.lastName,               
                email: resp.data.email,
                phone: resp.data.phone,               
            })
           

        }, (message: any, resp: any) => {
            // Error...
        })
    }

    useEffect(() => {

        if (id) {
            getContactByID(id)
        }
       

    }, [id]);


   

    const submitContact = (data: any) => {
        console.log('data',data);
        return new Promise((resolve: any, reject: any) => {
            if (id) {
                data.companyContactID = id;
                data.updatedBy = userInfo?.userID;
                userApi.companyContactsEdit(data, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message);
                })

            } else {                                
                data.addedBy = userInfo?.userID;
                userApi.companyContactsAdd(data, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message);
                })
            }
        })
    }

    const submitData = (data: any) => {   
        data.isActive = true; 
        data.fkCompanyID = comid;
        return new Promise((resolve, reject) => { 
            submitContact(data).then(resolve).catch(reject)
        })

    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {

        toast.promise(
            submitData(data), {
            loading: 'Loading...',
            success: (msg: any) => { navigate(`/company/contacts/${comid}` ); return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        }
        );
    }
   


    return (
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><Link to={URLS.DASHBOARD}>Dashboard</Link></li>
                                    <li><Link to={URLS.COMPANIES}>Companies</Link></li>                                    
                                    <li><Link to={`${URLS.CONTACTS}/${comid}`}>Contacts</Link></li>
                                    <li>{(paramId > 0) ? 'Edit' : 'Add'} Contact</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">{(paramId > 0) ? 'Edit' : 'Create'} Contact</h1>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="white-box h-100">
                                <div className="form-area">
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                                       

                                        

                                       

                                        <div className="col-md-6">
                                            <label className="form-label">First Name</label>
                                            <input type="text" {...register("firstName", { required: "First name is required" })} className="form-control" placeholder="Put the  first name" />
                                            <Error error={errors.firstName} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Last Name</label>
                                            <input type="text" {...register("lastName", { required: "Last name is required" })} className="form-control" placeholder="Put the  last name" />
                                            <Error error={errors.lastName} />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Email</label>
                                            <input type="text" {...register("email", { required: "Email is required" })} className="form-control" placeholder="Put the email" />
                                            <Error error={errors.email} />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Phone</label>
                                            <input type="text" {...register("phone", { required: "Phone is required" })} className="form-control" placeholder="Put the phone number" maxLength={15}/>
                                            <Error error={errors.phone} />
                                        </div>




                                        <div className="col-12 d-flex justify-content-end gap-3">
                                            <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Submit <i className="arrow-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="pt-5">
                                <img src="./../images/idea-pic.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}
