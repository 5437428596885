import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from 'react-hot-toast';
import { useForm, SubmitHandler } from "react-hook-form"
import { URLS } from "../../_config";
import Error from "../../components/elements/formFieldError";
import { useFormApi } from "../../_common/hooks/api/FormApiHook";

export const ScoreQuestionsForm = () => {
    const navigate = useNavigate();
    const userApi = useUserApi();
    const formApi = useFormApi();
    const params: any = useParams();
    type Inputs = {
        sectionName: any,
        fkScoreSectionID: number
        fkCompanyID: number
        fkTenantID: number
        weight: number
        criteria: string
        question: string
        low: string
        lowNumber: number
        medium: string
        mediumNumber: number
        high: string
        highNumber: number
    }

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        reset,
        formState: { errors },
    } = useForm<Inputs>()

    const [scoreQuestionId, setScoreQuestionId] = useState<any>(params?.id);
    const [companyListData, setCompanyListData] = useState<any>({});
    const [tenantListData, setTenantListData] = useState<any>({});

    const getScoreQuestions = () => {
        formApi.getScoreQuestions({ urlParams: { ScoreQuestionID: scoreQuestionId } }, (message: any, resp: any) => {
            reset({
                sectionName: resp.data.sectionName,
                fkCompanyID: resp.data.fkCompanyID,
                fkTenantID: resp.data.fkTenantID,
                fkScoreSectionID: resp.data.fkScoreSectionID,
                weight: resp.data.weight,
                criteria: resp.data.criteria,
                question: resp.data.question,
                low: resp.data.low,
                lowNumber: resp.data.lowNumber,
                medium: resp.data.medium,
                mediumNumber: resp.data.mediumNumber,
                high: resp.data.high,
                highNumber: resp.data.highNumber,

            })
        }, (message: any, resp: any) => {
            // Error...
        })
    }
    const getCompanyList = () => {
        userApi.companyList({ urlParams: { index: 1, size: 100 } }, (message: any, resp: any) => {
            setCompanyListData(resp.data.companies)
        }, (message: any, resp: any) => {
            // Error...
        })
    }
    const getTenantsList = () => {
        userApi.getTenantsAll({ urlParams: { PageIndex: 1, PageSize: 100 } }, (message: any, resp: any) => {
            setTenantListData(resp.data.tenants)
        }, (message: any, resp: any) => {
            // Error...
        })
    }
    useEffect(() => {
        getCompanyList();
        getTenantsList();
        if (scoreQuestionId) {
            getScoreQuestions()
        }
    }, [scoreQuestionId]);


    const submitData = (data: any) => {
        let formParam = {
            ...data,
            "isActive": true
        };
        if (!scoreQuestionId) {
            return new Promise((resolve, reject) => {
                formApi.scoreQuestionSave(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message)
                })
            })
        } else {
            formParam.scoreQuestionID = scoreQuestionId;
            return new Promise((resolve, reject) => {
                formApi.scoreQuestionUpdate(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message)
                })
            })
        }
    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        toast.promise(
            submitData(data), {
            loading: (!scoreQuestionId) ? 'Creating...' : 'Updating...',
            success: (msg: any) => { navigate(URLS.SCORE_QUESTION.LIST); return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    return (
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><Link to={URLS.DASHBOARD}>Dashboard</Link></li>
                                    <li><Link to={URLS.SCORE_QUESTION.LIST}>Score Questions</Link></li>
                                    <li>{(scoreQuestionId > 0) ? 'Edit' : 'Add'}  Score Questions</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">{(scoreQuestionId > 0) ? 'Edit' : 'Add'} Score Questions</h1>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-8">
                            <div className="white-box h-100">
                                <div className="form-area">
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>

                                        <div className="col-md-4">
                                            <label htmlFor="fkScoreSectionID" className="form-label">Section Name </label>
                                            <select className="form-control" {...register("fkScoreSectionID", { required: "Section is required" })} id="fkScoreSectionID">
                                                <option value=''>Select one...</option>
                                                <option value={1}>Benefits</option>
                                                <option value={2}>Risk</option>
                                            </select>
                                            <Error error={errors.fkScoreSectionID} />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="fkCompanyID" className="form-label">Company </label>
                                            <select className="form-control" {...register("fkCompanyID", { required: "Company is required" })} id="fkCompanyID">
                                                <option value=''>Select one...</option>
                                                {
                                                    (companyListData && companyListData.length > 0) &&
                                                    companyListData.map((compItem: any) =>
                                                        <option value={compItem?.companyID} key={compItem?.companyID}>{compItem?.companyName}</option>
                                                    )
                                                }
                                            </select>
                                            <Error error={errors.fkCompanyID} />
                                        </div>

                                        <div className="col-md-4">
                                            <label htmlFor="fkTenantID" className="form-label">Tenant </label>
                                            <select className="form-control" 
                                            // {...register("fkTenantID", { required: "Tenant is required" })} 
                                            id="fkTenantID">
                                                <option value=''>Select one...</option>
                                                {
                                                    (tenantListData && tenantListData.length > 0) &&
                                                    tenantListData.map((item: any) =>
                                                        <option value={item?.tenantId} key={item?.tenantId}>{item?.tenantName}</option>
                                                    )
                                                }
                                            </select>
                                            <Error error={errors.fkTenantID} /> 
                                        </div>


                                        <div className="col-md-10">
                                            <label htmlFor="criteria" className="form-label">Criteria</label>
                                            <input   {...register("criteria", { required: "Required" })} type="text" className="form-control"  id="criteria" />
                                            <Error error={errors.criteria} /> 
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="weight" className="form-label">Weight</label>
                                            <input   {...register("weight", { required: "Required" })} type="text" className="form-control"  id="weight" />
                                            <Error error={errors.weight} /> 
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="question" className="form-label">Question</label>
                                            <input   {...register("question", { required: "Required" })} type="text" className="form-control"  id="question" />
                                            <Error error={errors.question} /> 
                                        </div>
                                        <div className="col-md-10">
                                            <label htmlFor="low" className="form-label">Low</label>
                                            <input   {...register("low", { required: "Required" })} type="text" className="form-control"  id="low" />
                                            <Error error={errors.low} /> 
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="lowNumber" className="form-label">Low Number</label>
                                            <input   {...register("lowNumber", { required: "Required" })} type="text" className="form-control" id="lowNumber" />
                                            <Error error={errors.lowNumber} /> 
                                        </div>
                                        <div className="col-md-10">
                                            <label htmlFor="medium" className="form-label">Medium</label>
                                            <input   {...register("medium", { required: "Required" })} type="text" className="form-control"  id="medium" />
                                            <Error error={errors.medium} /> 
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="mediumNumber" className="form-label">Medium Number</label>
                                            <input   {...register("mediumNumber", { required: "Required" })} type="text" className="form-control"  id="mediumNumber" />
                                            <Error error={errors.mediumNumber} /> 
                                        </div>
                                        <div className="col-md-10">
                                            <label htmlFor="high" className="form-label">High</label>
                                            <input   {...register("high", { required: "Required" })} type="text" className="form-control"  id="high" />
                                            <Error error={errors.high} /> 
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="highNumber" className="form-label">High Number</label>
                                            <input   {...register("highNumber", { required: "Required" })} type="text" className="form-control"  id="highNumber" />
                                            <Error error={errors.highNumber} /> 
                                        </div>

                                      
 

                                        <div className="col-12 d-flex justify-content-end gap-3">
                                            <Link to={URLS.SCORE_QUESTION.LIST} className="btn btn-primary  d-flex gap-2">Back</Link>
                                            <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Submit <i className="arrow-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="pt-5">
                                <img src="/../images/idea-pic.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}
