import { config } from "process"
import React, { useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Error from "../elements/formFieldError"
import toast from "react-hot-toast"
import { useUserApi } from "../../_common/hooks/api/UserApiHook"
import { useUser } from "../../_common/hooks/authHook"

interface props {
    config:any,
    onSubmitSuccess?:()=>void
}


export default function DynamicForm({config,onSubmitSuccess}: props) {
    const { control, register, handleSubmit, reset, getValues, formState: { errors , isValid },  } = useForm()
    const form:any  = useRef();
    const userApi = useUserApi();

    const [startTime,setStartTime] = useState<any>(new Date())
    const user:any = useUser()

    const getColClass = (field: any) => {
        let s: number = Math.round(12 / config.layout.col)
        if (field.colSpan) {
            s = s * field.colSpan
        }
        if (s > 12) {
            s = 12
        }
        return 'col-md-' + s
    }

    const getFileElement = (field: any, i: any) => {
        return (
            <div className={getColClass(field)} key={i}>
                <div className="custom-file-uploader mb-4">
                <Controller
                    defaultValue=""
                    control={control}
                    name={field.name}
                    rules={{
                        required: field.required?field.required:false,
                    }}
                    render={({ field: { onChange, value, ref, name } }) => (
                        <input type="file" name={name} ref={ref} onChange={onChange} value={value}/>
                    )}
                />

                
                    
                    <i className="file-upload-icon"></i>
                    <p className="mb-0">Click to upload.</p>
                </div>
                <Error error={errors[field.name]}/>
            </div>
        )
    }

    const getTextElement = (field: any, i:any) => {
        return (
            <div className={getColClass(field)} key={i}>
                <label htmlFor="" className="form-label">{field.caption}</label>
                <Controller
                    defaultValue=""
                    control={control}
                    name={field.name}
                    rules={{
                        required: field.required?field.required:false,
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                        <input type="text" className="form-control" placeholder={field.placeholder} ref={ref} onChange={onChange} value={value} />
                    )}
                />

                <Error error={errors[field.name]}/>
                
            </div>
        )
    }

    const getTextareaElement = (field: any, i:any) => {
        return (
            
            <div className={getColClass(field)} key={i}>
                <label htmlFor="" className="form-label">{field.caption}</label>
                <Controller
                    defaultValue=""
                    control={control}
                    name={field.name}
                    rules={{
                        required: field.required?field.required:false,
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                        <textarea className="form-control" rows={5} placeholder={field.placeholder} ref={ref} onChange={onChange}></textarea>
                    )}
                />

                <Error error={errors[field.name]}/>
                
                
            </div>
        )
    }

    const getCheckboxElement = (field: any, i:any) => {
        return (
            <div className={getColClass(field)} key={i}>
                <label htmlFor="" className="form-label">{field.caption}</label>
                <div className="d-flex gap-3">
                    {Object.keys(field.options).map((valueKey: any, k: any) => (
                        <div className="form-check" key={k}>
                            <Controller
                                defaultValue=""
                                control={control}
                                name={field.name+valueKey}
                                rules={{
                                    required: false, //field.required ? field.required : false,
                                }}
                                render={({ field: { onChange, value, ref, name } }) => (
                                    <input className="form-check-input" type="checkbox" name={name} value={valueKey} ref={ref} onChange={onChange} />
                                )}
                            />


                            <label className="form-check-label" >
                                {field.options[valueKey]}
                            </label>
                        </div>
                    ))}
                        </div>
               

                <Error error={errors[field.name]}/>
                
                
            </div>
        )
    }

    const getRadioElement = (field: any, i:any) => {
        return (
            <div className={getColClass(field)} key={i}>
                <label htmlFor="" className="form-label">{field.caption}</label>
                <div className="d-flex gap-3">
                    {Object.keys(field.options).map((valueKey:any, k:any)=>(
                        <div className="form-check" key={k}>
                            <Controller
                                defaultValue=""
                                control={control}
                                name={field.name}
                               /*  rules={{
                                    required: field.required ? field.required : false,
                                }} */
                                render={({ field: { onChange, value, ref, name } }) => (
                                    <input className="form-check-input" type="radio" name={name} value={valueKey} onChange={onChange}/>
                                )}
                            />
                        
                        <label className="form-check-label" >
                            {field.options[valueKey]}
                        </label>
                    </div>
                    ))}
                </div>
            </div>
        )
    }

    const submitData = (button:any) =>{

        return new Promise((resolve,reject)=>{
            let data = getValues();

            let formData: any = {
                api: button.actionURL,
                "formId": config.fromID,
                "fkCompanyID": user.companyID,
                "fkTenantID": user.tenantID,
                "status": 1,
                "userID": user?.userID,
                "timeSpendInSec": Math.round(((new Date()).getTime() - startTime.getTime())/1000),
                "answers": []
            }
            config.controls.map((field:any)=>{
                switch (field.inputType) {
                    case 'FileField': 
                    convertBase64((document.getElementsByName('file_1_1_1')[0] as any).files[0]).then((base64File)=>{
                        formData.answers.push({
                            "fkFormControlID": field.fkFormControlID,
                            "inputType": "File",
                            "fkControlTypeID": field.fkControlTypeID,
                            "values": [base64File]
                        })
                    })
                    
                    break;
                    case 'TextBox': 
                    formData.answers.push({
                        "fkFormControlID": field.fkFormControlID,
                        "inputType": "TextBox",
                        "fkControlTypeID": field.fkControlTypeID,
                        "values": [data[field.name]]
                    })
                    break;
                    case 'TextArea': 
                    formData.answers.push({
                        "fkFormControlID": field.fkFormControlID,
                        "inputType": "TextArea",
                        "fkControlTypeID": field.fkControlTypeID,
                        "values": [data[field.name]]
                    })
                    break;
                    case 'CheckBox': 
                    let val:any = []
                    Object.keys(field.options).map((valueKey: any, k: any) => {
                        if(data[field.name+valueKey]){
                            val.push(valueKey)
                        }

                    })

                    formData.answers.push({
                        "fkFormControlID": field.fkFormControlID,
                        "inputType": "CheckBox",
                        "fkControlTypeID": field.fkControlTypeID,
                        "values": val
                    })
                    break;
                    case 'Radio': 
                    formData.answers.push({
                        "fkFormControlID": field.fkFormControlID,
                        "inputType": "Radio",
                        "fkControlTypeID": field.fkControlTypeID,
                        "values": [data[field.name]]
                    })
                    
                    break;

                    default:
                        return null;
                }
            })

            setTimeout(() => {
                userApi.submitDynamicForm(formData, (message: any, resp: any) => {
                    resolve(message)
                }, (message: any) => {
                    reject(message)
                })
            }, 1000)
        })
    }

    const onSubmit = (data:any)=>{
        // fake ..
    }

    const convertBase64 = (file:any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
    
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
    
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const submitButton = (button:any)=>{
        if(isValid){
            toast.promise(
                submitData(button),{
                   loading: 'Saving...',
                   success: (msg:any)=>{
                    if(onSubmitSuccess){onSubmitSuccess();}
                    reset();
                    
                    
                    return <b>{msg}</b>
                },
                   error: (msg:any)=>{return <b>{msg}</b>},
                 }
               );
        }   else {
            //form.current.submit()
            console.log('Not valid')
        }
    }

    useEffect(()=>{
        setStartTime(new Date())
    },[])


    return (
        <React.Fragment>
            <div className="form-area">
         
                <form className="row g-3" onSubmit={handleSubmit(onSubmit)} >
                   
                    {config.controls.map((field: any, i:any) => {
                        switch (field.inputType) {
                            case 'FileField': return getFileElement(field, i); break;
                            case 'TextBox': return getTextElement(field, i); break;
                            case 'TextArea': return getTextareaElement(field, i); break;
                            case 'CheckBox': return getCheckboxElement(field, i); break;
                            case 'Radio': return getRadioElement(field, i); break;

                            default:
                                return null;
                        }
                    })}
                    <div className="col-12 d-flex justify-content-end gap-3">
                        {config.buttons.map((button:any, i:any)=>(
                            <button key={i} type="button" className={`btn btn-${button.theme?button.theme:'primary'} d-flex align-items-center gap-2`} onClick={()=>{submitButton(button)}}>{button.caption} <i className="arrow-right"></i></button>
                        ))}
                    </div>
                </form>
            </div>
        </React.Fragment>



    )

}