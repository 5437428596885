import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from 'react-hot-toast';
import { useForm, SubmitHandler } from "react-hook-form"
import { URLS } from "../../_config";
import bgImg from "../../../public/images/idea-pic.png";

export const SysEnumsForm = () => {
    const navigate = useNavigate();
    const userApi = useUserApi();
    const params: any = useParams();
    type Inputs = {
        key: string
        value: string
        description: string
        configGroup: string
        fkCompanyID: number
        fkTenantID: number
    }

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        reset,
        formState: { errors },
    } = useForm<Inputs>()

    const [systemEnumId, setSystemEnumId] = useState<any>(params?.id);
    const [companyListData, setCompanyListData] = useState<any>({});
    const [tenantListData, setTenantListData] = useState<any>({});

    const getSystemEnums = () => {
        userApi.getSystemEnums({ urlParams: { SysEnumID: systemEnumId } }, (message: any, resp: any) => {
            reset({
                key: resp.data.key,
                value: resp.data.value, 
                fkCompanyID: resp.data.fkCompanyID,
                fkTenantID: resp.data.fkTenantID,
            })
        }, (message: any, resp: any) => {
            // Error...
        })
    }
    const getCompanyList = () => {
        userApi.companyList({ urlParams: { index: 1, size: 100 } }, (message: any, resp: any) => {
            setCompanyListData(resp.data.companies)
        }, (message: any, resp: any) => {
            // Error...
        })
    } 
     const getTenantsList = () => {
        userApi.getTenantsAll({ urlParams: { PageIndex: 1, PageSize: 100 } }, (message: any, resp: any) => {
            setTenantListData(resp.data.tenants)
        }, (message: any, resp: any) => {
            // Error...
        })
    }
    useEffect(() => {
        getCompanyList();
        getTenantsList();
        if (systemEnumId) {
            getSystemEnums()
        }
    }, [systemEnumId]);


    const submitData = (data: any) => {
        let formParam = {
            ...data,
            "isActive": true
        };
        if (!systemEnumId) {
            return new Promise((resolve, reject) => {
                userApi.systemEnumsSave(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message)
                })
            })
        } else {
            formParam.sysEnumID = systemEnumId;
            return new Promise((resolve, reject) => {
                userApi.systemEnumsUpdate(formParam, (message: any, resp: any) => {
                    resolve(message);
                }, (message: any, resp: any) => {
                    reject(message)
                })
            })
        }
    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        toast.promise(
            submitData(data), {
            loading: (!systemEnumId) ? 'Creating...' : 'Updating...',
            success: (msg: any) => { navigate(URLS.SYSTEM_ENUMS); return <b>{msg}</b> },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    return (
        <React.Fragment>
            <Header></Header>

            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><Link to={URLS.DASHBOARD}>Dashboard</Link></li>
                                    <li><Link to={URLS.SYSTEM_ENUMS}>System Enums</Link></li>
                                    <li>{(systemEnumId > 0) ? 'Edit' : 'Add'}  System Enums</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">{(systemEnumId > 0) ? 'Edit' : 'Add'} System Enums</h1>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="white-box h-100">
                                <div className="form-area">
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>

                                        <div className="col-md-6">
                                            <label htmlFor="key" className="form-label">  Key</label>
                                            <input   {...register("key", { required: "Key is required" })} type="text" className="form-control" placeholder="Put Key" id="key" />
                                            {errors.key && <span className="text-danger">{errors.key.message}</span>}
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="value" className="form-label">  Value</label>
                                            <input type="text"   {...register("value", { required: "Value is required" })} className="form-control" placeholder="Put Value" id="value" />
                                            {errors.value && <span className="text-danger">{errors.value.message}</span>}
                                        </div>
                                        
                                        <div className="col-md-12">
                                            <label htmlFor="fkCompanyID" className="form-label">Company </label>
                                            <select className="form-control" {...register("fkCompanyID", { required: "Company is required" })} id="fkCompanyID">
                                                <option value=''>Select one...</option>
                                                {
                                                    (companyListData && companyListData.length > 0) &&
                                                    companyListData.map((compItem: any) =>
                                                        <option value={compItem?.companyID} key={compItem?.companyID}>{compItem?.companyName}</option>
                                                    )
                                                }
                                            </select>
                                            {errors.fkCompanyID && <span className="text-danger">{errors.fkCompanyID.message}</span>}
                                        </div>

                                       
                                        <div className="col-md-12">
                                            <label htmlFor="fkTenantID" className="form-label">Tenant </label>
                                            <select className="form-control" {...register("fkTenantID", { required: "Tenant is required" })} id="fkTenantID">
                                                <option value=''>Select one...</option>
                                                {
                                                    (tenantListData && tenantListData.length > 0) &&
                                                    tenantListData.map((item: any) =>
                                                        <option value={item?.tenantId} key={item?.tenantId}>{item?.tenantName}</option>
                                                    )
                                                }
                                            </select>
                                            {errors.fkTenantID && <span className="text-danger">{errors.fkTenantID.message}</span>}
                                        </div>

                                       


                                        <div className="col-12 d-flex justify-content-end gap-3">
                                            <button type="submit" className="btn btn-info d-flex align-items-center gap-2">Submit <i className="arrow-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="pt-5">
                                <img src="/../images/idea-pic.png" alt="" />
                                {/* <img src={bgImg} alt="" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </React.Fragment>
    )
}
