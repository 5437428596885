import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Pagination from "../../components/pagination/Pagination";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import toast from 'react-hot-toast';
import { useUser } from "../../_common/hooks/authHook";
import { URLS } from "../../_config";

export const ContactList = () => {
    const [allData, setAllData] = useState<Array<any>>([]);
    const userApi = useUserApi();
    const userInfo: any = useUser();
    const { id } = useParams();
    const paramId: any = id;



    const getAllItemList = () => {
        userApi.companyContactsList({ urlParams: { id: id } }, (message: any, resp: any) => {
            console.log(resp);

            setAllData(resp.data.companyContacts);
        }, (message: any, resp: any) => {
            // Error...
        })

    }

    useEffect(() => {
        getAllItemList()

    }, []);



    const deleteData = (data: any) => {
        let formParam = {
            "companyContactID": data.companyContactID,
            "updatedBy": userInfo?.userID,
        };
        return new Promise((resolve, reject) => {
            userApi.companyContactsDelete(formParam, (message: any, resp: any) => {
                resolve(message);
            }, (message: any, resp: any) => {
                reject(message);
            })
        })
    }

    const deleteItem = (companyContactID: number) => {
        let data = {
            "companyContactID": companyContactID,
        };

        Swal.fire({
            title: 'Confirm Delete',
            text: `Are you sure you want to delete this contact?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    deleteData(data), {
                    loading: 'Loading...',
                    success: (msg: any) => { getAllItemList(); return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                }
                );

            }
        });
    }

    const statusData = (data: any) => {
        let formParam = {
            "updatedBy": userInfo?.userID,
            "companyContactID": data.companyContactID,
            "isActive": data.isActive
        };

        return new Promise((resolve, reject) => {
            userApi.companyContactsStatus(formParam, (message: any, resp: any) => {
                resolve(message);
            }, (message: any, resp: any) => {
                reject(message)
            })
        })
    }

    const statusChange = (status: boolean, companyContactID: number) => {
        let msg = status === false ? 'In-Active' : 'Active';
        let data = {

            "companyContactID": companyContactID,
            "isActive": status
        };

        Swal.fire({
            title: 'Confirm Status',
            text: `Are you sure you want to ${msg} this company?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: `Yes, ${msg} it!`
        }).then((result) => {
            if (result.isConfirmed) {
                toast.promise(
                    statusData(data), {
                    loading: 'Loading...',
                    success: (msg: any) => { getAllItemList(); return <b>{msg}</b> },
                    error: (msg: any) => { return <b>{msg}</b> },
                }
                );

            }
        });


    }
    return (
        <React.Fragment>
            <Header></Header>
            <div className="main-container flex-grow-1">
                <div className="container">
                    <div className="page-title py-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="csmt-breadcrumb d-flex justify-content-start gap-3 mb-2 ps-0">
                                    <li><Link to={URLS.DASHBOARD}>Dashboard</Link></li>
                                    <li><Link to={URLS.COMPANIES}>Companies</Link></li>
                                    <li>Contacts</li>
                                </ul>
                            </div>
                            <div className="col-sm-12">
                                <h1 className="h6 text-uppercase font-weight-700  mb-0">Contacts</h1>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">


                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h1 className="h5 mb-0 font-weight-600"></h1>

                                <Link to={`/company/contact/${id}/create`} className="btn btn-info py-2 add-more-files-btn"> Add Contact</Link>
                            </div>

                            <div className="white-box px-4 pt-3">
                                <div className="table-responsive list-table">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr className="align-middle">
                                                <th >#</th>
                                                <th scope="col">First Name</th>
                                                <th scope="col">Last Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {allData && allData.length > 0 ? (
                                                allData.map((item) => (
                                                    <tr key={item.companyContactID} className="align-middle h-60">
                                                        <td >1</td>
                                                        <td>{item.firstName}</td>
                                                        <td>{item.lastName}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.phone}</td>
                                                        <td>
                                                            <div className="d-flex gap-3">
                                                                <Link to={`/company/contact/${id}/${item.companyContactID}`}>
                                                                    <i className="edit-icon"></i>
                                                                </Link>
                                                                <Link to="#" onClick={() => { deleteItem(item.companyContactID) }}><i className="delete-icon"></i></Link>
                                                                {item.isActive === true ? (
                                                                    <Link to="#" onClick={() => { statusChange(false, item.companyContactID) }}><i className="active-icon"></i></Link>
                                                                ) : (
                                                                    <Link to="#" onClick={() => { statusChange(true, item.companyContactID) }}><i className="in-active"></i></Link>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr className="align-middle h-60">
                                                    <td colSpan={6} className="text-center">Data Not Found!</td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </div>

                                {/* <div className="pagination-panel">
                                    <ul className="d-flex justify-content-center">
                                        <li><a href="#" className="page-nav-arrow-left"></a></li>
                                        <li className="active"><a href="#">1</a></li>
                                        <li><a href="#">2</a></li>
                                        <li><a href="#">3</a></li>
                                        <li><a href="#">4</a></li>
                                        <li><a href="#">5</a></li>
                                        <li><a href="#" className="page-nav-arrow-right"></a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </React.Fragment>
    )
}
