import React from "react";

const ProgressSteps = () =>{
    return(
        <div className="progress-steps-panel d-flex mb-4">
            <div className="progress-steps d-flex align-items-center gap-3">
                <div className="progress-number check-done">1</div>
                <p>Project Plan</p>
            </div>
            <div className="progress-steps d-flex align-items-center gap-3">
                <div className="progress-number check-done">2</div>
                <p>Procurement Plan</p>
            </div>
            <div className="progress-steps active d-flex align-items-center gap-3">
                <div className="progress-number">3</div>
                <p>Test Plan</p>
            </div>
            
        </div>
    );
}

export default ProgressSteps;